import {
  getConvFee,
  getPromoDiscount,
} from "../../../common/AllTickets/promoConvhelper";

export function getBuildPackagePrice(
  quotation,
  hotelAllDetails,
  ConvFee,
  promoData,
  partPayment,
  redeemAmount,
  transactionFee,
  tourLiveList
) {
  let activitiesTotal = 0;
  let activitiesMarkup = 0;
  let activitiesAgentMarkup = 0;
  let activitiesCommission = 0;

  let hotelTotal = 0;
  let hotelMarkup = 0;
  let hotelAgentMarkup = 0;
  let hotelCommission = 0;

  let totalAmount = 0;
  let totalMarkup = 0;
  let totalAgentMarkup = 0;
  let totalCommission = 0;

  let convAmount = 0;
  let discount = 0;
  let grandTotal = 0;

  let partPaymentAmount = 0;
  let partdueAmount = 0;
  let redeemTotal = 0;
  let tranxFree =0;
  let activitiesTotalFullPay=0
  if (quotation?.TourDetails?.length > 0) {
    activitiesTotal = quotation.TourDetails.map((item) =>
      item.reduce((a, b) => a + Number(b.serviceTotal), 0)
    ).reduce((acc, cur) => acc + Number(cur), 0);
    if(tourLiveList &&tourLiveList.length>0){
    const result =  quotation.TourDetails[0].filter(c =>tourLiveList.find(s => s.TourID == c.tourId ))
  
    activitiesTotalFullPay = result.reduce((a, b) => a + Number(b.serviceTotal), 0)
    }

    activitiesMarkup = quotation.TourDetails.map((item) =>
      item.reduce((a, b) => a + Number(b.markup), 0)
    ).reduce((acc, cur) => acc + Number(cur), 0);

    activitiesAgentMarkup = quotation.TourDetails.map((item) =>
      item.reduce((a, b) => a + Number(b.agentMarkup), 0)
    ).reduce((acc, cur) => acc + Number(cur), 0);

    activitiesCommission = quotation.TourDetails.map((item) =>
      item.reduce((a, b) => a + Number(b.commission), 0)
    ).reduce((acc, cur) => acc + Number(cur), 0);
  }

  if (hotelAllDetails?.length > 0) {
    let hotelWithPrice = hotelAllDetails.filter((item) => item.hotelPriceRes);
    hotelTotal = hotelWithPrice.reduce(
      (acc, cur) => acc + Number(cur.hotelPriceRes.total),
      0
    );

    hotelMarkup = hotelWithPrice.reduce(
      (acc, cur) => acc + Number(cur.hotelPriceRes.markup),
      0
    );

    hotelAgentMarkup = hotelWithPrice.reduce(
      (acc, cur) => acc + Number(cur.hotelPriceRes.agentMarkup),
      0
    );

    hotelCommission = hotelWithPrice.reduce(
      (acc, cur) => acc + Number(cur.hotelPriceRes.commission),
      0
    );
  }

  if (activitiesCommission && activitiesTotal > activitiesCommission) {
    activitiesTotal -= activitiesCommission;
    totalCommission += Number(activitiesCommission);
  }

  if (hotelCommission && hotelTotal > hotelCommission) {
    totalCommission += Number(hotelCommission);
  }

  totalAmount = Number(activitiesTotal) + Number(hotelTotal);

  convAmount = Number(getConvFee(ConvFee, totalAmount));
  totalAmount += convAmount;
  discount = Number(getPromoDiscount(promoData, totalAmount));

  grandTotal = totalAmount - discount;
  if (redeemAmount?.CouponAmt) {
    redeemTotal = Number(redeemAmount?.CouponAmt);
  }
  grandTotal -= redeemTotal;
  totalMarkup = Number(activitiesMarkup) + Number(hotelMarkup);
  totalAgentMarkup = Number(activitiesAgentMarkup) + Number(hotelAgentMarkup);

  if (partPayment.isPartPayment) {
   let PartPayTotal = Number(grandTotal)- Number(activitiesTotalFullPay)
    partPaymentAmount = Number(
      (Number(PartPayTotal) * partPayment?.PartPaymentPercentage) / 100
    ).toFixed(2);
    partPaymentAmount= Number(activitiesTotalFullPay) + Number(partPaymentAmount)
    partdueAmount = Number(
      Number(grandTotal) - Number(partPaymentAmount)
    ).toFixed(2);
  }

  if(transactionFee?.type=="P" || transactionFee?.type=="p")
  {
    tranxFree =(grandTotal /100) * parseFloat(transactionFee.amount)
    
  }
  else if(transactionFee?.type=="f" || transactionFee?.type=="F") {
    tranxFree = parseFloat(transactionFee.amount)
  
  }grandTotal += tranxFree;

  return {
    activitiesTotal,
    hotelTotal,
    totalAmount: Number(totalAmount).toFixed(2),
    grandTotal: Number(grandTotal).toFixed(2),
    discount: Number(discount).toFixed(2),
    convAmount: Number(convAmount).toFixed(2),
    totalMarkup,
    totalAgentMarkup,
    totalCommission,
    partPaymentAmount,
    partdueAmount,
    redeemTotal: redeemTotal,
    tranxFree
  };
}

export function getActivitiesPartPaymentDetails(grandTotal, partPayment) {
  let partPaymentAmount = 0;
  let dueAmount = 0;
  if (partPayment.isPartPayment) {
    let copyTotal = grandTotal;

    partPaymentAmount = Number(
      (parseFloat(copyTotal) * partPayment?.PartPaymentPercentage) / 100
    ).toFixed(2);

    dueAmount = Number(copyTotal - partPaymentAmount).toFixed(2);
  } else {
    partPaymentAmount = grandTotal;
  }

  return {
    partPaymentAmount,
    dueAmount,
  };
}

import {
  getConvFee,
  getPromoDiscount,
} from "../../common/AllTickets/promoConvhelper";

export const getFlightGrandTotalPrice = (
  airPriceResp,
  ConvFee,
  promoData,
  flightSearchObj,
  selectedInsuranceData,
  redeemAmount,
  SelectedoutbondBagges,
  selectedoutMeal,SelectedInbondBagges,SelectedInbondMeal,transactionFee,isInternational=0
) => {
  let adultCount = parseInt(flightSearchObj.adultCount);
  let childCount = parseInt(flightSearchObj.childCount);
  let infantCount = parseInt(flightSearchObj.infantCount);
  let totalPax = adultCount + childCount + infantCount;
  let insuranceTotal = 0;
  let totalBaseFare = 0;
  let totalAmount = 0;
  let otherCharges = 0;
  let convamount = 0;
  let discount = 0;
  let commission = 0;
  let markup = 0;
  let agentMarkup = 0;
  let adminCommission = 0;
  let grandTotal = 0;
  let redeemTotal = 0;
  let totalpaxcount = 0; 
  let gstOnMarkup =0;
  let tranxFree =0;
  airPriceResp.flightDetails.forEach((flight) => {
    totalpaxcount += flight.adultCount + flight.childCount + flight.infantCount;
    flight.fareFamilies.forEach((fareFamily) => {
      fareFamily.flightFares.forEach((fare) => {
        if (fare.fareTag == "Base") {
          if (fare.paxType == "ADT") {
            totalBaseFare += adultCount * Number(fare.amount);
          } else if (fare.paxType == "CHD") {
            totalBaseFare += childCount * Number(fare.amount);
          } else {
            totalBaseFare += infantCount * Number(fare.amount);
          }
        } else if (fare.fareTag == "Tax") {
          if (fare.paxType == "ADT") {
            otherCharges += adultCount * Number(fare.amount);
          } else if (fare.paxType == "CHD") {
            otherCharges += childCount * Number(fare.amount);
          } else {
            otherCharges += infantCount * Number(fare.amount);
          }
        } else if (fare.fareTag == "commission") {
          commission += Number(fare.amount);
        } else if (fare.fareTag == "markup") {
          markup = Number(fare.amount);
        } else if (fare.fareTag == "agentMarkup") {
          agentMarkup += Number(fare.amount);
        } else if (fare.fareTag == "adminCommission") {
          adminCommission += Number(fare.amount);
        }
      });
    });
  });

  if (
    selectedInsuranceData.status === 1 &&
    selectedInsuranceData?.serviceType === 1
  ) {
    insuranceTotal = Number(totalPax) * Number(selectedInsuranceData.amount);
  }
// * totalpaxcount
  markup = totalpaxcount * markup;
  gstOnMarkup =Number(((markup*18)/100).toFixed(2));
  totalAmount = Number((Number(totalBaseFare) + Number(otherCharges)).toFixed(2));
 let totalPaxForConv =totalpaxcount;
  if(flightSearchObj.airTravelType=="roundTrip" && isInternational==0){
    totalPaxForConv=totalPaxForConv/2;
  }
  convamount = Number((getConvFee(ConvFee, totalAmount, totalPaxForConv )).toFixed(2));

  grandTotal = (totalAmount + Number(convamount));
grandTotal = Number((grandTotal+ Number(SelectedoutbondBagges==undefined?0:SelectedoutbondBagges)
+ Number(selectedoutMeal==undefined?0:selectedoutMeal) + Number(SelectedInbondBagges==undefined?0:SelectedInbondBagges)
+ Number(SelectedInbondMeal==undefined?0:SelectedInbondMeal)).toFixed(2))
  discount = Number((getPromoDiscount(promoData, grandTotal)).toFixed(2));
  grandTotal -= discount;

  grandTotal += Number((insuranceTotal).toFixed(2));
  if (redeemAmount?.CouponAmt) {
    redeemTotal = Number(redeemAmount?.CouponAmt);
  }
  grandTotal -=Number((redeemTotal).toFixed(2));
  console.log(grandTotal, commission, "airPriceResp");
  if (commission) {
 let  tdsOnCommission = Number(((commission/100)*5).toFixed(2));
    grandTotal -= Number(commission.toFixed(2));
    grandTotal +=tdsOnCommission;
  }

  grandTotal = (grandTotal + gstOnMarkup + markup);
  if(transactionFee?.type=="P" || transactionFee?.type=="p")
  {
    tranxFree =(grandTotal /100) * parseFloat(transactionFee.amount)
    
  }
  else if(transactionFee?.type=="f" || transactionFee?.type=="F") {
    tranxFree = parseFloat(transactionFee.amount)
  
  }
  grandTotal += tranxFree;
  return {
    grandTotal: Number(grandTotal).toFixed(2),
    commission: Number(commission).toFixed(2),
    convamount: Number(convamount).toFixed(2),
    discount: Number(discount).toFixed(2),
    markup,
    agentMarkup,
    adminCommission,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
    redeemTotal: redeemTotal,
    tranxFree: tranxFree
  };
};

export const getFareTotal = (flightSearchObj, flightFares) => {
  let totalBaseFare = 0;
  let tax = 0;
  let totalAmount = 0;
  let adultPrice = 0;
  let ChildPrice = 0;
  let infantPrice = 0;
  let adultCount = parseInt(flightSearchObj.adultCount);
  let childCount = parseInt(flightSearchObj.childCount);
  let infantCount = parseInt(flightSearchObj.infantCount);
let markup =0; 
let gstOnMarkup =0;
  flightFares.forEach((fareObj) => {
    if(fareObj.fareTag==="markup")
    {
      markup =  Number(fareObj.amount)*(adultCount + childCount + infantCount);
    }
    if (fareObj.fareTag === "Base") {
      if (fareObj.paxType === "ADT") {
        adultPrice += Number(fareObj.amount) * adultCount;
      } else if (fareObj.paxType === "CHD") {
        ChildPrice += Number(fareObj.amount) * childCount;
      } else {
        infantPrice += Number(fareObj.amount) * infantCount;
      }
    } else if (fareObj.fareTag == "Tax") {
      if (fareObj.paxType == "ADT") {
        tax += adultCount * Number(fareObj.amount);
      } else if (fareObj.paxType == "CHD") {
        tax += childCount * Number(fareObj.amount);
      } else {
        tax += infantCount * Number(fareObj.amount);
      }
    }
  });
 gstOnMarkup = Number(((markup/100)*18).toFixed(2));
  totalBaseFare = adultPrice + ChildPrice + infantPrice;

  totalAmount =(totalBaseFare + tax + markup +gstOnMarkup);

  return {
    adultPrice: Number(adultPrice).toFixed(2),
    ChildPrice: Number(ChildPrice).toFixed(2),
    infantPrice: Number(infantPrice).toFixed(2),
    totalBaseFare: Number(totalBaseFare).toFixed(2),
    tax: Number(tax + markup +gstOnMarkup).toFixed(2),
    totalAmount: Number(totalAmount).toFixed(2),
    markup: Number(markup).toFixed(2),
    gstOnMarkup: Number(gstOnMarkup).toFixed(2),
  };
};

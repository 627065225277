import React from "react";
import "../HotelDetailsBox/HotelDetailsBox.scss";
import hotelNoImg from "../../assets/images/hotels/no_img.png";
import { Card, Col, Rate, Row } from "antd";
import moment from "moment";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

const HotelDetailsBox = ({ Ids, hotelDetailsObj, hotelSearchData }) => {
  let history = useHistory();
  // let checkInDateAndTime =
  //   moment(hotelSearchData.checkInDate).format("lll").split(" ") ?? [];
  // let checkOutDateAndTime =
  //   moment(hotelSearchData.checkOutDate).format("lll").split(" ") ?? [];

  let noOfNights = () => {
    if (Object.keys(hotelSearchData).length > 0) {
      let checkin = new Date(hotelSearchData.checkInDate);
      let checkout = new Date(hotelSearchData.checkOutDate);
      let diffTime = checkout - checkin;
      let diffDays = Math.ceil(diffTime / (1000 * 24 * 60 * 60));
      return diffDays;
    } else {
      return 0;
    }
  };

  const getAdultChildCount = () => {
    let adults = 0;
    let childs = 0;

    if (Object.keys(hotelSearchData).length > 0) {
      if (hotelSearchData.roomGuests.length > 0) {
        for (
          let index = 0;
          index < hotelSearchData.roomGuests.length;
          index++
        ) {
          adults += Number(hotelSearchData.roomGuests[index].noOfAdults);
          childs += Number(hotelSearchData.roomGuests[index].noOfChilds);
        }
      }
    }
    if (childs > 0) {
      return `${adults} Adult &  ${childs} Children`;
    } else return `${adults} Adult`;
  };

  return (
    <>
      <Row>
        <Col md={8} sm={24} xs={24}>
          <div className="image-details one-img-hotel">
            {hotelDetailsObj?.images?.length > 0 ? (
              <img
                src={hotelDetailsObj.images[0]}
                alt={hotelDetailsObj.hotelName}
              />
            ) : (
              <img src={hotelNoImg} alt="no-photo" />
            )}

            <div className="tag-cp-img">
              <p>You Save Rs.2,640 </p>
            </div>
          </div>
        </Col>

        <Col md={16} sm={24} xs={24}>
          <div className="disp-table-cell-div">
            <div className="hotel-plan-div">
              <h4>{hotelDetailsObj.hotelName}</h4>
              {hotelDetailsObj.starRating && (
                <div className="hotel-star">
                  <Rate
                    className="starRating"
                    disabled
                    value={Number(hotelDetailsObj.starRating)}
                    allowHalf={true}
                  />
                </div>
              )}
            </div>
            <p className="post-div-para">
              {hotelDetailsObj?.addresses[0]?.address}
            </p>
          </div>
          <Row gutter={[2, 12]} className="cal-top-date-pic">
            <Col md={12} sm={12} xs={12}>
              <div className="cal-block-div">
                <div className="cb-top-div">
                  <span className="checkin-text-div">Check-In</span>
                  <span className="bold bkg-date-div">
                    {/* {checkInDateAndTime[1]?.replace(",", "")} */}
                    {moment(hotelSearchData.checkInDate).format("DD, MMM YYYY")}
                  </span>
                </div>
                {/* <div className="cb-footer-div">
                    {checkInDateAndTime[0]} &nbsp;|&nbsp;{checkInDateAndTime[3]}{" "}
                    {checkInDateAndTime[4]}
                  </div> */}
              </div>
            </Col>
            <Col md={12} sm={12} xs={12}>
              <div className="cal-block-div">
                <div className="cb-top-div">
                  <span className="checkin-text-div">Check-Out</span>
                  <span className="bold bkg-date-div">
                    {/* {checkOutDateAndTime[1]?.replace(",", "")} */}

                    {moment(hotelSearchData.checkOutDate).format(
                      "DD, MMM YYYY"
                    )}
                  </span>
                </div>
                {/* <div className="cb-footer-div">
                    {checkOutDateAndTime[0]} &nbsp;|&nbsp;
                    {checkOutDateAndTime[3]} {checkOutDateAndTime[4]}
                  </div> */}
              </div>
            </Col>
          </Row>

          <Col className="room-number-wrapper">
            <div className="second-col-details-div">
              <ul className="cp-room-1">
                <li className="room-number-days">
                  {noOfNights() + 1} Days & {noOfNights()} Nights
                </li>
              </ul>

              {Object.keys(hotelSearchData).length > 0 &&
                hotelSearchData.roomGuests.length > 0 &&
                hotelSearchData.roomGuests.map((item, index) => {
                  return (
                    <>
                      <ul className="spin-days-rooms">
                        <li className="room-spin-cp">Room {index + 1}:</li>
                        <li className="room-pkg-spin-cp">
                          {item.noOfChilds > 0
                            ? `${item.noOfAdults} Adults & ${item.noOfChilds} Childs`
                            : `${item.noOfAdults} Adults`}
                        </li>
                      </ul>
                    </>
                  );
                })}

              {/* {getAdultChildCount()} */}
            </div>
          </Col>
          <Col md={24} sm={24} xs={24}>
            <Col className="inclusion-cp">
              {hotelDetailsObj?.rooms[0]?.roomName.split(",").map((i, idx) =>
                idx > 0 ? (
                  <>
                    {idx === 1 ? <p>Inclusion:</p> : null}
                    <ul>
                      <li>
                        <i className="fa fa-check" aria-hidden="true"></i>
                        &nbsp;
                        {i}
                      </li>
                    </ul>
                  </>
                ) : (
                  ""
                )
              )}
            </Col>
            {/* <div className="inclusion-cp">
                <p>Inclusion:</p>
                <ul>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i>&nbsp;
                    Breakfast
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i>&nbsp;
                    Complimentary Wi-Fi Internet
                  </li>
                </ul>
              </div> */}
          </Col>
        </Col>
        <Col md={24} sm={24} xs={24} className="cancel-cp-bottom">
          <>
            <div className="hotelCancellationDate text-danger d-flex" style={{justifyContent: "space-between"}}>
              <p><strong>Last CancellationDate :- </strong> {moment(hotelDetailsObj.rooms[0].ratePlans[0].lastCancellationDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}</p>
              <p><strong>Last VoucherDate :- </strong> {moment(hotelDetailsObj.rooms[0].ratePlans[0].lastVoucherDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} </p>
            </div>
            {Object.keys(hotelDetailsObj).length > 0 && (
              <>
                {hotelDetailsObj.rooms.length > 0 ? (
                  <>
                    {hotelDetailsObj.rooms[0]?.ratePlans[0]
                      ?.cancellationPolicy[0]?.policies[0] != "" ? (
                      <div className="cancel-policy-cp">
                        <label>
                          <p>
                            <strong>Cancellation Policy: </strong>
                            {
                              hotelDetailsObj.rooms[0]?.ratePlans[0]
                                ?.cancellationPolicy[0]?.policies[0]
                            }
                          </p>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        </Col>
      </Row>
      <div className="hotel-details-box-wrapper cp-box-none">
        {Object.keys(hotelDetailsObj).length > 0 && (
          <div className="hotel-details-img-name">
            <div className="image-details">
              {hotelDetailsObj.imageList.length > 0 ? (
                <img
                  src={hotelDetailsObj.imageList[0].path}
                  alt={hotelDetailsObj.hotelName}
                />
              ) : (
                <img src={hotelNoImg} alt="no-photo" />
              )}
            </div>
            <div className="name-details">
              <p>
                {hotelDetailsObj.hotelName}
                <sup>
                  {/* <BeautyStars
                    value={hotelDetailsObj.starRating}
                    activeColor={"#f6952d"}
                    size={"7px"}
                    inactiveColor={"#eaebee"}
                  /> */}
                </sup>
              </p>
              {/* <span>{hotelDetailsObj.hotelAddress}</span> */}
            </div>
          </div>
        )}

        <div className="stay-details-container">
          <div className="staying-date">
            <label className="hotel-stay-name">Marina Bay Beach Resort</label>
            <p className="locaton-hotel-div">
              Behind Bobs inn,Anna Vaddo, Candolim, GOA,
            </p>

            {Object.keys(hotelSearchData).length > 0 ? (
              <span>
                {moment(hotelSearchData.checkInDate).format("DD MMM YYYY")}
                {" - "}
                {moment(hotelSearchData.checkOutDate).format("DD MMM YYYY")}
              </span>
            ) : (
              ""
            )}

            <span className="number-hotel-spin">Nights: {noOfNights()}</span>
          </div>
          <div className="staying-date hotel-stay-date-row">
            {Object.keys(hotelDetailsObj).length > 0 && (
              <label>
                {hotelDetailsObj.rooms.length > 0 ? (
                  <>
                    {hotelDetailsObj.rooms[0].roomName} -{" "}
                    {hotelDetailsObj.rooms[0].ratePlans[0].cancellationPolicy
                      .policyName
                      ? hotelDetailsObj.rooms[0].ratePlans[0].cancellationPolicy
                          .policyName
                      : ""}
                  </>
                ) : (
                  ""
                )}
              </label>
            )}
            {getAdultChildCount()}
          </div>
        </div>
      </div>
    </>
  );
};

export default HotelDetailsBox;

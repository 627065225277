import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Layout } from "antd";
import "../../Hotelpkgroom/Hotelpkgroom.scss";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ApiClient from "../../../../helpers/ApiClient";
import { useAuthContext } from "../../../../common/providers/AuthProvider";
import { useActivitiesContext } from "../../../../common/providers/Activities/ActivitiesProvider";
import queryString from "query-string";

import {getAllActivitiesPrice} from "../../Activitiescheckout/activitiesHelper";
//./ActivitiesGrandTotalCard";
const ShowCartModel = ({
    cartData,
    getActivitiesByUserId = (userId) => { }
}) => {
    const {
        state: { activitiesCart },
        setActivitiesCart,
        setActivitiesCheckOutData,
    } = useActivitiesContext();

    let history = useHistory();
    const { Content } = Layout;
    const [isLoading, setLoading] = useState(true);
    const { user } = useAuthContext();

    const deleteToursByUserId = (optionId, userId) => {
        if (user) {
            ApiClient.delete(
                `activities/tours/deleteactivitiesbyuserId?userId=${userId}&TourOptionId=${optionId}`
            ).then((result) => result)
                .then((resp) => {
                    if (resp.statusCode == 200) {
                        getActivitiesByUserId(userId);
                        setLoading(false);
                    }
                });
        }
        else{

        }
    };
   
  const {
    totalAmount,

  } = getAllActivitiesPrice(
    activitiesCart?.TourDetails,
  );
  const handleDeleteTour = (optionId,tourDate,tourId, transferId) => {
    if (user) {
      deleteToursByUserId(optionId, user?.UserID);
    } else {
        let filterList=activitiesCart.TourDetails;
        filterList?.map((item,indx)=>{
        if(item.optionId == optionId && item.tourDate==tourDate && item.tourId ==tourId && item.transferId ==transferId){
            filterList.splice(indx , 1)
        }
        })
    //   let filterList = activitiesCart.TourDetails.filter(
    //     (item) => (item.optionId !== optionId  && item.tourId !==tourId && item.transferId !==transferId)
    //   );
    //   setCartData((prev) => ({
    //     ...prev,
    //     TourDetails: filterList,
    //   }));
      setActivitiesCart((prev) => ({
        ...prev,
        TourDetails: filterList,
      }));
    }
  };
    const goto = () => {
        setActivitiesCheckOutData({
            searchRequest: activitiesCart?.searchRequest,
            TourDetails: activitiesCart.TourDetails,
        });
        history.push("/activities/checkout");
    };

    // const { user } = useAuthContext();
    // const {
    //   state: { activitiesCart },
    //   setActivitiesCart,
    //   setActivitiesCheckOutData,
    // } = useActivitiesContext();
  
    // const [cartData, setCartData] = useState({
    //   searchRequest: {},
    //   TourDetails: [],
    // });
    // const [isLoading, setLoading] = useState(true);
  
    let selectedTour = [];
  
    // const getActivitiesByUserId = (userId) => {
    //   ApiClient.get(`activities/tours/getactivitiesbyuserId/${userId}`)
    //     .then((result) => result)
    //     .then((resp) => {
    //       if (resp.statusCode == 200 && resp?.data) {
    //         setCartData(resp.data);
    //       } else {
    //         setCartData({
    //           searchRequest: {},
    //           TourDetails: [],
    //         });
    //       }
    //       setLoading(false);
    //     });
    // };
  
    // const getactivitiesbycartId = () => {
    //   setLoading(true);
    //   let param = queryString.parse(document.location.search);
  
    //   if (param.id) {
    //     ApiClient.get(`activities/tours/getactivitiesbycartId/${param.id}`)
    //       .then((result) => result)
    //       .then((resp) => {
    //         if (resp.statusCode == 200 && resp?.data) {
    //           setCartData({ cartId: param.id, ...resp.data });
    //         } else {
    //           setCartData({
    //             cartId: "",
    //             searchRequest: {},
    //             TourDetails: [],
    //           });
    //         }
    //         setLoading(false);
    //       });
    //   } else {
    //     setCartData({
    //     cartId: "",
    //       searchRequest: {},
    //       TourDetails: [],
    //     });
    //     setLoading(false);
    //   }
    // };
  
    // const deleteToursByCartId = (cartId, optionId) => {
    //   ApiClient.delete(
    //     `activities/tours/removetours?cartId=${cartId}&TourOptionId=${optionId}`
    //   )
    //     .then((result) => result)
    //     .then((resp) => {
    //       if (resp.statusCode == 200) {
    //         <Alert message="Tours Deletd" type="success" />;
    //         getactivitiesbycartId();
    //         setLoading(false);
    //       }
    //     });
    // };
  
    // const deleteToursByUserId = (optionId, userId) => {
    //   ApiClient.delete(
    //     `activities/tours/deleteactivitiesbyuserId?userId=${userId}&TourOptionId=${optionId}`
    //   )
    //     .then((result) => result)
    //     .then((resp) => {
    //       if (resp.statusCode == 200) {
    //         message.success("Tours Deleted Successfully", 2);
    //         getActivitiesByUserId(userId);
    //         setLoading(false);
    //       }
    //     });
    // };
  
    // const handleDeleteTour = (optionId) => {
    //   if (user) {
    //     deleteToursByUserId(optionId, user.UserID);
    //   } else {
    //     let filterList = cartData.TourDetails.filter(
    //       (item) => item.optionId !== optionId
    //     );
    //     setCartData((prev) => ({
    //       ...prev,
    //       TourDetails: filterList,
    //     }));
    //     setActivitiesCart((prev) => ({
    //       ...prev,
    //       TourDetails: filterList,
    //     }));
    //   }
    // };
  
    // const selectTourFromcart = (e, tours, index) => {
    //   if (e.target.checked == true) {
    //     selectedTour.push(tours);
    //   } else {
    //     selectedTour.splice(index, 1);
    //   }
    // };
    // const goto = () => {
    //   if (selectedTour.length === 0) {
    //     message.error("Please Select Tours", 3);
    //   } else {
    //     setActivitiesCheckOutData({
    //       searchRequest: cartData?.searchRequest,
    //       TourDetails: selectedTour,
    //     });
    //     history.push("/activities/checkout");
    //   }
    // };
  
    // useEffect(() => {
    //   getactivitiesbycartId();
    // }, []);
  
    useEffect(() => {
      if (user) {
        if (user.UserID) {
          getActivitiesByUserId(user.UserID);
        }
      } else {
        if (activitiesCart && Object.keys(activitiesCart).length > 0) {
        //   setCartData(activitiesCart);
        }
        setLoading(false);
      }
    }, [user]);
    const getPaxCount = (tour) => {
      return `Adult(s) ${tour?.adult ? tour?.adult : 0} ${
        tour?.child ? ", Child(s) " + tour?.child : " "
      } ${tour?.infant ? ", Infant(s) " + tour?.infant : " "}`;
    };
  
    // const handleAddMore = () => {
    //   if (cartData?.searchRequest) {
    //     let query = {
    //       country: cartData?.searchRequest?.country,
    //       city: cartData?.searchRequest?.city,
    //       travelDate: cartData?.searchRequest?.travelDate,
    //     };
    //     let queryParams = queryString.stringify(query);
    //     history.push("/activities/results?" + queryParams);
    //   }
    // };
    return (
        <Layout className="travel-img">
            <Content className="admin-container cms-pages-width showMyCart-cms">
                {activitiesCart?.TourDetails?.map((tour, index) => {
                    return (
                        <div key={"selectedBuildActivities" + index}>
                            <Row
                                key={"tourperDay" + index}
                                className={"quotation-card mt-2"}
                            >
                                <Col md={24} xs={24}>
                                    {/* <h5>Day-{index + 1}</h5> */}
                                    <Card>
                                        <div className="quotation-header d-flex align-items-center justify-content-between">
                                            <h5 className="activi-hotelinfo flex-fill">
                                                {tour.tourName}{" "}
                                                {moment(tour.tourDate, "YYYY-MM-DD").format(
                                                    "DD MMM YYYY"
                                                )}
                                            </h5>
                                            <span
                                                className="close-fa"
                                                onClick={() => handleDeleteTour(tour.optionId,tour.tourDate,tour.tourId, tour.transferId)}
                                            >
                                                <i className="fa fa-times"></i>
                                            </span>
                                        </div>
                                        <div className="hotel-table-name">
                                            <p>Tour Name: {tour.tourName}</p>
                                            <p>Transfer Type: {tour.transferName}</p>
                                            <p>Timings: {tour.startTime}</p>
                                            <p>Tour Option : {tour.optionName}</p>
                                        </div>
                                        <div className="hotel-table-name">
                                            <Table
                                                responsive="xl"
                                                striped
                                                bordered
                                                hover
                                                className="totalofdata"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Adult</th>
                                                        {tour?.child > 0 && <th>Child</th>}

                                                        {tour?.infant > 0 && <th>Infant</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {tour.adult}
                                                        </td>
                                                        {tour?.child > 0 && (
                                                            <td>
                                                                {tour.child}
                                                            </td>
                                                        )}
                                                        {tour?.infant > 0 && (
                                                            <td>
                                                                {tour.infant}
                                                            </td>
                                                        )}
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <Row className="grandoption">
                                                <Col md={12} xs={24}>
                                                    <p className="grandone-1">Total</p>
                                                </Col>
                                                <Col md={12} xs={24}>
                                                    <p className="grandone">
                                                        ₹ {tour.serviceTotal}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    )
                })}
                <Row className="grandoption">
                <Col md={24} xs={24}>
                <Row className="grandoption">
                                                <Col md={12} xs={24}>
                                                    <p className="grandone-1">Grand Total</p>
                                                </Col>
                                                <Col md={12} xs={24}>
                                                    <p className="grandone">
                                                    {totalAmount}
                                                    </p>
                                                </Col>
                                            </Row>
              
                    </Col>
                    <Col md={24} xs={24}>
                        <Button
                            className="create-auotation"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            Add More
                        </Button>
                    </Col>
                   
                    <Col md={24} xs={24}>
                        <Button
                            className="create-auotation"
                            data-dismiss="modal"
                            onClick={() => goto()}
                        >
                            Checkout Page
                        </Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

export default ShowCartModel
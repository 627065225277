import React, { useContext, useEffect, useState } from "react";
import { Card, Radio, Space } from "antd";
import { useAuthContext } from "../providers/AuthProvider";
import { GlobalStatesContext } from "../../common/providers";
import type { RadioChangeEvent } from 'antd';
const PayMentSelection = ({ userId, setUserId, setModalVisibl, setValue, value }) => {
  const { setUser, setAccessToken, updateLoginFlag, resetIsLogin } =
    useAuthContext();

    const {
        state: {
          otherData: {  },
        },
        AddTransFee
      } = useContext(GlobalStatesContext);
    //  const [value, setValue] = useState();
    const onChange = (e: RadioChangeEvent) => {
      console.log('radio checked', e.target.value);
      setValue(e.target.value);
      AddTransFee(e.target.value);
    //  if(value!='')
    //  {
    //     AddTransFee(value);
    //  }
        //setValue(e.target.value);
      };
     
  return (
    <div>
        <div className="promo-card apply-promo-fbt">
        
        <Card className="flight-cards-details">
          <div className="promo-card-header">
            <p className="name">Select Payment Method</p>
          </div>
        
                <div className="promo-cp-coupons mt-3">
                  <div className="inline-cp-promo">
                  <Radio.Group onChange={onChange} value={value}>
                      <Space direction="vertical">
                        <Radio value={"DC"}>Debit Card</Radio>
                        <Radio value={"CC"}>Credit Card</Radio>
                        <Radio value={"NB"}>Net banking</Radio>
                        <Radio value={"UPI"}>UPI</Radio>
                      </Space>
                    </Radio.Group>
                  </div>

                  <div className="promo-percentage-cp">
                    <p>
                      {/* {item.Description} */}
                    
                    </p>
                  </div>
                </div>
            
        </Card>
      
    </div>
    </div>
  );
};
export default PayMentSelection;

import React, { useState, useRef, useEffect } from "react";
import { Modal, Form, Row, Col, Statistic, Input, Button, message } from "antd";
import "./verifyOtpModal.scss";
import sendImg from "../../assets/images/send-otp-2.png";

function VerifyOtpModal({
  visible,
  setVerifyModalVisible,
  handleVerifyOtp,
  handleResendOtp,
  mobile,
}) {
  const [otpverify] = Form.useForm();
  const { Countdown } = Statistic;
  const [resendOtp, setResendOtp] = useState(false);

  const d1 = useRef(null);
  const d2 = useRef(null);
  const d3 = useRef(null);
  const d4 = useRef(null);
  const targetTime = new Date().getTime() + 120000;
  const [deadline, setDeadline] = useState(targetTime);

  const handelCountdown = () => {
    // Modal.warning({
    //   title: 'OTP Expired',
    //   content: 'Your OTP Expired Please Try Again',
    //   onOk() { setResendOtp(true) }
    // });
    message.error("Your OTP Expired Please Try Again");
    setResendOtp(true);
  };

  const submitOtpForm = (data) => {
    let otp = data.d1 + data.d2 + data.d3 + data.d4;
    handleVerifyOtp(otp);
  };

  const handleOtpVal = (e, inputBox) => {
    let val = parseInt(e.target.value);
    if (val >= 0) inputBox.current.focus();
  };
  const handleBack = (e, inputBox) => {
    if (!e.target.value && (e.keyCode === 8 || e.keyCode === 46))
      inputBox.current.focus();
  };

  const validateInput = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
      return;
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }

    var regex = /^[0-9]*$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const handleResend = () => {
    const targetTime = new Date().getTime() + 180000;
    otpverify.resetFields();
    handleResendOtp();
    setDeadline(targetTime);
  };

  useEffect(() => {
    otpverify.resetFields();
  }, [visible]);

  return (
    <Modal
      className="modal-login-new-1"
      centered
      visible={visible}
      onOk={() => setVerifyModalVisible(false)}
      onCancel={() => setVerifyModalVisible(false)}
    >
      <Form form={otpverify} onFinish={submitOtpForm}>
        <Row>
          <Col md={24} xs={24}>
            <img className="otp-img" src={sendImg} alt="search-img" />
          </Col>
          <Col md={24} xs={24}>
            <Countdown
              className="countdown"
              onFinish={handelCountdown}
              value={deadline}
              format="mm:ss"
            />
          </Col>
          <Col md={24} xs={24}>
            <div className="otp-shadow">
              <p>Enter OTP sent to your mobile number {mobile ? mobile : ""}</p>
              <Row gutter={8}>
                <Col className="otp-shadow-1" md={6} xs={6}>
                  <Form.Item
                    className="otp-margin-bottom"
                    name="d1"
                    autoFocus
                    rules={[
                      {
                        required: true,
                        message: " ",
                      },
                      {
                        pattern: "^[0-9]{1}$",
                        message: " ",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="newpassword"
                      autoComplete="off"
                      autoFocus
                      ref={d1}
                      type="tel"
                      maxLength="1"
                      onChange={(e) => handleOtpVal(e, d2)}
                      onKeyPress={validateInput}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col className="otp-shadow-1" md={6} xs={6}>
                  <Form.Item
                    className="otp-margin-bottom"
                    name="d2"
                    rules={[
                      {
                        required: true,
                        message: " ",
                      },
                      {
                        pattern: "^[0-9]{1}$",
                        message: " ",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="newpassword"
                      autoComplete="off"
                      ref={d2}
                      type="tel"
                      maxLength="1"
                      onChange={(e) => handleOtpVal(e, d3)}
                      onKeyDown={(e) => handleBack(e, d1)}
                      onKeyPress={validateInput}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col className="otp-shadow-1" md={6} xs={6}>
                  <Form.Item
                    className="otp-margin-bottom"
                    name="d3"
                    rules={[
                      {
                        required: true,
                        message: " ",
                      },
                      {
                        pattern: "^[0-9]{1}$",
                        message: " ",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="newpassword"
                      autoComplete="off"
                      ref={d3}
                      type="tel"
                      maxLength="1"
                      onChange={(e) => handleOtpVal(e, d4)}
                      onKeyDown={(e) => handleBack(e, d2)}
                      onKeyPress={validateInput}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col className="otp-shadow-1" md={6} xs={6}>
                  <Form.Item
                    className="otp-margin-bottom"
                    name="d4"
                    rules={[
                      {
                        required: true,
                        message: " ",
                      },
                      {
                        pattern: "^[0-9]{1}$",
                        message: " ",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="newpassword"
                      autoComplete="off"
                      ref={d4}
                      type="tel"
                      maxLength="1"
                      autoComplete="off"
                      onKeyDown={(e) => handleBack(e, d3)}
                      onKeyPress={validateInput}
                      onPressEnter={() => otpverify.submit()}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {resendOtp && (
                <span className="login-person-1" onClick={handleResend}>
                  Resend code
                </span>
              )}
            </div>
          </Col>

          <Col md={24} xs={24}>
            <Button className="google-btn" htmlType="submit">
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default VerifyOtpModal;

import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Tabs,
  Tooltip,
} from "antd";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import dateFormat from "dateformat";
// import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { calculateDuration } from "../../../common/AllTickets/Flight/flightHelper";
import ApiClient from "../../../helpers/ApiClient";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import {
  MailOutlined,
  RightOutlined,
  ShoppingOutlined,
  LineOutlined,
} from "@ant-design/icons";
import "./Flight.scss";
import "antd/dist/antd.css";
import "font-awesome/css/font-awesome.min.css";

export const FlightTimings = ({ flightInfo, airSearchData, fareFamilies,isSearch =false }) => {
  const { user } = useAuthContext();
  const [fareRulesResp, setFareRulesResp] = useState("");
  const { TabPane } = Tabs;
  const [cmsFareRules, setCmsFareRules] = useState({});
  const [flightDetails, setFlightDetails] = useState(false);

  const showModal = () => {
    let obj = {
      airlineCode: flightInfo.airLine,
      fareType: flightInfo?.fareFamilies?.fareFamilies[0]?.coupanType,
      roleID: user?.Role?.RoleId ?? 4,
      membershipID: user?.Membership ?? 1,
    };
    getCmsFareRules(obj);
  };

  const getCmsFareRules = (obj) => {
    ApiClient.get("admin/getFlightFareRules", obj)
      .then((res) => {
        if (res.status == 200) {
          setCmsFareRules(res.data);
          setFlightDetails({
            visible: true,
          });
        } else {
          setCmsFareRules({});
          setFlightDetails({
            visible: true,
          });
        }
      })
      .catch((e) => {
        setCmsFareRules({});
        setFlightDetails({
          visible: true,
        });
      });
  };

  const fetchFlightFares = (str, event) => {
   let selectedFareid=0;
    if(flightInfo.isRoundTrip){
      selectedFareid=  sessionStorage.getItem('FareId1');
    }else{
      selectedFareid= sessionStorage.getItem('FareId');
    }
   
    if (str === "2") {
      let fareReqObj = {
        traceId: airSearchData.traceId,
        flightId: flightInfo.flightId,
        airTravelType: airSearchData.airTravelType,
        mappingType: airSearchData.resultsType,
        itineraryViewType: "1",
        fareId:selectedFareid,
        isRoundTrip:flightInfo.isRoundTrip
      };
      ApiClient.post("flights/airFareRules", fareReqObj)
        .then((result) => {
          return result;
        })
        .then((resp) => {
          if (resp.statusCode === 200) {
            if (resp.data) {
              if (resp.data.fareRules) {
                setFareRulesResp(resp);
              }
            }

            // setFareRulesResp(resp.data.errors[0].errorDetail);
            // props.setFlightFareRules(resp.data.errors[0].errorDetail);
          } else {
            let error_resp = {
              data: {
                fareRules: [
                  {
                    ruleDetails:
                      "Something went wrong , Fare Rules not Available",
                  },
                ],
              },
            };
            setFareRulesResp(error_resp);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const formatFlightTime = (flightTime) => {
    let [date, time] = flightTime.split("T");
    let [hour, minute, seconds] = time.split(":");
    return `${hour}:${minute}`;
  };

  const luggageDetails = () => {
    return (
      <div className="luggage-tooltip">
        <Row gutter={16}>
          {fareFamilies.fareFamilies[0].baggage.map((bag, i) => {
            return (
              <Col key={i}>
                <p>{bag.cityPair}</p>
                <p>
                  <i className="fa fa-plane"></i>{" "}
                  <span>Airline {bag.airline}</span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.baggageInfo} CheckedIn Baggage </span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.cabinBaggageInfo} Cabin Baggage</span>
                </p>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };
  let selectedFareids=0;
  let isRefundable = true;
  if(isSearch == false)
  {
    isRefundable =  fareFamilies.fareFamilies[0].isRefundable

  }
  else
  {
    if(flightInfo.isRoundTrip){
      selectedFareids=  sessionStorage.getItem('FareId1');
    }else{
      selectedFareids= sessionStorage.getItem('FareId');
    }
    isRefundable = fareFamilies.fareFamilies.filter(x=>x.fareId ==selectedFareids)[0]?.isRefundable
  }

  // const collapseHeader = (
  //   <div>
  //     <div className="collapse-header">
  //       <Row>
  //         {
  //           <Col span={14} className="flight-icon">
  //             <p>Facilities</p>
  //             {/* <img
  //             src={require("../../../assets/images/Flighticons/vistara.png")}
  //             alt=""
  //           />
  //           <div className="flight-names-block">
  //             <p>Vistara</p>
  //             <p> Operated by vistara</p>
  //           </div> */}
  //           </Col>
  //         }
  //         <Col span={6} className="modal-flight-icon">
  //           {/*  <i className="fa fa-plane"></i>
  //           <span>AirbusA320 (Jet)</span> */}
  //         </Col>
  //         <Col span={4} className="timer-flight-icon">
  //           {/* <ClockCircleOutlined className="icon" />
  //           <span>2h 15min</span> */}
  //           {/* {calculateDuration(
  //                   flightInfo.flightSegments[0].departureDateTime,
  //                   flightInfo.flightSegments[0].arrivalDateTime
  //                 )} */}
  //         </Col>
  //       </Row>
  //     </div>
  //   </div>
  // );
  return (
    <>
      <div className="flight-timings">
        {fareFamilies.fareFamilies.length > 0 && (
          <div className="refundText">
            {isRefundable ? "" : "Non"} Refundable
          </div>
        )}
        <div className="timing_wrapper flight-syt-time">
          <div className="plane-timings">
            <ul>
              <li>
                <p>
                  {formatFlightTime(
                    flightInfo.flightSegments[0].departureDateTime
                  )}
                </p>
              </li>
              <li>
                <LineOutlined />
              </li>
              <li>
                {" "}
                <div className="travel-time">
                  {/* <p>
                    <ClockCircleOutlined />
                  </p> */}
                  <div>
                    <p>{flightInfo?.duration}</p>
                  </div>
                  {/* {flightInfo.flightSegments.length > 0 ? (
                    <div>
                      <p>
                        {calculateDuration(
                          flightInfo.flightSegments[0].departureDateTime,
                          flightInfo.flightSegments[
                            flightInfo.flightSegments.length - 1
                          ].arrivalDateTime
                        )}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        {calculateDuration(
                          flightInfo.flightSegments[0].departureDateTime,
                          flightInfo.flightSegments[0].arrivalDateTime
                        )}
                      </p>
                    </div>
                  )} */}
                </div>
              </li>
              <li>
                <LineOutlined />
              </li>
              <li>
                {flightInfo.flightSegments.length > 0 ? (
                  <div>
                    <p>
                      {formatFlightTime(
                        flightInfo.flightSegments[
                          flightInfo.flightSegments.length - 1
                        ].arrivalDateTime
                      )}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      {formatFlightTime(
                        flightInfo.flightSegments[0].arrivalDateTime
                      )}
                    </p>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="facility-icons">
          <Tooltip placement="bottom" title={luggageDetails}>
            <p className="details-bag-icon">
              Baggage <ShoppingOutlined />
            </p>
          </Tooltip>
        </div>
       {isSearch &&
        <div className="details-btn">
          <p className="details-text" onClick={showModal}>
            Details
          </p>
        </div>
}
      </div>

      {/* Details Modal */}
      <div>
        <Modal
          title="Flight Details"
          visible={flightDetails}
          footer={[null]}
          onOk={(e) => setFlightDetails(false)}
          onCancel={(e) => setFlightDetails(false)}
          className="flight-details-modal"
        >
          <div className="all-times-local">
            <Tabs
              defaultActiveKey="1"
              onTabClick={(str, e) => fetchFlightFares(str, e)}
            >
              <TabPane tab="Segments" key="1">
                <div className="from-to-modal-details">
                  <Row gutter={16} className="more-details-row">
                    <Col md={14} xs={24}>
                      <Row align="middle" gutter={16}>
                        <Col md={8} xs={8} className="more-details-list">
                          <p>{flightInfo.flightSegments[0].origin}</p>
                          <p>
                            <strong>
                              {dateFormat(
                                flightInfo.flightSegments[0].departureDateTime,
                                "HH:MM"
                              )}
                              ,
                            </strong>
                            <span>
                              {dateFormat(
                                flightInfo.flightSegments[0].departureDateTime,
                                "dd mmm (ddd)"
                              )}
                            </span>
                          </p>
                        </Col>
                        <Col md={8} xs={8}>
                          <div className="break-journey-city">
                            <div className="inner_wrapper">
                              {flightInfo.flightSegments.map(
                                (flightSegInfo, index) =>
                                  index !== 0 ? (
                                    <div
                                      className="stop_points"
                                      key={flightSegInfo.segId}
                                    >
                                      <Tooltip
                                        placement="top"
                                        title={() => (
                                          <div className="tooltip-data">
                                            {flightInfo.flightSegments.length -
                                              1 >
                                              1 ? (
                                              <h4>Change</h4>
                                            ) : (
                                              ""
                                            )}
                                            <p>
                                              {flightSegInfo.origin +
                                                " " +
                                                flightSegInfo.originName}
                                            </p>
                                          </div>
                                        )}
                                        className="citynames-tooltip"
                                      >
                                        <span className="break-city-name pointer_cursor">
                                          {flightSegInfo.origin}
                                        </span>
                                      </Tooltip>
                                    </div>
                                  ) : null
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={8} xs={8} className="more-details-list">
                          <p>
                            {
                              flightInfo.flightSegments[
                                flightInfo.flightSegments.length - 1
                              ].destination
                            }
                          </p>
                          <p>
                            <strong>
                              {dateFormat(
                                flightInfo.flightSegments[
                                  flightInfo.flightSegments.length - 1
                                ].arrivalDateTime,
                                "HH:MM"
                              )}
                              ,
                            </strong>
                            <span>
                              {dateFormat(
                                flightInfo.flightSegments[
                                  flightInfo.flightSegments.length - 1
                                ].arrivalDateTime,
                                "dd mmm (ddd)"
                              )}
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} xs={24} className="trip-duration-time">
                      <p>Total trip duration:</p>
                      <p>
                        {flightInfo.flightSegments.length > 0
                          ? calculateDuration(
                            flightInfo.flightSegments[0].departureDateTime,
                            flightInfo.flightSegments[
                              flightInfo.flightSegments.length - 1
                            ].arrivalDateTime
                          )
                          : calculateDuration(
                            flightInfo.flightSegments[0].departureDateTime,
                            flightInfo.flightSegments[0].arrivalDateTime
                          )}{" "}
                        |
                        {flightInfo.flightSegments.length - 1 === 0 ? (
                          <>Direct</>
                        ) : (
                          <>{flightInfo.flightSegments.length - 1} stops </>
                        )}
                      </p>
                    </Col>
                    <Col md={4} xs={24} className="trip-duration-time">
                      <p>class:</p>
                      <p>{airSearchData.cabinClass}</p>
                    </Col>
                  </Row>
                </div>

                {flightInfo.flightSegments.map((flightSegment, i) => (
                  <div className="total-flight-details">
                    <div className="to-fro-flight-details">
                      <div className="from-city-flight-details">
                        <span>{flightSegment.origin}</span>
                      </div>
                      <div>
                        <span>
                          departure:{" "}
                          <strong>
                            {dateFormat(
                              flightSegment.departureDateTime,
                              "HH:MM"
                            )}
                          </strong>
                          {" , "}
                        </span>
                        <span>
                          {dateFormat(
                            flightSegment.departureDateTime,
                            "dd mmm (ddd)"
                          )}
                        </span>
                        <span>
                          <strong>
                            {"  "}
                            {flightInfo.airLineName}(
                            {flightSegment.marketingAirline}-
                            {flightSegment.flightNumber})
                          </strong>
                        </span>
                      </div>
                    </div>
                    <div className="collapsed-card">
                      {/* <Card bordered={false}>
                        <Collapse ghost={true}>
                          <Panel header={collapseHeader} key="1">
                            <div className="acc-body">
                              <Row>
                                <Col md="4" className="padd-right-0">
                                  <div className="acc-body-section">
                                    <DesktopOutlined className="icon" />
                                    <p>Media and entertainment in price!</p>
                                  </div>
                                  <div className="acc-body-section">
                                    <CoffeeOutlined className="icon" />
                                    <p>Refreshments in price!</p>
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="acc-body-section">
                                    <UserOutlined className="icon" />
                                    <p>Food and beverages in price!</p>
                                  </div>
                                  <div className="acc-body-section">
                                    <ShoppingOutlined className="icon" />
                                    <p>Checked baggage in price!</p>
                                  </div>
                                </Col>
                              </Row>
                              <div className="acc-body-section seat-sec">
                                <i className="fa fa-user"></i>
                                <p>Seat configuration: 3-3</p>
                              </div>
                              <div className="acc-body-section seat-sec flight-class">
                                <p>
                                  FlightNumber:
                                  <span>{flightSegment.flightNumber}</span>
                                </p>
                                <p className="ec-class">
                                  Class: <span>{airSearchData.cabinClass}</span>
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      </Card> */}
                    </div>

                    <div className="to-fro-flight-details">
                      <div className="from-city-flight-details">
                        <span>{flightSegment.destination}</span>
                      </div>
                      <div>
                        <span>
                          arrival:{" "}
                          <strong>
                            {formatFlightTime(flightSegment.arrivalDateTime)}
                          </strong>
                        </span>
                        {" , "}
                        <span>
                          {dateFormat(
                            flightSegment.arrivalDateTime,
                            "dd mmm (ddd)"
                          )}
                        </span>
                      </div>
                    </div>
                    {i < flightInfo.flightSegments.length - 1 && (
                      <div className="halt-wrapper">
                        <p>
                          Layover Time:{" "}
                          {calculateDuration(
                            flightInfo.flightSegments[i + 1].departureDateTime,
                            flightSegment.arrivalDateTime
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </TabPane>

              {cmsFareRules?.cancelPolicyDescription ? (
                <TabPane tab="Cancel Policy" key="3">
                  <div className="fareRule-container">
                    <div>
                      {ReactHtmlParser(cmsFareRules.cancelPolicyDescription)}
                    </div>
                  </div>
                </TabPane>
              ) : (
                <TabPane tab="Cancel Policy" key="2">
                  <div className="fareRule-container">
                    {!fareRulesResp ? (
                      <p>Loading...</p>
                    ) : (
                      fareRulesResp.data.fareRules.map((rule, i) => {
                        return (
                          <div key={i}>
                            <pre>{ReactHtmlParser(rule.ruleDetails)}</pre>
                          </div>
                        );
                      })
                    )}
                  </div>
                </TabPane>
              )}
              {cmsFareRules?.reschedulePolicyDescription && (
                <TabPane tab="Reschedule Policy" key="4">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(
                        cmsFareRules.reschedulePolicyDescription
                      )}
                    </pre>
                  </div>
                </TabPane>
              )}

              {cmsFareRules?.covidRulesDescription && (
                <TabPane tab="Covid Rules Policy" key="5">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(cmsFareRules.covidRulesDescription)}
                    </pre>
                  </div>
                </TabPane>
              )}
              {cmsFareRules?.otherRulesDescription && (
                <TabPane tab="Other Rules Policy" key="6">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(cmsFareRules.otherRulesDescription)}
                    </pre>
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </Modal>
      </div>
      {/* Details Modal */}

      {/* Fare Type Modal */}
    </>
  );
};

const Flight = (props) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  const flightInfo = props.flightInfo;
  const flightSearchObj = props.flightSearchObj;
  const fareFamilies = props.fareFamilies;
  const promoData = props.promoData;

  const AirportToolTip = (heading, code, name) => {
    return (
      <div className="tooltip-data">
        <h4>{heading}</h4>
        <p>{code}</p>
        <p>{name}</p>
      </div>
    );
  };
  // const getSegmentTooltip = (segments) => {
  //   return (
  //     <Tooltip
  //       placement="top"
  //       title={
  //         <div classNmae="d-flex">
  //           {segments.map((seg, i) => (
  //             <div className="tooltip-data">
  //               <h4>{"Change"}</h4>
  //               <p>{seg.origin}</p>
  //               <p>{seg.originName}</p>
  //             </div>
  //           ))}
  //         </div>
  //       }
  //       className="citynames-tooltip"
  //     ></Tooltip>
  //   );
  // };

  return (
    <div className={" flight-results-container"}>
      <div className="combined-view">
        <div className="combined-flight-results-container">
          <Card className="combined-flight-card">
            <Row>
              <Col md={24} xs={24} className="flight-trip-details">
                <div className="itinary-details">
                  <div className="itinary-flight-name">
                    <img
                      className="airline-img"
                      src={flightInfo.airLineLogo}
                      alt={flightInfo.airLineName}
                    />
                    <div className="token-number-flught">
                      <p style={{ fontWeight: 700 }}>
                        {flightInfo.airLineName}
                      </p>
                      &nbsp;
                      <p style={{ fontWeight: 700 }}>
                        ({flightInfo.flightSegments[0].marketingAirline}-
                        {flightInfo.flightSegments[0].flightNumber})
                      </p>
                    </div>
                  </div>
                  <div className="fromTo">
                    <div className="cities-from-to">
                      <div className="loc_wrapper">
                        <Tooltip
                          placement="top"
                          title={AirportToolTip(
                            "Arrival",
                            flightInfo.origin,
                            flightInfo.originName
                          )}
                          className="citynames-tooltip"
                        >
                          <span className="code pointer_cursor">
                            {flightInfo.origin}
                          </span>
                        </Tooltip>
                        <div className="break-journey-city">
                          <div className="inner_wrapper">
                            {flightInfo.flightSegments.map(
                              (flightSegInfo, index) =>
                                index === 1 ? (
                                  <div
                                    className="stop_points"
                                    key={flightSegInfo.segId}
                                  >
                                    <Tooltip
                                      placement="top"
                                      title={AirportToolTip(
                                        "Change",
                                        flightSegInfo.origin,
                                        flightSegInfo.originName
                                      )}
                                      className="citynames-tooltip"
                                    >
                                      <span className="break-city-name pointer_cursor">
                                        {flightSegInfo.origin}
                                      </span>
                                    
                                      
                                     
                                      {flightInfo.flightSegments.length > 2 ? (
                                        <>
                                          <span className="break-city-name pointer_cursor">
                                          {flightSegInfo.destination}
                                        </span>
                                        {/* <span className="segment-span">
                                          +{" "}
                                          {flightInfo.flightSegments.length - 2}{" "}
                                          Stop(s)
                                        </span> */}
                                        </>
                                      ) : null}
                                    </Tooltip>
                                  </div>
                                ) : null
                            )}
                            {/* {flightInfo.flightSegments.length>0?
                           <div
                           className="stop_points"
                           
                         >
<span className="break-city-name pointer_cursor">
                                        {getSegmentTooltip(flightInfo.flightSegments)}
                                      </span>
                           </div>
                           :null} */}
                          </div>
                        </div>
                        <Tooltip
                          placement="top"
                          title={AirportToolTip(
                            "Destination",
                            flightInfo.destination,
                            flightInfo.destiantionName
                          )}
                          className="citynames-tooltip"
                        >
                          <span className="code pointer_cursor">
                            {flightInfo.destination}
                          </span>
                        </Tooltip>
                      </div>
                      {/* <div className="terminal_wrapper">
                        
                        {flightInfo.flightSegments[0].arrivalTerminal != "" && (
                          <>
                            <p>
                              Terminal:{" "}
                              {flightInfo.flightSegments[0].departureTerminal !=
                              ""
                                ? " " +
                                  flightInfo.flightSegments[0].departureTerminal
                                : null}{" "}
                            </p>
                            <p>
                              {flightInfo.flightSegments[0].arrivalTerminal}
                            </p>
                          </>
                        )}
                        
                      </div> */}
                    </div>

                    <div className="arrive-dept-date">
                      <span className="dateDay">
                        {dateFormat(
                          flightInfo.flightSegments[0].departureDateTime,
                          "dd mmm (ddd)"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flight-class-details">
                    <span className="stopsType">
                      {flightInfo.flightSegments.length === 1 ? (
                        <>Direct</>
                      ) : (
                        <>{flightInfo.flightSegments.length - 1} stops </>
                      )}
                    </span>
                    <span className="flight-class">
                      class: {flightSearchObj.cabinClass}
                    </span>
                    {agent && (
                      <span className="flight-class">
                        Fare Type: {fareFamilies.fareFamilies[0].coupanType}
                      </span>
                    )}
                  </div>
                </div>
                <FlightTimings
                  flightInfo={flightInfo}
                  airSearchData={flightSearchObj}
                  fareFamilies={fareFamilies}
                 
                />
              </Col>
            </Row>
            {promoData && !agent
              ? promoData.length > 0 && (
                <div className="promo-strip">
                  <div className="promoData">
                    Use code {promoData[0].Code} to get FLAT{" "}
                    {promoData[0].Discount}{" "}
                    {promoData[0].DiscountType === 0 ? "" : "%"} instant
                    discount in this flight.
                  </div>
                </div>
              )
              : ""}
          </Card>
        </div>
      </div>
    </div>
  );
};

export const FlightSelection = ({
  flightInfo,
  changeFlightResultsView,
  flightSearchObj,
  selectedFlight,
  showNetFare,
  journeyType = false,
}) => {
  const [flightArr, setFlightArr] = useState([]);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [openFareType, setFlightFareType] = useState(false);
  const {
    isLogin: { agent },
  } = useAuthContext();
  const onClieckChooseFare = () => {
    setFlightFareType(true);
  }
  const onClickChooseFlight = (fareid) => {
    if(flightInfo.isRoundTrip === true)
    {
      sessionStorage.removeItem('FareId1');
    sessionStorage.setItem('FareId1', fareid);
    }
    else
    {
    sessionStorage.removeItem('FareId');
    sessionStorage.setItem('FareId', fareid);
    }

    try {
      if (
        flightSearchObj.resultsType === "Combined" &&
        flightSearchObj.airTravelType === "roundTrip"
      ) {
        flightInfo.flightDetails.map((flightDetObj) => {
          flightDetObj["fareFamilies"] = flightInfo.fareFamilies;
          flightArr.push(flightDetObj);
          setFlightArr([flightArr]);
        });
        changeFlightResultsView(flightArr);
      } else {
        if (
          selectedFlight.length > 0 &&
          flightSearchObj.airTravelType === "roundTrip"
        ) {
          if (journeyType === "return") {
            if (selectedFlight.length === 2) {
              let copySelectedFlights = [...selectedFlight];
              copySelectedFlights[1] = flightInfo;

              changeFlightResultsView(copySelectedFlights);
            } else {
              changeFlightResultsView([...selectedFlight, flightInfo]);
            }
          } else {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[0] = flightInfo;

            changeFlightResultsView(copySelectedFlights);
          }
        } else {
          changeFlightResultsView([flightInfo]);
        }
        scrollToListTop();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const scrollToListTop = () => {
    document
      .getElementsByClassName("modify-search-container")[0]
      .scrollIntoView({
        behavior: "smooth",
      });
  };

  const [openMailModal, setOpenMailModal] = useState(false);
  const showMailModal = () => {
    setOpenMailModal(true);
  };
  const openOk = (e) => {
    setOpenMailModal(false);
  };

  const OpenCancel = (e) => {
    setOpenMailModal(false);
  };
  const [openNumberModal, setOpenNumberModal] = useState(false);
  const showNumberModal = () => {
    setOpenNumberModal(true);
  };

  const clickOk = (e) => {
    setOpenNumberModal(false);
  };

  const clickCancel = (e) => {
    setOpenNumberModal(false);
  };
  const [Emailform] = Form.useForm();

  const sendEmail = (formData) => {
    let emailData = {
      to: formData.email,
      data: {
        url: document.location.href,
        airLine: flightInfo.airLine,
        airLineLogo: flightInfo.airLineLogo,
        airLineName: flightInfo.airLineName,
        cabinClass: flightSearchObj.cabinClass,
        currency: flightInfo.fareFamilies.fareFamilies[0].currency,
        destiantionName: flightInfo.destiantionName,
        destination: flightInfo.destination,
        duration: flightInfo.duration,
        flightId: flightInfo.flightId,
        flightSegments: flightInfo.flightSegments,
        isBookOffline: flightInfo.isBookOffline,
        origin: flightInfo.origin,
        originName: flightInfo.originName,
        totalFare: Number(
          flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
        ).toFixed(2),
      },
      cc: "",
      bcc: "",
      subject: "Flight Information",
    };

    ApiClient.post("email/flightsegment", emailData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Mail Sent Successfully", 3);
          Emailform.resetFields();
          OpenCancel();
        }
      })
      .catch((e) => console.log(e));
  };
  const priceToolTip = (x) => {
    let base = 0;
    let service = 0;
    let markup =0;
    let gstOnMarkup =0;
    
    if(x == undefined)
    {
   
   
     markup = Number(flightInfo.fareFamilies.fareFamilies[0].markup * flightInfo.fareFamilies.fareFamilies[0].totalpax);
    base += flightInfo.fareFamilies.fareFamilies[0].totalBaseFare;
    service += flightInfo.fareFamilies.fareFamilies[0].totalTaxFare;
     gstOnMarkup =Number(flightInfo.fareFamilies.fareFamilies[0].gstOnMarkup);
    }
    else{
      markup = Number(x?.markup * x?.totalpax);
      base += x?.totalBaseFare;
      service += x?.totalTaxFare;
       gstOnMarkup =Number(x?.gstOnMarkup);
    }
    // flightInfo.fareFamilies.fareFamilies[0].flightFares.forEach((fare) => {
    //   if (fare.fareTag == "Base") {
    //     base += fare.amount;
    //     // if (fare.paxType === 'ADT') {
    //     //   base += fare.amount
    //     // } else if (fare.paxType === 'CHD') {
    //     //   base += fare.amount
    //     // } else if (fare.paxType === 'INF') {
    //     //   base += fare.amount
    //     // }
    //   } else if (fare.fareTag == "Tax") {
    //     service += fare.amount;
    //     // if (fare.paxType === 'ADT') {
    //     //   service += fare.amount
    //     // } else if (fare.paxType === 'CHD') {
    //     //   service += fare.amount
    //     // } else if (fare.paxType === 'INF') {
    //     //   service += fare.amount
    //     // }
    //   }
    // });

    return (
      <div className="pointer_cursor">
        <p>
          Base Fare: {activeCurrency} {currencyValue(base)}
        </p>
        <p>
          Service Charge: {activeCurrency} {currencyValue(markup)}
        </p>
        <p>
          GST (18%): {activeCurrency} {currencyValue(gstOnMarkup)}
        </p>
        <p>
          Tax Charges: {activeCurrency} {currencyValue(service)}
        </p>
      </div>
    );
  };
const fareType =(x) =>{

  switch(x){
    case "SME":
      return "General";
    case "Discounted":
      return "Discounted";
    case "Corporte":
      return "General";
    case "Non-Refundable":
        return "Non-Refundable";
    case "VFR":
        return "General";
    case "Smart":
        return "Smart";
    case "Stream":
        return "Stream";
    case "Redeemer":
        return "Redeemer";
    case "Boost":
        return "Boost";
    case "Trading":
        return "General";
        case "Supreme6E":
          return "Supreme6E";
    default:
        return "General";
  }

}  
  
  const luggageDetails = (x) => {
    return (
      <div className="luggage-tooltip">
        <Row gutter={16}>
          {x.baggage.map((bag, i) => {
            return (
              <Col key={i}>
                <p>{bag.cityPair}</p>
                <p>
                  <i className="fa fa-plane"></i>{" "}
                  <span>Airline {bag.airline}</span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.baggageInfo} CheckedIn Baggage </span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.cabinBaggageInfo} Cabin Baggage</span>
                </p>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };
  return (
    <>
      <div className="choose-btn-block">
        <div className="share-price-mail">
          <div className="choose-btn-price">
            {showNetFare ? (
              <>
                <span className="currencyType text-line">{activeCurrency}</span>
                <Tooltip title={priceToolTip}>
                  <span className="currency text-line">
                    {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} */}
                    {currencyValue(
                      flightInfo.fareFamilies.fareFamilies[0].totalFare
                    )}
                  </span>
                </Tooltip>

                <div className="text-left">
                  <p className="netfare">
                    {activeCurrency}{" "}
                    {currencyValue(
                      flightInfo.fareFamilies.fareFamilies[0].totalFare + flightInfo.fareFamilies.fareFamilies[0].tds
                      - flightInfo.fareFamilies.fareFamilies[0].commission
                    )}{" "}
                  </p>
                  <p className="netfare">
                    Inc: {activeCurrency}{" "}
                    {currencyValue(
                      flightInfo.fareFamilies.fareFamilies[0].commission
                    )}{" "}
                  </p>
                  <p className="netfare">
                    Tds: {activeCurrency}{" "}
                    {currencyValue(
                      flightInfo.fareFamilies.fareFamilies[0].tds
                    )}{" "}
                  </p>
                </div>
              </>
            ) : (
              <>
                <span className="currencyType">
                  {/* {flightInfo.fareFamilies.fareFamilies[0].currency} */}
                  {activeCurrency}
                </span>
                <Tooltip title={priceToolTip}>
                  <span className="currency">
                    {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} */}
                    {currencyValue(
                      flightInfo.fareFamilies.fareFamilies[0].totalFare
                    )}
                  </span>
                </Tooltip>{" "}
              </>
            )}
          </div>
          <div className="share-section">
            <MailOutlined onClick={() => showMailModal()} />
            {/* <MessageOutlined onClick={showNumberModal} /> */}
          </div>
          <div className="right-price-section">
            <span className="price-text">
              Price for{" "}
              {flightSearchObj.adultCount +
                flightSearchObj.infantCount +
                flightSearchObj.childCount}{" "}
              passenger,
              <br />{" "}
              {flightSearchObj.airTravelType === "oneWay"
                ? "Oneway"
                : "Round Trip"}
            </span>
          </div>
        </div>
        <div className="choose-btn-section">
          <div className="choose-web-btn">
            <button
              className="choose-button"
              onClick={  flightSearchObj.resultsType === "Combined" &&
              flightSearchObj.airTravelType === "roundTrip"?(e) =>onClickChooseFlight(flightInfo.fareFamilies.fareFamilies[0].fareId):(e) => onClieckChooseFare(flightInfo.fareFamilies.fareFamilies[0].fareId)}
            >
              Show More Price
            </button>
          </div>
          <div className="choose-mobile-btn">
            <Link to="/flight/checkout">
              <button
                className="choose-button"
                onClick={(e) => onClickChooseFlight()}
              >
                <RightOutlined />
              </button>
            </Link>
          </div>
        </div>
        {/*   <div className="share-section">
          <MailOutlined onClick={() => showMailModal()} />
           <MessageOutlined onClick={showNumberModal} /> 
        </div>  */}
        <Modal
          title="Send Flight Information"
          visible={openMailModal}
          onOk={openOk}
          onCancel={OpenCancel}
          footer={false}
          className="flight-infoModal"
          centered
          width={400}
        >
          <Form onFinish={sendEmail} form={Emailform}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Required!" },
                { type: "email", message: "Email is not a valid email" },
              ]}
            >
              <Input placeholder="Enter Email Address" />
            </Form.Item>

            <Button type="primary" htmlType={"submit"}>
              Send
            </Button>
          </Form>
        </Modal>
        <Modal
          title="Send Flight Information"
          visible={openNumberModal}
          onOk={clickOk}
          onCancel={clickCancel}
          className="flight-infoModal"
          footer={false}
          centered
          width={400}
        >
          <Input placeholder="Enter Mobile Number" />
          <Button type="primary">Send</Button>
        </Modal>


      </div>
      {/* multi Fare Model */}
      <Modal
        title="Flight Fare list"
        visible={openFareType}
        onOk={(e) => setFlightFareType(false)}
        onCancel={(e) => setFlightFareType(false)}
        className="flight-infoModal"
        footer={false}
        centered
        width={810}
        color={"white"}
      >


        <FlightDetailsComponent flightInfos={flightInfo} airSearchDatas={flightSearchObj} fareFamilie={flightInfo.fareFamilies}></FlightDetailsComponent>

        <div className={" flight-results-container"}>
          <div className="combined-view">
            <div className="combined-flight-results-container">

              <Row className="flight-multi_fare">
                <Col md={agent?3:4} xs={agent?3:4} className="flight-fare-data">
                  Fares
                </Col>
                <Col md={agent?3:5} xs={agent?3:5} className="flight-fare-data">
                  Baggage
                </Col>
                <Col md={agent?4:6} xs={agent?4:6} className="flight-fare-data">
                  Cancel Policy
                </Col>
                { agent && ( <>   <Col md={4} xs={4} className="flight-fare-data">
                Remaining seats
                </Col></>)}
                <Col md={agent?6:4} xs={agent?6:4} className="flight-fare-data">
                 Price
                </Col>
                <Col md={4} xs={4} className="flight-fare-data">
                
                </Col>
              </Row>
              {flightInfo.fareFamilies.fareFamilies.map((x,index )=> {
                return (
                  <Row className="flight-multi_fare_data">
                    <Col md={agent?3:4} xs={agent?3:4} className="flight-fare-data">
                      {agent ==false? fareType(x.coupanType):x.coupanType}
                    </Col>
                    <Col md={agent?4:6} xs={agent?4:6} className="flight-fare-data">
                    <div className="facility-icons">
          <Tooltip placement="bottom" title={luggageDetails(x)}>
            <p className="details-bag-icon flight-bagges">
              View Detial <ShoppingOutlined />
            </p>
          </Tooltip>
        </div>
                      
                    </Col>
                    <Col md={4} xs={4} className="flight-fare-data">
                      <FlightCancelationPolicy flightInfo={flightInfo} airSearchData={flightSearchObj} fareFamilies={flightInfo.fareFamilies} index={index}></FlightCancelationPolicy>
                     
                    </Col>
                    { agent && ( <>  <Col md={3} xs={3} className="flight-fare-data">
                {x.segmentInfos[0].seatRemaining}
                    </Col></>)}
                    <Col md={6} xs={6} className="flight-fare-data">
                        <div className="choose-btn-block">
                          <div className="share-price-mail">
                            <div className="choose-btn-price">
                              {showNetFare ? (
                                <>
                                  <span className="currencyType text-line">{activeCurrency}</span>
                                  <Tooltip title={priceToolTip(x)}>
                                    <span className="currency text-line">
                                      {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} */}
                                      {currencyValue(
                                        x.totalFare
                                      )}
                                    </span>
                                    
                                  </Tooltip>

                                  <div className="text-left">
                                    <p className="netfare">
                                      {activeCurrency}{" "}
                                      {currencyValue(
                                        x.totalFare + x.tds
                                        - x.commission

                                      )}{" "}
                                    </p>
                                    <p className="netfare">
              Inc: {activeCurrency}{" "}
              {currencyValue(
                x.commission
              )}{" "}
            </p>
            <p className="netfare">
              Tds: {activeCurrency}{" "}
              {currencyValue(
                x.tds
              )}{" "}
            </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <span className="currencyType">
                                    {/* {flightInfo.fareFamilies.fareFamilies[0].currency} */}
                                    {activeCurrency}
                                  </span>
                                  <Tooltip title={priceToolTip(x)}>
                                    <span className="currency">
                                      {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} */}
                                      {currencyValue(
                                        x.totalFare
                                      )}
                                    </span>
                                  </Tooltip>{" "}
                                </>
                              )}
                            </div>

                            <div className="right-price-section">
                              <span className="price-text">
                                Price for{" "}
                                {flightSearchObj.adultCount +
                                  flightSearchObj.infantCount +
                                  flightSearchObj.childCount}{" "}
                                passenger,
                                <br />{" "}
                                {flightSearchObj.airTravelType === "oneWay"
                                  ? "Oneway"
                                  : "Round Trip"}
                              </span>
                            </div>
                          </div>

                        </div>

                      </Col><Col md={4} xs={4} className="flight-fare-data">
                        <div className="choose-btn-section">
                          <div className="choose-web-btn">
                            <button
                              className="choose-button"
                              onClick={(e) => { onClickChooseFlight(x.fareId); setFlightFareType(false); } }
                            >
                              Book Now
                            </button>
                          </div>
                          <div className="choose-mobile-btn">
                            <Link to="/flight/checkout">
                              <button
                                className="choose-button"
                                onClick={(e) => onClickChooseFlight(x.fareId)}
                              >
                                <RightOutlined />
                              </button>
                            </Link>
                          </div>
                        </div>
                      </Col>
                  </Row>
                )
              })}
            </div>
          </div>
        </div>

      </Modal>
    </>
  );
};

////Flight Fare detail 


////Flight New Detial Model

const FlightFareDetail = ({ flightInfo, airSearchData, fareFamilies }) => {


  const formatFlightTime = (flightTime) => {
    let [date, time] = flightTime.split("T");
    let [hour, minute, seconds] = time.split(":");
    return `${hour}:${minute}`;
  };


  return (
    <>
      <div className="flight-timings">
        {fareFamilies.fareFamilies.length > 0 && (
          <div className="refundText">
            {fareFamilies.fareFamilies[0].isRefundable ? "" : "Non"} Refundable
          </div>
        )}
        <div className="timing_wrapper flight-syt-time">
          <div className="plane-timings">
            <ul>
              <li>
                <p>
                  {formatFlightTime(
                    flightInfo.flightSegments[0].departureDateTime
                  )}
                </p>
              </li>
              <li>
                <LineOutlined />
              </li>
              <li>
                {" "}
                <div className="travel-time">
                  {/* <p>
                    <ClockCircleOutlined />
                  </p> */}
                  <div>
                    <p>{flightInfo?.duration}</p>
                  </div>
                 
                </div>
              </li>
              <li>
                <LineOutlined />
              </li>
              <li>
                {flightInfo.flightSegments.length > 0 ? (
                  <div>
                    <p>
                      {formatFlightTime(
                        flightInfo.flightSegments[
                          flightInfo.flightSegments.length - 1
                        ].arrivalDateTime
                      )}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      {formatFlightTime(
                        flightInfo.flightSegments[0].arrivalDateTime
                      )}
                    </p>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>

   
      {/* Details Modal */}

      {/* Fare Type Modal */}
    </>
  );
};;

const FlightDetailsComponent = ({ flightInfos, airSearchDatas, fareFamilie }) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  const flightInfo = flightInfos;
  const flightSearchObj = airSearchDatas;
  const fareFamilies = fareFamilie;


  const AirportToolTip = (heading, code, name) => {
    return (
      <div className="tooltip-data">
        <h4>{heading}</h4>
        <p>{code}</p>
        <p>{name}</p>
      </div>
    );
  };

  return (
    <div className={" flight-results-container"}>
      <div className="combined-view">
        <div className="combined-flight-results-container">
          <Card className="combined-flight-card">
            <Row>
              <Col md={24} xs={24} className="flight-trip-details">
                <div className="itinary-details">
                  <div className="itinary-flight-name">
                    <img
                      className="airline-img"
                      src={flightInfo.airLineLogo}
                      alt={flightInfo.airLineName}
                    />
                    <div className="token-number-flught">
                      <p style={{ fontWeight: 700 }}>
                        {flightInfo.airLineName}
                      </p>
                      &nbsp;
                      <p style={{ fontWeight: 700 }}>
                        ({flightInfo.flightSegments[0].marketingAirline}-
                        {flightInfo.flightSegments[0].flightNumber})
                      </p>
                    </div>
                  </div>
                  <div className="fromTo">
                    <div className="cities-from-to">
                      <div className="loc_wrapper">
                        <Tooltip
                          placement="top"
                          title={AirportToolTip(
                            "Arrival",
                            flightInfo.origin,
                            flightInfo.originName
                          )}
                          className="citynames-tooltip"
                        >
                          <span className="code pointer_cursor">
                            {flightInfo.origin}
                          </span>
                        </Tooltip>
                        <div className="break-journey-city">
                          <div className="inner_wrapper">
                            {flightInfo.flightSegments.map(
                              (flightSegInfo, index) =>
                                index === 1 ? (
                                  <div
                                    className="stop_points"
                                    key={flightSegInfo.segId}
                                  >
                                    <Tooltip
                                      placement="top"
                                      title={AirportToolTip(
                                        "Change",
                                        flightSegInfo.origin,
                                        flightSegInfo.originName
                                      )}
                                      className="citynames-tooltip"
                                    >
                                      <span className="break-city-name pointer_cursor">
                                        {flightSegInfo.origin}
                                      </span>
                                      {flightInfo.flightSegments.length > 2 ? (
                                        <span className="segment-span">
                                          +{" "}
                                          {flightInfo.flightSegments.length - 2}{" "}
                                          Stop(s)
                                        </span>
                                      ) : null}
                                    </Tooltip>
                                  </div>
                                ) : null
                            )}
                          </div>
                        </div>
                        <Tooltip
                          placement="top"
                          title={AirportToolTip(
                            "Destination",
                            flightInfo.destination,
                            flightInfo.destiantionName
                          )}
                          className="citynames-tooltip"
                        >
                          <span className="code pointer_cursor">
                            {flightInfo.destination}
                          </span>
                        </Tooltip>
                      </div>
                    </div>

                    <div className="arrive-dept-date">
                      <span className="dateDay">
                        {dateFormat(
                          flightInfo.flightSegments[0].departureDateTime,
                          "dd mmm (ddd)"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flight-class-details">
                    <span className="stopsType">
                      {flightInfo.flightSegments.length === 1 ? (
                        <>Direct</>
                      ) : (
                        <>{flightInfo.flightSegments.length - 1} stops </>
                      )}
                    </span>
                    <span className="flight-class">
                      class: {flightSearchObj.cabinClass}
                    </span>
                    {agent && (
                      <span className="flight-class">
                        Fare Type: {fareFamilies.fareFamilies[0].coupanType}
                      </span>
                    )}
                  </div>
                </div>
                <FlightFareDetail
                  flightInfo={flightInfo}
                  airSearchData={flightSearchObj}
                  fareFamilies={fareFamilies}
                />
              </Col>
            </Row>

          </Card>
        </div>
      </div>
    </div>
  );
};

const FlightCancelationPolicy =  ({ flightInfo, airSearchData, fareFamilies,index }) => {
  const { user } = useAuthContext();
  const [fareRulesResp, setFareRulesResp] = useState("");
  const { TabPane } = Tabs;
  const [cmsFareRules, setCmsFareRules] = useState({});
  const [flightDetails, setFlightDetails] = useState(false);

  const showModal = () => {
    let obj = {
      airlineCode: flightInfo.airLine,
      fareType: flightInfo.fareFamilies.fareFamilies[index].coupanType,
      roleID: user?.Role?.RoleId ?? 4,
      membershipID: user?.Membership ?? 1,
    };
    getCmsFareRules(obj);
  };

  const getCmsFareRules = (obj) => {
    ApiClient.get("admin/getFlightFareRules", obj)
      .then((res) => {
        if (res.status == 200) {
          setCmsFareRules(res.data);
          setFlightDetails({
            visible: true,
          });
        } else {
          setCmsFareRules({});
          setFlightDetails({
            visible: true,
          });
        }
      })
      .catch((e) => {
        setCmsFareRules({});
        setFlightDetails({
          visible: true,
        });
      });
  };

  const fetchFlightFares = (str, event) => {
    if (str === "2") {
      let fareReqObj = {
        traceId: airSearchData.traceId,
        flightId: flightInfo.flightId,
        airTravelType: airSearchData.airTravelType,
        mappingType: airSearchData.resultsType,
        itineraryViewType: "1",
        fareId:flightInfo.fareFamilies.fareFamilies[index].fareId,
        isRoundTrip:flightInfo.isRoundTrip
      };
      ApiClient.post("flights/airFareRules", fareReqObj)
        .then((result) => {
          return result;
        })
        .then((resp) => {
          if (resp.statusCode === 200) {
            if (resp.data) {
              if (resp.data.fareRules) {
                setFareRulesResp(resp);
              }
            }
          } else {
            let error_resp = {
              data: {
                fareRules: [
                  {
                    ruleDetails:
                      "Something went wrong , Fare Rules not Available",
                  },
                ],
              },
            };
            setFareRulesResp(error_resp);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const formatFlightTime = (flightTime) => {
    let [date, time] = flightTime.split("T");
    let [hour, minute, seconds] = time.split(":");
    return `${hour}:${minute}`;
  };

 
  return (
    <>
      <div className="flight-timings">
        <div className="details-btn">
          <p className="details-text" onClick={showModal}>
      
                        View Policy
                    
          </p>
        </div>
      </div>

      {/* Details Modal */}
      <div>
        <Modal
          title="Flight Details"
          visible={flightDetails}
          footer={[null]}
          onOk={(e) => setFlightDetails(false)}
          onCancel={(e) => setFlightDetails(false)}
          className="flight-details-modal"
        >
          <div className="all-times-local">
            <Tabs
              defaultActiveKey="1"
              onTabClick={(str, e) => fetchFlightFares(str, e)}
            >
              <TabPane tab="Segments" key="1">
                <div className="from-to-modal-details">
                  <Row gutter={16} className="more-details-row">
                    <Col md={14} xs={24}>
                      <Row align="middle" gutter={16}>
                        <Col md={8} xs={8} className="more-details-list">
                          <p>{flightInfo.flightSegments[0].origin}</p>
                          <p>
                            <strong>
                              {dateFormat(
                                flightInfo.flightSegments[0].departureDateTime,
                                "HH:MM"
                              )}
                              ,
                            </strong>
                            <span>
                              {dateFormat(
                                flightInfo.flightSegments[0].departureDateTime,
                                "dd mmm (ddd)"
                              )}
                            </span>
                          </p>
                        </Col>
                        <Col md={8} xs={8}>
                          <div className="break-journey-city">
                            <div className="inner_wrapper">
                              {flightInfo.flightSegments.map(
                                (flightSegInfo, index) =>
                                  index !== 0 ? (
                                    <div
                                      className="stop_points"
                                      key={flightSegInfo.segId}
                                    >
                                      <Tooltip
                                        placement="top"
                                        title={() => (
                                          <div className="tooltip-data">
                                            {flightInfo.flightSegments.length -
                                              1 >
                                              1 ? (
                                              <h4>Change</h4>
                                            ) : (
                                              ""
                                            )}
                                            <p>
                                              {flightSegInfo.origin +
                                                " " +
                                                flightSegInfo.originName}
                                            </p>
                                          </div>
                                        )}
                                        className="citynames-tooltip"
                                      >
                                        <span className="break-city-name pointer_cursor">
                                          {flightSegInfo.origin}
                                        </span>
                                      </Tooltip>
                                    </div>
                                  ) : null
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={8} xs={8} className="more-details-list">
                          <p>
                            {
                              flightInfo.flightSegments[
                                flightInfo.flightSegments.length - 1
                              ].destination
                            }
                          </p>
                          <p>
                            <strong>
                              {dateFormat(
                                flightInfo.flightSegments[
                                  flightInfo.flightSegments.length - 1
                                ].arrivalDateTime,
                                "HH:MM"
                              )}
                              ,
                            </strong>
                            <span>
                              {dateFormat(
                                flightInfo.flightSegments[
                                  flightInfo.flightSegments.length - 1
                                ].arrivalDateTime,
                                "dd mmm (ddd)"
                              )}
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} xs={24} className="trip-duration-time">
                      <p>Total trip duration:</p>
                      <p>
                        {flightInfo.flightSegments.length > 0
                          ? calculateDuration(
                            flightInfo.flightSegments[0].departureDateTime,
                            flightInfo.flightSegments[
                              flightInfo.flightSegments.length - 1
                            ].arrivalDateTime
                          )
                          : calculateDuration(
                            flightInfo.flightSegments[0].departureDateTime,
                            flightInfo.flightSegments[0].arrivalDateTime
                          )}{" "}
                        |
                        {flightInfo.flightSegments.length - 1 === 0 ? (
                          <>Direct</>
                        ) : (
                          <>{flightInfo.flightSegments.length - 1} stops </>
                        )}
                      </p>
                    </Col>
                    <Col md={4} xs={24} className="trip-duration-time">
                      <p>class:</p>
                      <p>{airSearchData.cabinClass}</p>
                    </Col>
                  </Row>
                </div>

                {flightInfo.flightSegments.map((flightSegment, i) => (
                  <div className="total-flight-details">
                    <div className="to-fro-flight-details">
                      <div className="from-city-flight-details">
                        <span>{flightSegment.origin}</span>
                      </div>
                      <div>
                        <span>
                          departure:{" "}
                          <strong>
                            {dateFormat(
                              flightSegment.departureDateTime,
                              "HH:MM"
                            )}
                          </strong>
                          {" , "}
                        </span>
                        <span>
                          {dateFormat(
                            flightSegment.departureDateTime,
                            "dd mmm (ddd)"
                          )}
                        </span>
                        <span>
                          <strong>
                            {"  "}
                            {flightInfo.airLineName}(
                            {flightSegment.marketingAirline}-
                            {flightSegment.flightNumber})
                          </strong>
                        </span>
                      </div>
                    </div>
                    

                    <div className="to-fro-flight-details">
                      <div className="from-city-flight-details">
                        <span>{flightSegment.destination}</span>
                      </div>
                      <div>
                        <span>
                          arrival:{" "}
                          <strong>
                            {formatFlightTime(flightSegment.arrivalDateTime)}
                          </strong>
                        </span>
                        {" , "}
                        <span>
                          {dateFormat(
                            flightSegment.arrivalDateTime,
                            "dd mmm (ddd)"
                          )}
                        </span>
                      </div>
                    </div>
                    {i < flightInfo.flightSegments.length - 1 && (
                      <div className="halt-wrapper">
                        <p>
                          Layover Time:{" "}
                          {calculateDuration(
                            flightInfo.flightSegments[i + 1].departureDateTime,
                            flightSegment.arrivalDateTime
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </TabPane>

              {cmsFareRules?.cancelPolicyDescription ? (
                <TabPane tab="Cancel Policy" key="3">
                  <div className="fareRule-container">
                    <div>
                      {ReactHtmlParser(cmsFareRules.cancelPolicyDescription)}
                    </div>
                  </div>
                </TabPane>
              ) : (
                <TabPane tab="Cancel Policy" key="2">
                  <div className="fareRule-container">
                    {!fareRulesResp ? (
                      <p>Loading...</p>
                    ) : (
                      fareRulesResp.data.fareRules.map((rule, i) => {
                        return (
                          <div key={i}>
                            <pre>{ReactHtmlParser(rule.ruleDetails)}</pre>
                          </div>
                        );
                      })
                    )}
                  </div>
                </TabPane>
              )}
              {cmsFareRules?.reschedulePolicyDescription && (
                <TabPane tab="Reschedule Policy" key="4">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(
                        cmsFareRules.reschedulePolicyDescription
                      )}
                    </pre>
                  </div>
                </TabPane>
              )}

              {cmsFareRules?.covidRulesDescription && (
                <TabPane tab="Covid Rules Policy" key="5">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(cmsFareRules.covidRulesDescription)}
                    </pre>
                  </div>
                </TabPane>
              )}
              {cmsFareRules?.otherRulesDescription && (
                <TabPane tab="Other Rules Policy" key="6">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(cmsFareRules.otherRulesDescription)}
                    </pre>
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </Modal>
      </div>
      {/* Details Modal */}

      {/* Fare Type Modal */}
    </>
  );}

export default Flight;

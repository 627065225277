import React, { useState, useEffect } from "react";
import { Col, Row, Layout } from "antd";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Buildpackagesearchnew from "./Buildpackagesearchnew";
import "./buildpackage.scss";
import moment from "moment";
import queryString from "query-string";
import ApiClient from "../../helpers/ApiClient";
import { useSytContext } from "../../common/providers/SytProvider";
import Banner from "../../components/banner/Banner";
import TopCities from "../../components/home/TopCities/TopCities";
import Reviews from "../../common/Reviews/Reviews";

const { Content } = Layout;

const BuildPackage = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { packBanners } = useSytContext();
  const [cityActivitiesData, setCityActivitiesData] = useState({
    sliderData: [],
    cityData: [],
  });

  const getCityActivitiesList = () => {
    ApiClient.get("admin/cityHotels")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter(
            (item) => item.Status === 0 && item.Servicetype === 1
          );
          let result = data.reduce(function (obj, key) {
            obj[key.CountryName] = obj[key.CountryName] || [];
            obj[key.CountryName].push(key);
            return obj;
          }, {});
          setCityActivitiesData({ sliderData: data, cityData: result });
        } else {
          setCityActivitiesData({ sliderData: [], cityData: [] });
        }
      })
      .catch((e) => {
        setCityActivitiesData({ sliderData: [], cityData: [] });
      });
  };

  useEffect(() => {
    getCityActivitiesList();
  }, []);

  const getActivitiesUrl = (url) => {
    if (url) {
      let params = queryString.parse(url);
      params.travelDate = moment().format("YYYY-MM-DD");
      params = queryString.stringify(params);
      return `/activities/results?${params}`;
    } else {
      return "";
    }
  };

  const MobileSlidersettings = {
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="buildyourpackage-section bd-build-img hide-popup-build ">
      <div className="bus-block">
        <div className="bus-banner">
          <Banner banner={packBanners} />
        </div>
        {/* <div className="bus-search-header">
          <h2>Pacakges & Things To Do Around the World</h2>
        </div> */}
        <div className="buses-search">
          <Buildpackagesearchnew
            buildPackageSearch={() => {}}
            modifySearch={false}
            isCartModify={false}
          />
        </div>
      </div>

      <section className="bus-support-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart bd-pkg-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart bd-pkg-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts1.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="help-support-cc">
        <div className="container">
          <Row>
            <Col md={24} xs={24}>
              <h3 className="trip-help-cc">How Shop Your Trip Helps?</h3>
            </Col>
            <Col md={6} xs={24} className="activity-panel-img-cc">
              <img
                src={
                  require("../../assets/images/activity-panel-1.png").default
                }
                alt=""
              />
              <p>
                <span>1. Select Your Destination</span> from 20k+ New & popular
                Tourist Attractions
              </p>
            </Col>
            <Col md={6} xs={24} className="activity-panel-img-cc">
              <img
                src={
                  require("../../assets/images/activity-panel-2.png").default
                }
                alt=""
              />
              <p>
                <span>2. Select Your Accommodation</span> from 500k+
                Accommodations around the Globe
              </p>
            </Col>
            <Col md={6} xs={24} className="activity-panel-img-cc">
              <img
                src={
                  require("../../assets/images/activity-panel-3.png").default
                }
                alt=""
              />
              <p>
                <span>3. Check Your Budget</span> with In-Built Trip Budget
                Calculator in Multiple Currencies
              </p>
            </Col>
            <Col md={6} xs={24} className="activity-panel-img-cc">
              <img
                src={
                  require("../../assets/images/activity-panel-4.png").default
                }
                alt=""
              />
              <p>
                <span>4. Finish Your Itinerary</span> within short time
              </p>
            </Col>
          </Row>
        </div>
      </section>

      {Object.keys(cityActivitiesData.cityData).length > 0
        ? Object.keys(cityActivitiesData.cityData).map((countryName, idx) => (
            <div key={idx}>
              <div className="promo providerapi">
                <Layout className="holidays-search-apps activities-home-page">
                  <div className="">
                    <Content className="admin-container cms-pages-width text-center">
                      <h4 className="popular-clr-name">
                        Popular Destination In {countryName}
                      </h4>
                      <Row gutter={[12, 12]}>
                        {cityActivitiesData.cityData[countryName].map(
                          (item, i) => (
                            <Col md={6} xs={24} key={i}>
                              <Link to={getActivitiesUrl(item.SearchURL)}>
                                <img
                                  className="cart-shopping-img-1"
                                  src={
                                    item.HotelImage1
                                      ? `${BASE}${item.HotelImage1.substring(
                                          1
                                        )}`
                                      : require("../../assets/images/hotels/hotel-fil-2.jpg")
                                          .default
                                  }
                                  alt={item.CityName}
                                />
                              </Link>
                              <p className="text-center font-weight-bold">
                                {item.HotelName}
                              </p>
                            </Col>
                          )
                        )}
                      </Row>
                    </Content>
                  </div>
                </Layout>
              </div>
            </div>
          ))
        : null}

      <div className="promo providerapi buildpack-slider">
        <Layout className="holidays-search-apps activities-home-page">
          <Content className="admin-container cms-pages-width text-center">
            <h4 className="popular-clr-name">Top Packages</h4>
            <Slider {...MobileSlidersettings}>
              {cityActivitiesData.sliderData.map((item) => (
                <div key={item.CityHotelID}>
                  <Link to={getActivitiesUrl(item.SearchURL)}>
                    <img
                      className="cart-shopping-img-1"
                      src={
                        item.HotelImage1
                          ? `${BASE}${item.HotelImage1.substring(1)}`
                          : require("../../assets/images/hotels/hotel-fil-2.jpg")
                              .default
                      }
                      alt={item.CityName}
                    />
                  </Link>
                  <p className="text-center font-weight-bold">
                    {item.HotelName}
                  </p>
                </div>
              ))}
            </Slider>
          </Content>
        </Layout>
      </div>
      <section className="top-route-bb">
        <TopCities />
      </section>
      <section className="top-route-bb">
        <Reviews serviceType={5} />
      </section>
    </div>
  );
};
export default BuildPackage;

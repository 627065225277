import {
  DownloadOutlined,
  FileExcelOutlined,
  MailOutlined,
  MessageOutlined,
  PrinterOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Collapse,
} from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useContext, useState, useEffect, useReducer } from "react";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { getPassengerData } from "../../helpers/PassegerData";
import ApiClient from "../../helpers/ApiClient";

// import { downloadTicketPdf } from "../../helpers/TicketUtils";

import ActivitiesDoc from "../PdfDocuments/Activities/ActivitiesDoc";
import BuildPackageDoc from "../PdfDocuments/BuildPackage/BuildPackageDoc";

// import Invoice from "./Model/UserInvoice";
import FlightDoc from "../PdfDocuments/Flight/FlightDoc";
import BusDoc from "../PdfDocuments/Bus/BusDoc";
import HotelDoc from "../PdfDocuments/Hotel/HotelDoc";
import BusInvoiceDoc from "../PdfDocuments/Bus/BusInvoice";
import FlightInvoiceDoc from "../PdfDocuments/Flight/FlightInvoice";
import HotelInvoiceDoc from "../PdfDocuments/Hotel/HotelInvoice";
import ActivitiesInvoiceDoc from "../PdfDocuments/Activities/ActivitiesInvoice";
import BuildPackageInvoiceDoc from "../PdfDocuments/BuildPackage/BuildPackageInvoice";
// import PaymentGateway from "../../helpers/PaymentGateway";
// import { useSytContext } from "../../common/providers/SytProvider";
import ActivityTicketSidebar from "./ActivityTicketSidebar";
import PayGateway from "../../helpers/PayGateway";
import PayMentSelection from "../PaymentSelection/PaymentSelection";
import { GlobalStatesContext } from "../providers";

const TicketSidebar = ({
  ticketData,
  type,
  onCancelTicket,
  getTicketDetails = () => { },
  cmsFareRules = {},
  fareRulesResp = null,
  logoImage,
  toggleVoucheredModal = () => { },
  VoucheredConfirm = () => { },
  VoucheredModalVisible,
  voucherData,
  IspartPayement,
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: {
      otherData: { ConvFee, promoData, redeemAmount, transactionFee }
    }
  } = useContext(GlobalStatesContext);
  const [smsForm] = Form.useForm();
  const [pstMrkForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const { Panel } = Collapse;
  const [userInvoiceVisible, setUserinvoiceVisible] = useState(false);
  const [limitCount, setLimitCount] = useState({
    smsCount: 0,
    emailCount: 0,
  });
  const [pgData, setPgData] = useState({
    data: {},
    visible: false,
  });
  const [invoiceData, setInvoiceData] = useState({});
  const [withFare, setWithFare] = useState(-1);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [smsModalVisible, setSmsModalVisible] = useState(false);
  const [pstMrkModalVisible, setPstMrkModalVisible] = useState(false);
  const [IsPaymentGatway, setIsPaymentGatway] = useState(false);
  const PGDISPLAY = "PGDISPLAY";
  const PGDATA = "PGDATA";
  const PGTYPE = "PGTYPE";
  const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };
  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case PGTYPE:
        return { ...state, pgType: action.payload };
      case PGDISPLAY:
        return { ...state, pgDisplay: action.payload };
      case PGDATA:
        return { ...state, pgData: action.payload };
      default:
        return state;
    }
  }

  const toggleEmailModal = () => {
    emailForm.resetFields();
    if (!emailModalVisible) loadpassengerData("Email");
    setEmailModalVisible((prev) => !prev);
  };

  const toggleSmsModal = () => {
    smsForm.resetFields();
    if (!smsModalVisible) loadpassengerData("SMS");
    setSmsModalVisible((prev) => !prev);
  };

  const togglePstMrkModal = () => {
    pstMrkForm.resetFields();
    setPstMrkModalVisible((prev) => !prev);
  };

  // Function Add By Karthik 

  // Code End by karthik

  const loadpassengerData = (type) => {
    if (user && user?.UserID) {
      getPassengerData(user?.UserID).then((data) => {
        if (data.status) {
          if (type === "SMS") smsForm.setFieldsValue({ Mobile: data.Mobile });
          // else emailForm.setFieldsValue({ Email: data.Email });
        }
      });
    }
  };

  const addPostMarkup = (req) => {
    ApiClient.post("admin/postmarkup", req).then((res) => {
      if (res.status === 200) {
        if (res?.message) message.success(res?.message, 3);
        getTicketDetails();
        setPstMrkModalVisible(false);
      } else if (res.status === 400) {
        if (res?.message) message.success(res?.message, 3);
      } else {
        if (res?.message) message.success(res?.message, 3);
        else message.error("Failed");
      }
    });
  };

  useEffect(() => {
    getInvoiceData();
  }, []);

  const getInvoiceData = () => {
    let formData = {};
    if (type === "Flight") {
      formData.serviceType = 1;
      formData.refNo = ticketData.referenceNumber;
    }

    if (type === "Hotel") {
      formData.serviceType = 2;
      formData.refNo = ticketData.RefNumber;
    }
    if (type === "Bus") {
      formData.serviceType = 3;
      formData.refNo = ticketData.bookingRefNo;
    }

    if (type === "Activities") {
      formData.serviceType = 4;
      formData.refNo = ticketData?.TourDetails?.[0].referenceNumber;
    }
    if (type === "BuildPackage") {
      formData.serviceType = 5;
      formData.refNo = ticketData.bookingRefNo;
    }
    if (formData) {
      ApiClient.post("admin/invoice", formData)
        .then((res) => {
          if (res.status == 200) {
            setInvoiceData(res.data);
          } else {
            setInvoiceData({});
          }
        })
        .catch((error) => {
          setInvoiceData({});
        });
    }
  };

  const submitPostMarkup = (val) => {
    if (user) {
      let formData = {
        amount: val.amount,
        userId: user?.UserID,
      };

      if (type === "Flight") {
        formData.serviceType = 1;
        formData.refNumber = ticketData.referenceNumber;
      }

      if (type === "Hotel") {
        formData.serviceType = 2;
        formData.refNumber = ticketData.RefNumber;
      }
      if (type === "Bus") {
        formData.serviceType = 3;
        formData.refNumber = ticketData.bookingRefNo;
      }

      if (type === "Activities") {
        formData.serviceType = 4;
        formData.refNumber = ticketData?.TourDetails?.[0].referenceNumber;
      }
      if (type === "BuildPackage") {
        formData.serviceType = 5;
        formData.refNumber = ticketData.RefNumber;
      }
      addPostMarkup(formData);
    }
  };
  const sendEmailSmsETicket = (val) => {
    ApiClient.post("CommonUtility/notification", val).then((res) => {
      if (res.status === 200) {
        message.success(
          `${val.trigger === 2 ? "Sms" : "Email"} Sent Successfully`
        );
        setEmailModalVisible(false);
        setSmsModalVisible(false);
      } else {
        if (res?.message) message.error(res?.message, 3);
        else message.error("Failed");
      }
    });
  };

  const submitEmailForm = (val) => {
    if (limitCount.emailCount > 2) {
      message.error("Limit Exceeded");
      setEmailModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({
        ...prev,
        emailCount: limitCount.emailCount + 1,
      }));
    }
    if (type === "Bus") {
      let formData = {
        phoneNumber: "string",
        travelType: 3,
        trigger: 1,
        bookingReference: ticketData.bookingRefNo,
        email: val.Email,
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "Flight") {
      let formData = {
        phoneNumber: "string",
        travelType: 1,
        trigger: 1,
        bookingReference: ticketData.referenceNumber,
        email: val.Email,
        withFare: withFare,
      };

      sendEmailSmsETicket(formData);
    }
    if (type === "Hotel") {
      let formData = {
        phoneNumber: "string",
        travelType: 2,
        trigger: 1,
        bookingReference: ticketData.RefNumber,
        email: val.Email,
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }

    if (type === "Activities") {
      let formData = {
        phoneNumber: "string",
        travelType: 4,
        trigger: 1,
        bookingReference: ticketData?.TourDetails?.[0].referenceNumber,
        email: val.Email,
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "BuildPackage") {
      let formData = {
        phoneNumber: "string",
        travelType: 5,
        trigger: 1,
        bookingReference: ticketData.bookingRefNo,
        email: val.Email,
        withFare: withFare,
        builpackageQuotation: 0,
      };
      sendEmailSmsETicket(formData);
    }
  };

  const sendSms = (val) => {
    if (limitCount.smsCount > 2) {
      message.error("Limit Exceeded");
      setSmsModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({ ...prev, smsCount: limitCount.smsCount + 1 }));
    }
    if (type === "Bus") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 3,
        trigger: 2,
        bookingReference: ticketData.bookingRefNo,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "Flight") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 1,
        trigger: 2,
        bookingReference: ticketData.id,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "Hotel") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 2,
        trigger: 2,
        bookingReference: ticketData.RefNumber,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
  };

  const handleCancel = (cancelTicketType) => {
    onCancelTicket(cancelTicketType);
  };
  const printTicket = () => {
    window.print();
  };

  const confirmHotelBooking = () => {
    if (type === "Hotel") {
      setPgData({
        // data,
        visible: true,
      });
    }
  };

  const confirmFlightBooking = () => {

    setPgData({
      // data,
      visible: true,
    });

  };

  const blockApiReqFlightBooking = (pgType) => {
    let tr = ticketData
    const AirTicketRQ = {
      pnr: ticketData.pnr,
      traceId: ticketData.traceId,
      userId: ticketData['userId'],
      referenceNumber: ticketData.referenceNumber,
      pgType: pgType
    }

    ApiClient.post("flights/airTicketConfirm", AirTicketRQ)
      .then((res) => {
        if (res.status === 200) {
          window.location.href = res.data.payment_link;
        } else {
          message.error("Payment Failed", 3);
        }
      })
      .catch((error) => { });
  }

  const blockApiReq = (pgType) => {
    if (type === "Hotel" && pgType) {
      let data = {
        traceId: ticketData.traceId,
        referenceNo: ticketData.RefNumber,
        pgType: pgType,
        totalprice: Number(ticketData.totalPrice),
      };

      ApiClient.post("hotels-v2/payandconfirmhotelblock", data)
        .then((res) => {
          if (res.status === 200) {
            window.location.href = res.data.payment_link;
          } else {
            message.error("Payment Failed", 3);
          }
        })
        .catch((error) => { });
    }else if(type === "BuildPackage" && pgType){
      dispatchPgDetails({ type: PGTYPE, payload: pgType });
      if(pgType && agent){
        partPayment(pgType);
      }
    }
  };

  const OnPaymentModeSelection = (e) => {
    if (e.target.value === 1) {
      setIsPaymentGatway(true);
    } else {
      setIsPaymentGatway(false);
    }
  }

  const partPayment = (pgType) => {
    let reqData = {
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      currency: "AED",
      packageRefNo: ticketData.bookingRefNo,
      activitiesPackageDetails: [],
      hotelsPackageDetails: [],
      partPayment: {
        isPartPayment: IspartPayement ? true : false,
        serviceTotal: 0,
        partPaymentAmount: 0,
        dueAmount: ticketData.partPayment.partPaymentAmount,
        serviceType: 4,
      },
      pgType: pgType ? pgType : pgDetails.pgType,
      paymentMethod: transactionFee?.methodType,
      promoCode: promoData.Code ?? "",
      convienenceId: ConvFee.id ?? 0,
      isCouponReedem: redeemAmount.status ?? false,
    };
    
    ApiClient.post("buildPackage/packageBlock", reqData).then((response) => {
      if(response.status == 200){
        message.success("PartPayment received successfully");
      }else {
        message.error("PartPayment rejected with status " + response.status);
      }
    });
  };

  return (
    <>
      {type === "Activities" && <ActivityTicketSidebar getInvoiceData={getInvoiceData} printTicket={printTicket}
        toggleEmailModal={toggleEmailModal} handleCancel={handleCancel}
        submitEmailForm={submitEmailForm} emailModalVisible={emailModalVisible}
        togglePstMrkModal={togglePstMrkModal} submitPostMarkup={submitPostMarkup}
        invoiceData={invoiceData}
        pstMrkModalVisible={pstMrkModalVisible}
        setWithFare={setWithFare}
        ticketData={ticketData}
        type={type}
        logoImage={logoImage}
        toggleVoucheredModal={toggleVoucheredModal}
        voucherData={voucherData}
        VoucheredModalVisible={VoucheredModalVisible}
        VoucheredConfirm={VoucheredConfirm}
      />}
      {type !== "Activities" &&
        <div className="actionable-buttons">


          <div className="mb-3">
            <Collapse
              accordion
              expandIcon={() => <DownloadOutlined />}
              expandIconPosition="left"
            >
              <Panel
                header={agent ? "Download E-Ticket/Invoice" : "Download E-Ticket"}
                key="1"
              >
                <div className="trip-type-selector">
                  <div className="trip-type-selector">
                    {type === "Activities" ? (
                      <PDFDownloadLink
                        document={
                          <ActivitiesDoc
                            ticketData={ticketData}
                            withFare={true}
                            agent={agent}
                            Logo={logoImage}
                            voucherData={voucherData}
                          />
                        }
                        fileName="activitiesTicket.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button className="mb-1">
                              {agent ? "E-Ticket (With Fare)" : "With Fare"}
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                    {type === "Activities" ? (
                      <PDFDownloadLink
                        document={
                          <ActivitiesDoc
                            ticketData={ticketData}
                            withFare={false}
                            agent={agent}
                            Logo={logoImage}
                            voucherData={voucherData}
                          />
                        }
                        fileName="activitiesTicket.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button>
                              {" "}
                              {agent ? "E-Ticket (Without Fare)" : "Without Fare"}
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                    {type === "Flight" ? (
                      <PDFDownloadLink
                        document={
                          <FlightDoc
                            ticketData={ticketData}
                            fareRulesResp={fareRulesResp}
                            cmsFareRules={cmsFareRules}
                            withFare={true}
                            Logo={logoImage}

                          />
                        }
                        fileName="flightTicket.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button className="mb-1">
                              {agent ? "E-Ticket (With Fare)" : "With Fare"}
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                    {type === "Flight" ? (
                      <PDFDownloadLink
                        document={
                          <FlightDoc
                            ticketData={ticketData}
                            fareRulesResp={fareRulesResp}
                            cmsFareRules={cmsFareRules}
                            withFare={false}
                            Logo={logoImage}
                          />
                        }
                        fileName="flightTicket.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button>
                              {" "}
                              {agent ? "E-Ticket (Without Fare)" : "Without Fare"}
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                    {type === "Bus" ? (
                      <PDFDownloadLink
                        document={
                          <BusDoc
                            ticketData={ticketData}
                            withFare={true}
                            Logo={logoImage}
                          />
                        }
                        fileName="busTicket.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button className="mb-1">
                              {agent ? "E-Ticket (With Fare)" : "With Fare"}
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                    {type === "Bus" ? (
                      <PDFDownloadLink
                        document={
                          <BusDoc
                            ticketData={ticketData}
                            withFare={false}
                            Logo={logoImage}
                          />
                        }
                        fileName="busTicket.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button>
                              {" "}
                              {agent ? "E-Ticket (Without Fare)" : "Without Fare"}
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                    {type === "Hotel" ? (
                      <PDFDownloadLink
                        document={
                          <HotelDoc
                            ticketData={ticketData}
                            cmsFareRules={cmsFareRules}
                            withFare={true}
                            Logo={logoImage}
                          />
                        }
                        fileName="hotelTicket.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button className="mb-1">
                              {agent ? "E-Ticket (With Fare)" : "With Fare"}
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                    {type === "Hotel" ? (
                      <PDFDownloadLink
                        document={
                          <HotelDoc
                            ticketData={ticketData}
                            cmsFareRules={cmsFareRules}
                            withFare={false}
                            Logo={logoImage}
                          />
                        }
                        fileName="hotelTicket.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button>
                              {" "}
                              {agent ? "E-Ticket (Without Fare)" : "Without Fare"}
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                    {type === "BuildPackage" ? (
                      <PDFDownloadLink
                        document={
                          <BuildPackageDoc
                            ticketData={ticketData}
                            withFare={true}
                            agent={agent}
                            Logo={logoImage}
                          />
                        }
                        fileName="PackageTicket.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button className="mb-1">
                              {agent ? "E-Ticket (With Fare)" : "With Fare"}
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                    {type === "BuildPackage" ? (
                      <PDFDownloadLink
                        document={
                          <BuildPackageDoc
                            ticketData={ticketData}
                            withFare={false}
                            agent={agent}
                            Logo={logoImage}
                          />
                        }
                        fileName="PackageTicket.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button>
                              {" "}
                              {agent ? "E-Ticket (Without Fare)" : "Without Fare"}
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}

                    {[5, 7].includes(invoiceData.BookingStatus) && agent ? (
                      type === "Bus" ? (
                        <PDFDownloadLink
                          document={
                            <BusInvoiceDoc
                              invoiceData={invoiceData}
                              logo={logoImage}
                              type={"credit_note"}
                            />
                          }
                          fileName="Bus_Credit_Note.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              <Button>Loading document... </Button>
                            ) : (
                              <Button className="invoice-btn">Credit Note</Button>
                            )
                          }
                        </PDFDownloadLink>
                      ) : null
                    ) : null}

                    {invoiceData.BookingStatus === 3 && agent ? (
                      type === "Bus" ? (
                        <PDFDownloadLink
                          document={
                            <BusInvoiceDoc
                              logo={logoImage}
                              invoiceData={invoiceData}
                              type={"invoice"}
                            />
                          }
                          fileName="Bus_Invoice.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              <Button>Loading document... </Button>
                            ) : (
                              <Button className="invoice-btn">Invoice</Button>
                            )
                          }
                        </PDFDownloadLink>
                      ) : null
                    ) : null}
                    {invoiceData.bookingStatus === 3 && agent ? (
                      type === "Flight" ? (
                        <PDFDownloadLink
                          document={
                            <FlightInvoiceDoc
                              invoiceData={invoiceData}
                              logo={logoImage}
                              type={"invoice"}
                            />
                          }
                          fileName="Flight_Invoice.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              <Button>Loading document... </Button>
                            ) : (
                              <Button className="invoice-btn">Invoice</Button>
                            )
                          }
                        </PDFDownloadLink>
                      ) : null
                    ) : null}
                    {[4, 5, 6].includes(invoiceData.bookingStatus) && agent ? (
                      type === "Flight" ? (
                        <PDFDownloadLink
                          document={
                            <FlightInvoiceDoc
                              invoiceData={invoiceData}
                              logo={logoImage}
                              type={"credit_note"}
                            />
                          }
                          fileName="Flight_Credit_Note.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              <Button>Loading document... </Button>
                            ) : (
                              <Button className="invoice-btn">Credit Note</Button>
                            )
                          }
                        </PDFDownloadLink>
                      ) : null
                    ) : null}
                    {invoiceData.BookingStatus === 2 && agent ? (
                      type === "Hotel" ? (
                        <PDFDownloadLink
                          document={
                            <HotelInvoiceDoc
                              invoiceData={invoiceData}
                              logo={logoImage}
                              type={"invoice"}
                            />
                          }
                          fileName="Hotel_Invoice.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              <Button>Loading document... </Button>
                            ) : (
                              <Button className="invoice-btn">Invoice</Button>
                            )
                          }
                        </PDFDownloadLink>
                      ) : null
                    ) : null}
                    {[3, 9].includes(invoiceData.BookingStatus) && agent ? (
                      type === "Hotel" ? (
                        <PDFDownloadLink
                          document={
                            <HotelInvoiceDoc
                              invoiceData={invoiceData}
                              logo={logoImage}
                              type={"credit_note"}
                            />
                          }
                          fileName="Hotel_Invoice.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              <Button>Loading document... </Button>
                            ) : (
                              <Button className="invoice-btn">Credit Note</Button>
                            )
                          }
                        </PDFDownloadLink>
                      ) : null
                    ) : null}

                    {invoiceData?.TourDetails?.length > 0 ? (
                      invoiceData.TourDetails[0].bookingStatus === 3 && agent ? (
                        type === "Activities" ? (
                          <PDFDownloadLink
                            document={
                              <ActivitiesInvoiceDoc
                                invoiceData={invoiceData}
                                logo={logoImage}
                                type={"invoice"}
                              />
                            }
                            fileName="Activities_Invoice.pdf"
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? (
                                <Button>Loading document... </Button>
                              ) : (
                                <Button className="invoice-btn">Invoice</Button>
                              )
                            }
                          </PDFDownloadLink>
                        ) : null
                      ) : null
                    ) : null}
                    {invoiceData?.TourDetails?.length > 0 ? (
                      invoiceData.TourDetails[0].bookingStatus === 0 && agent ? (
                        type === "Activities" ? (
                          <PDFDownloadLink
                            document={
                              <ActivitiesInvoiceDoc
                                invoiceData={invoiceData}
                                logo={logoImage}
                                type={"credit_note"}
                              />
                            }
                            fileName="Activities_Invoice.pdf"
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? (
                                <Button>Loading document... </Button>
                              ) : (
                                <Button className="invoice-btn">Credit Note</Button>
                              )
                            }
                          </PDFDownloadLink>
                        ) : null
                      ) : null
                    ) : null}
                    {type === "BuildPackage" && agent ? (
                      <PDFDownloadLink
                        document={
                          <BuildPackageInvoiceDoc
                            invoiceData={invoiceData}
                            logo={logoImage}
                          />
                        }
                        fileName="Package_Invoice.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button className="invoice-btn">Invoice</Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>

          <div className="ticket-options">
            <p onClick={() => printTicket()}>
              <PrinterOutlined /> Print E-Ticket
            </p>
          </div>

          <div className="mb-3">
            <Collapse accordion expandIcon={() => <MailOutlined />}>
              <Panel header={"Email Ticket"} key="1">
                <div className="trip-type-selector">
                  <div className="trip-type-selector">
                    <Button
                      block
                      style={{ marginBottom: 10 }}
                      onClick={() => {
                        toggleEmailModal();

                        setWithFare(1);
                      }}
                    >
                      With Price
                    </Button>

                    <Button
                      block
                      onClick={() => {
                        toggleEmailModal();
                        setWithFare(0);
                      }}
                    >
                      Without Price
                    </Button>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>

          {ticketData.cancellable && (
            <div className="ticket-options">
              <p onClick={() => toggleSmsModal()}>
                <MessageOutlined /> Sms E-Ticket
              </p>
            </div>
          )}

          {type === "Bus"
            ? ticketData.cancellable && (
              <div className="ticket-options">
                <p onClick={() => handleCancel(type)}>
                  <FileExcelOutlined /> Cancel E-Ticket
                </p>
              </div>
            )
            : null}

          {type === "Flight"
            ? ticketData.BookingStatus !== "CANCELLED" &&
            ticketData.cancellable && (
              <div className="ticket-options">
                <p onClick={() => handleCancel("Flight")}>
                  <FileExcelOutlined /> Cancel / Reschedule E-Ticket
                </p>
              </div>
            )
            : null}

          {type === "Hotel"
            ? ticketData.BookingStatus != 3 &&
            ticketData.cancellable && (
              <div className="ticket-options">
                <p onClick={() => handleCancel(type)}>
                  <FileExcelOutlined /> Cancel E-Ticket
                </p>
              </div>
            )
            : null}
          {/* {type === "Activities"
        ? ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}

      {type === "BuildPackage"
        ? ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null} */}
          {/* Add Code By Karthik 28/April/2023 */}
          {/* {type === "Activities" && voucherData === false ? 
        <>
          <div className="ticket-options">
            <p onClick={() => toggleVoucheredModal()}>
              <DollarOutlined /> Vouchered
            </p>
          </div>
        </>
        : null} */}
          {/* Add Code By Karthik 28/April/2023 */}

          {agent ? (
            <>
              {/* {ticketData.cancellable ? ( 
  post markup logic visible for all conditon , discussed with  Nitin13-07-2023 */}
              <div className="ticket-options">
                <p onClick={() => togglePstMrkModal()}>
                  <DollarOutlined /> Post Markup
                </p>
              </div>

              {type === "Flight" || type == "BuildPackage" && IsPaymentGatway && (
                <div className="mb-3">
                  <PayMentSelection />
                </div>
              )}

              {type === "BuildPackage" && IspartPayement == true && (
                <div className="ticket-options">
                  <PayGateway blockApiReq={blockApiReq} OnPaymentModeSelection={OnPaymentModeSelection} />
                </div>
              )}

              {type === "Flight" && (ticketData?.oneWaySegment[0]?.bookingStatus == 10 || ticketData?.returnSegment[0]?.bookingStatus == 10) &&
                ticketData?.PurchaseType === "Block" ? (
                <div className="ticket-options">
                  {pgData.visible ? (
                    // <PaymentGateway data={pgData.data} />
                    <PayGateway blockApiReq={blockApiReqFlightBooking} OnPaymentModeSelection={OnPaymentModeSelection} />
                  ) : (
                    <p onClick={() => confirmFlightBooking()}>
                      <DollarOutlined /> Confirm Booking
                    </p>
                  )}
                </div>
              ) : null}
              {/* {type === "Flight" &&  ticketData?.BookingStatus === "HOLD" &&
        
        ticketData?.PurchaseType === "Block" ? (
          <div className="ticket-options">
            {pgData.visible ? (
              // <PaymentGateway data={pgData.data} />
              <PayGateway blockApiReq={blockApiReq} />
            ) : (
              <p onClick={() => confireFlightBooking()}>
                <DollarOutlined /> Release Ticket
              </p>
            )}
          </div>
        ) : null} */}
              {type === "Hotel" &&
                ticketData?.BookingStatus === 6 &&
                ticketData?.PurchaseType === "Block" ? (
                <div className="ticket-options">
                  {pgData.visible ? (
                    // <PaymentGateway data={pgData.data} />
                    <PayGateway blockApiReq={blockApiReq} />
                  ) : (
                    <p onClick={() => confirmHotelBooking()}>
                      <DollarOutlined /> Confirm Booking
                    </p>
                  )}
                </div>
              ) : null}

              <Modal
                title={[
                  <div>
                    <h6 style={{ marginBottom: "0px" }}>
                      <strong>Post Markup</strong>
                    </h6>
                  </div>,
                ]}
                width={250}
                className="promo-modal-header"
                visible={pstMrkModalVisible}
                onOk={togglePstMrkModal}
                onCancel={togglePstMrkModal}
                footer={[
                  <div>
                    <Button key="close" onClick={togglePstMrkModal}>
                      Cancel
                    </Button>

                    <Button
                      key="add"
                      type="primary"
                      htmlType="submit"
                      onClick={pstMrkForm.submit}
                    >
                      Add
                    </Button>
                  </div>,
                ]}
              >
                <Form
                  form={pstMrkForm}
                  layout="vertical"
                  onFinish={submitPostMarkup}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Amount"
                        name="amount"
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },

                          {
                            validator: (_, value) => {
                              if (Number(value) > 0) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject("Please Enter valid Amount");
                              }
                            },
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} type="number" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </>
          ) : null}

          <Modal
            title="User Invoice"
            visible={userInvoiceVisible}
            onOk={() => setUserinvoiceVisible(false)}
            onCancel={() => setUserinvoiceVisible(false)}
            width={"75%"}
          >
            {/* <Invoice /> */}
          </Modal>

          <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>Enter The Email Address</strong>
                </h6>
              </div>,
            ]}
            className="promo-modal-header"
            visible={emailModalVisible}
            onOk={toggleEmailModal}
            onCancel={toggleEmailModal}
            footer={[
              <div>
                <Button key="close" onClick={toggleEmailModal}>
                  Cancel
                </Button>

                <Button
                  key="add"
                  type="primary"
                  htmlType="submit"
                  onClick={emailForm.submit}
                >
                  Send
                </Button>
              </div>,
            ]}
          >
            <Form form={emailForm} layout="vertical" onFinish={submitEmailForm}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[
                      { required: true, message: "Required!" },
                      { type: "email", message: "Email is not a valid email" },
                    ]}
                  >
                    <Input placeholder="Enter The Email Address" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>

          <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>SMS E-Ticket</strong>
                </h6>
              </div>,
            ]}
            className="promo-modal-header"
            visible={smsModalVisible}
            onOk={toggleSmsModal}
            onCancel={toggleSmsModal}
            footer={[
              <div>
                <Button key="close" onClick={toggleSmsModal}>
                  Cancel
                </Button>

                <Button
                  key="add"
                  type="primary"
                  htmlType="submit"
                  onClick={smsForm.submit}
                >
                  Submit
                </Button>
              </div>,
            ]}
          >
            <Form form={smsForm} layout="vertical" onFinish={sendSms}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Mobile No."
                    name="Mobile"
                    autoFocus
                    rules={[
                      {
                        required: true,
                        message: "Mobile Number Required",
                      },
                      {
                        minLength: 10,
                        maxLength: 10,
                        pattern: "^[0-9]{10}$",
                        message: "Must be 10 digits",
                      },
                    ]}
                  >
                    <Input
                      className="number-specing"
                      placeholder="Enter Mobile number"
                      autoComplete="off"
                      autoFocus
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>


        </div>}</>
  );
};
export default TicketSidebar;

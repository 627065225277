import React, { useState } from "react";
import { Col, Row, message, Modal, Collapse,  Select } from "antd";
import { useAuthContext } from "../../providers/AuthProvider";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { getAllActivitiesPrice } from "../../../pages/Activitespkg/Activitiescheckout/activitiesHelper";
import "../ticketActivities.scss";
import QRCode from "qrcode.react";
import ApiClient from "../../../helpers/ApiClient";
import Table from "react-bootstrap/Table";
import { useCurrencyContext } from "../../providers/CurrencyProvider";
const { Panel } = Collapse;
const { Option } = Select;

const TicketActivites = ({ ticketData, cmsFareRules, voucherData }) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  const { totalAmount, discount } = getAllActivitiesPrice(
    ticketData.TourDetails,
    ticketData.convienenceData,
    ticketData.promoData
  );
  const [CancellationPolicyModal, setCancellationPolicyModal] = useState(false);
  const [CancellationPolicy, setCancellationPolicy] = useState([]);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const getStatus = (status) => {
    if (voucherData == true) {
      status = "VOUCHERED"
    }
    switch (status) {
      case "BLOCKED": {
        return <span style={{ color: "#FFA500" }}>BLOCKED </span>;
      }

      case "SUCCESS": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status} </b>
          </span>
        );
      }

      case "CONFIRMED": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status} </b>
          </span>
        );

      }

      case "ALREADYCANCELLED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> ALREADY CANCELLED </b>
          </span>
        );
      }
      case "CANCELLED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status} </b>
          </span>
        );
      }

      case "VOUCHERED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status} </b>
          </span>
        );
      }
      case "NOT-AVAILABLE": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status} </b>
          </span>
        );
      }

      default:
        return;
    }
  };

  const getPaxCount = (pax) => {
    return `Adult(s) ${pax?.adults !== "0" ? pax?.adults : 0} ${pax?.childCount !== "0" ? ", Child(s) " + pax?.childCount : " "
      } ${pax?.infantCount !== "0" ? ", Infant(s) " + pax?.infantCount : " "}`;
  };

  const getTotalAmount = () => {
    let postMarkup = ticketData.totalPostMarkup
      ? ticketData.totalPostMarkup
      : 0;
    let totalAmount =
      Number(ticketData.PartPayment.serviceTotal) + Number(postMarkup);

    return Number(totalAmount).toFixed(2);
  };

  const getTicketPolicy = (tour) => {
    let { tourId, journeyDate, startTime, serviceUniqueId, optionId } = tour;
    //const currentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
    // message.success("Successfully")
    setCancellationPolicyModal(true)
    setCancellationPolicy(tour?.ticketPolicy)
  }

  const toggleCancelHandler = () => {
    setCancellationPolicyModal((prev) => !prev);
  };

  return (
    <div className="activities-ticket">
      <div className="activities-details">
        <ul className="tour-timeline tour-timeline-left">
          <div className="tour-details-panel">
            <h6 className="pax-title">Passanger Details</h6>
            <div className="passenger-details">
              <Row gutter={[8, 8]}>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Name:{" "}
                    <span>
                      {ticketData?.Passengers.firstName}{" "}
                      {ticketData?.Passengers.lastName}
                    </span>
                  </p>
                </Col>

                <Col md={12} sm={12} xs={24}>
                  <p>
                    Email: <span>{ticketData?.Passengers.email}</span>
                  </p>
                </Col>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Contact: <span>{ticketData?.Passengers.mobile}</span>
                  </p>
                </Col>
              </Row>
            </div>
            <h6 className="pax-title">Tour Details</h6>
            <div className="passenger-details">
              <Row gutter={[8, 8]}>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Reference Number:
                    <span> {ticketData?.TourDetails?.[0].referenceNumber}</span>
                  </p>
                </Col>
                {!agent && (
                  <Col md={12} sm={12} xs={24}>
                    <p>
                      Provider Ref No:
                      <span> {ticketData?.TourDetails?.[0].ProviderRefNo}</span>
                    </p>
                  </Col>
                )}

                {/*Starting Block  (Confirmation No Hide On Condition Base Changes by Karthik --12/April/2023) */}
                {ticketData?.TourDetails?.[0].confirmationNo != '' ?
                  <Col md={12} sm={12} xs={24}>
                    <p>
                      Confirmation No:
                      <span> {ticketData?.TourDetails?.[0].confirmationNo}</span>
                    </p>
                  </Col>
                  : null}
                {/*Ending Block  (Confirmation No Hide On Condition Base Changes by Karthik --12/April/2023) */}

                <Col md={12} sm={12} xs={24}>
                  <p>
                    Payment Mode:
                    <span>
                      {ticketData.PartPayment.isPartPayment === true
                        ? " Part"
                        : " Full"}{" "}
                      Payment
                    </span>
                  </p>
                </Col>

                {ticketData?.convienenceData?.amount > 0 && (
                  <Col md={12} sm={12} xs={24}>
                    {/*Starting Block  (Convienence Fee Hide On Condition Base Changes by Karthik --12/April/2023) */}
                    {/* {Number(ticketData.convienenceData.amount) > 0 && ( */}
                    <p>
                      Convienence Fee:
                      <span>
                        {" "}
                        INR{" "}
                        {Number(
                          ticketData?.convienenceData?.amount ?? 0
                        ).toFixed(2)}
                      </span>
                    </p>
                    {/* )} */}
                    {/*Ending Block  (Convienence Fee Hide On Condition Base Changes by Karthik --12/April/2023) */}
                  </Col>
                )}

                <Col md={12} sm={12} xs={24}>
                  <p>
                    Total Amount:
                    <span> {activeCurrency} {currencyValue(totalAmount)}</span>
                  </p>
                </Col>
                {/*Starting Block  (Convienence Fee Hide On Condition Base Changes by Karthik --12/April/2023) */}
                {ticketData?.promoData && (
                  <>
                    {ticketData.promoData.Discount > 0 &&
                      <Col md={12} sm={12} xs={24}>
                        <p>
                          Discount:
                          <span>
                            {" - INR "}
                            {(isNaN(discount)) ? 0 : Number(discount).toFixed(2)}
                          </span>
                        </p>
                      </Col>
                    }
                  </>
                )}
                {/*Ending Block  (Convienence Fee Hide On Condition Base Changes by Karthik --12/April/2023) */}
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Grand Total:
                    <span> {activeCurrency} {currencyValue(getTotalAmount())}</span>
                  </p>
                </Col>

                {ticketData.PartPayment.isPartPayment === true ? (
                  <>
                    <Col md={12} sm={12} xs={24}>
                      <p>
                        Paid Amount:
                        <span>
                          {" "}
                          {activeCurrency} {currencyValue(ticketData.PartPayment.partPaymentAmount)}
                        </span>
                      </p>
                    </Col>
                    <Col md={12} sm={12} xs={24}>
                      <p className="text-danger">
                        Due Amount:
                        <span> {activeCurrency} {currencyValue(ticketData.PartPayment.dueAmount)} </span>
                      </p>
                    </Col>
                  </>
                ) : null}
              </Row>
            </div>
          </div>

          {ticketData?.TourDetails?.map((tour, index) => {
            return (<li
              key={index}
              className="tour-timeline-inverted tour-timeline-item"
            >
              <div className="tour-timeline-badge warning">
                <span className="font-12">
                  {moment(tour.journeyDate, "YYYY-MM-DD").format("DD MMM")}
                </span>
              </div>
              <div className="tour-timeline-panel">
                <h5 className="tour-timeline-title">{tour.tourName}</h5>
                <p className="subtitle">{tour.optionName}</p>
                <p className="subtitle">
                  Tour Date :{" "}
                  {moment(tour.journeyDate, "YYYY-MM-DD").format(
                    "ddd DD MMM YYYY"
                  )}
                </p>

                <div className="tour-timeline-body">
                  <Row className="justify-content-between">
                    <Col md={6} xs={24}>
                      <img
                        className="hotel-tag-1"
                        src={tour.tourImage}
                        alt="First slide"
                      />
                    </Col>

                    {tour?.ticket?.length > 0 ? null : tour?.bookingStatus == 11  ? 
                      < Col className="px-3" md={18} xs={24}>
                        <p><strong>Note :- </strong>Please wait for 30 minutes for the availability of the QR code.If any query contact on <strong>Emergency Number :- 9166868837, 9999442804, 011-49511313</strong>. Thank you for your patience.</p>
                      </Col>
                      : null
                    }

                    {tour?.bookingStatus == 3 ?
                      < Col className="px-3" md={18} xs={24}>
                        <p><strong>Note :- </strong>Please Do Vourched Your Ticket.</p>
                      </Col> : null
                    }

                    <Col md={4} xs={24}>
                      {/* {console.log(tour)} */}
                      {/* {tour.ticket.map(x=>{
                       return (x.barcode && (
                         <QRCode
                            value={x.barcode}
                            size={100}
                            title={tour.tourName}
                            id={"packageQR" + index}
                          />
                        ))
                      })} */}
                    </Col>
                  </Row>
                  <Row className="justify-content-between pr-3" gutter={[2, 2]}>
                    <Col md={24} xs={24}>
                      <p
                        className="hotel-list-para"
                        dangerouslySetInnerHTML={{
                          __html: tour.tourShortDescription,
                        }}
                      ></p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Booking Status  :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">
                        {getStatus(tour.BookingStatus)}
                      </p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Cancellation Date :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">
                        {tour.cancellationDate
                          ? moment(tour.cancellationDate, "YYYY-MM-DD").format(
                            "DD-MM-YYYY"
                          )
                          : ""}
                      </p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Transfer Option :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">{tour.transferName}</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Duration :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">{tour.duration}</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Start Time :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">
                        {/* start here code added by Karthik  --4/April/2023*/}
                        {tour ? tour.startTime : 'Time Not Available'}
                        {/* End Here Code  */}
                        {/* {tour.startTime
                          ? moment(tour.startTime).format("hh:mm")
                          : null} */}
                      </p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Pax Details :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">{getPaxCount(tour.pax)}</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Amount :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">
                        {activeCurrency} {currencyValue(Number(tour.serviceTotal).toFixed(2))}
                      </p>
                    </Col>

                    <Col md={12} xs={24}>
                      <p className="wt-activity mt-3">Policy :</p>
                    </Col>
                    <Col md={12} xs={24}>

                      <p className="wt-activity2 mt-3 btn-link" onClick={()=>getTicketPolicy(tour)} style={{cursor: "pointer", textDecoration: "none"}}>

                        View Cancellation Policy
                      </p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p style={{ color: "#FF0000" }}>{tour?.ticketPolicy[0]?.cancellationPolicy}</p>

                    </Col>
                    {tour.BookingStatus=="NOT-AVAILABLE" &&
                      <Col md={12} xs={24}>
                        <p style={{ color: "#FF0000" }}>"Tour Not Available"</p>
                      </Col>}
                  </Row>

                  {tour.bookingStatus == 11 && tour?.ticket?.map(x => {
                    return (x.barcode && (<>
                      <Row className="justify-content-between my-3">
                        <Col md={6} xs={24}>
                          <img
                            className="hotel-tag-1"
                            src={tour.tourImage}
                            alt="First slide"
                          />
                        </Col>
                        <Col md={4} xs={24}>
                          <QRCode
                            value={x.barcode}
                            size={100}
                            title={tour.tourName}
                            id={"packageQR" + index}
                          />
                        </Col>
                      </Row>
                    </>))
                  })}
                </div>
              </div>
            </li>
            )
          })}
          <li className="tour-timeline-inverted tour-timeline-item">
            {cmsFareRules?.cancelPolicyDescription ? (
              <div className="tour-timeline-panel">
                <div className="terms-panel">
                  <h5 className="terms-title">Terms and Condition</h5>
                  <div className="terms-description">
                    <p className="terms-title">Cancel Policy</p>

                    {ReactHtmlParser(cmsFareRules?.cancelPolicyDescription)}
                    {cmsFareRules?.otherRulesDescription ? (
                      <p className="title">Other Policy</p>
                    ) : null}
                    {ReactHtmlParser(cmsFareRules?.otherRulesDescription)}
                  </div>
                </div>
              </div>
            ) : null}
          </li>

          <div className="tour-details-panel">

          </div>

          <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>Tour Cancellation Policy</strong>
                </h6>
              </div>,
            ]}
            centered
            width={1000}
            visible={CancellationPolicyModal}
            onCancel={toggleCancelHandler}
            footer={[]}
          >
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="Booking Policy" key="1">
                <h5>Cancellation Policy</h5>
                <p>{CancellationPolicy[0]?.cancellationPolicy} {CancellationPolicy.length==0 && "To obtain information regarding our cancellation policy, kindly reach out to our support team"}</p>
                <pre>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        CancellationPolicy[0]?.cancellationPolicyDescription,
                    }}
                  ></div>
                </pre>
                <h5>Child Policy</h5>
                <pre>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: CancellationPolicy[0]?.childPolicyDescription,
                    }}
                  ></div>
                </pre>
              </Panel>
            </Collapse>
          </Modal>
        </ul>
      </div>
    </div >
  );
};

export default TicketActivites;

import {
  DownloadOutlined,
  FileExcelOutlined,
  MailOutlined,
  MessageOutlined,
  PrinterOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Collapse,
} from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { getPassengerData } from "../../helpers/PassegerData";
import ApiClient from "../../helpers/ApiClient";

// import { downloadTicketPdf } from "../../helpers/TicketUtils";

import ActivitiesDoc from "../PdfDocuments/Activities/ActivitiesDoc";
import BuildPackageDoc from "../PdfDocuments/BuildPackage/BuildPackageDoc";
import ActivitiesInvoiceDoc from "../PdfDocuments/Activities/ActivitiesInvoice";
import BuildPackageInvoiceDoc from "../PdfDocuments/BuildPackage/BuildPackageInvoice";
// import PaymentGateway from "../../helpers/PaymentGateway";
// import { useSytContext } from "../../common/providers/SytProvider";
import PayGateway from "../../helpers/PayGateway";
import { useCurrencyContext } from "../providers/CurrencyProvider";

const ActivityTicketSidebar = ({
  getInvoiceData = () => { },
  printTicket = () => { },
  toggleEmailModal = () => { },
  handleCancel = () => { },
  togglePstMrkModal = () => { },
  submitPostMarkup = () => { },
  submitEmailForm = () => { },
  emailModalVisible,
  invoiceData,
  pstMrkModalVisible,
  setWithFare,
  ticketData,
  type,

  // onCancelTicket,
  // getTicketDetails = () => { },
  // cmsFareRules = {},
  // fareRulesResp = null,
  logoImage,
  toggleVoucheredModal = () => { },
  VoucheredConfirm = () => { },
  VoucheredModalVisible,
  voucherData
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [smsForm] = Form.useForm();
  const [pstMrkForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const { Panel } = Collapse;
  const [userInvoiceVisible, setUserinvoiceVisible] = useState(false);
  const [limitCount, setLimitCount] = useState({
    smsCount: 0,
    emailCount: 0,
  });
  const [pgData, setPgData] = useState({
    data: {},
    visible: false,
  });
  useEffect(() => {

  }, []);

  return (
    <div className="actionable-buttons">
      {type === "Activities" && ticketData.TourDetails[0]?.bookingStatus == 11 ?
        <div className="mb-3">
          <Collapse
            accordion
            expandIcon={() => <DownloadOutlined />}
            expandIconPosition="left"
          >
            <Panel
              header={agent ? "Vouchered E-Ticket" : "Download E-Ticket"}
              key="1"
            >
              <div className="trip-type-selector">
                <div className="trip-type-selector">
                  {type === "Activities" ? (
                    <PDFDownloadLink
                      document={
                        <ActivitiesDoc
                          ticketData={ticketData}
                          withFare={true}
                          agent={agent}
                          Logo={logoImage}
                          voucherData={voucherData}
                          activeCurrency={activeCurrency}
                          currencyValue={currencyValue}
                        />
                      }
                      fileName="activitiesTicket.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="mb-1">
                            {agent ? "E-Ticket (With Fare)" : "With Fare"}
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}
                  {type === "Activities" ? (
                    <PDFDownloadLink
                      document={
                        <ActivitiesDoc
                          ticketData={ticketData}
                          withFare={false}
                          agent={agent}
                          Logo={logoImage}
                          voucherData={voucherData}
                        />
                      }
                      fileName="activitiesTicket.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button>
                            {" "}
                            {agent ? "E-Ticket (Without Fare)" : "Without Fare"}
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}

                  {type === "BuildPackage" ? (
                    <PDFDownloadLink
                      document={
                        <BuildPackageDoc
                          ticketData={ticketData}
                          withFare={true}
                          agent={agent}
                          Logo={logoImage}
                        />
                      }
                      fileName="PackageTicket.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="mb-1">
                            {agent ? "E-Ticket (With Fare)" : "With Fare"}
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}
                  {type === "BuildPackage" ? (
                    <PDFDownloadLink
                      document={
                        <BuildPackageDoc
                          ticketData={ticketData}
                          withFare={false}
                          agent={agent}
                          Logo={logoImage}
                        />
                      }
                      fileName="PackageTicket.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button>
                            {" "}
                            {agent ? "E-Ticket (Without Fare)" : "Without Fare"}
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}
                  {type === "BuildPackage" && agent ? (
                    <PDFDownloadLink
                      document={
                        <BuildPackageInvoiceDoc
                          invoiceData={invoiceData}
                          logo={logoImage}
                        />
                      }
                      fileName="Package_Invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="invoice-btn">Invoice</Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
        : null}

      {type === "Activities" && ticketData.TourDetails[0]?.bookingStatus == 11 ?
        <>
          <div className="ticket-options">
            <p onClick={() => printTicket()}>
              <PrinterOutlined /> Print E-Ticket
            </p>
          </div>

          <div className="mb-3">
            <Collapse accordion expandIcon={() => <MailOutlined />}>
              <Panel header={"Email Ticket"} key="1">
                <div className="trip-type-selector">
                  <div className="trip-type-selector">
                    <Button
                      block
                      style={{ marginBottom: 10 }}
                      onClick={() => {
                        toggleEmailModal();

                        setWithFare(1);
                      }}
                    >
                      With Price
                    </Button>

                    <Button
                      block
                      onClick={() => {
                        toggleEmailModal();
                        setWithFare(0);
                      }}
                    >
                      Without Price
                    </Button>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
        </> : null
      }
      {type === "Activities" && ticketData.TourDetails[0]?.bookingStatus != 11
        ? ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}

      {type === "BuildPackage"
        ? ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}
      {/* Add Code By Karthik 28/April/2023 */}
      {type === "Activities" && ticketData.TourDetails[0]?.bookingStatus == 3 ?
        <>
          <div className="ticket-options">
            <p onClick={() => toggleVoucheredModal()}>
              <DollarOutlined /> Vouchered
            </p>
          </div>
        </>
        : null}

      {invoiceData && invoiceData?.TourDetails && invoiceData?.TourDetails?.length > 0 ? (agent ? (
        type === "Activities" ? (
          <div className="ticket-options">
            <p>
              <PDFDownloadLink
                document={
                  <ActivitiesInvoiceDoc
                    invoiceData={invoiceData}
                    logo={logoImage}
                    type={"invoice"}
                  />
                }
                fileName="Activities_Invoice.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Button>Loading document... </Button>
                  ) : (
                    <Button className="invoice-btn border-0"><strong>Invoice</strong></Button>
                  )
                }
              </PDFDownloadLink>
            </p>
          </div>
        ) : null
      ) : null
      ) : null}
      {/* Add Code By Karthik 28/April/2023 */}

      {/* Code Changes By karthik Refence By nitin sir */}
      {agent ? (
        <>
          {/* {ticketData.cancellable ? ( */}

          <div className="ticket-options">
            <p onClick={() => togglePstMrkModal()}>
              <DollarOutlined /> Post Markup
            </p>
          </div>
          {/* ) : null} */}
          {/* Code Changes By karthik Refence By nitin sir */}
          <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>Post Markup</strong>
                </h6>
              </div>,
            ]}
            width={250}
            className="promo-modal-header"
            visible={pstMrkModalVisible}
            onOk={togglePstMrkModal}
            onCancel={togglePstMrkModal}
            footer={[
              <div>
                <Button key="close" onClick={togglePstMrkModal}>
                  Cancel
                </Button>

                <Button
                  key="add"
                  type="primary"
                  htmlType="submit"
                  onClick={pstMrkForm.submit}
                >
                  Add
                </Button>
              </div>,
            ]}
          >
            <Form
              form={pstMrkForm}
              layout="vertical"
              onFinish={submitPostMarkup}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },

                      {
                        validator: (_, value) => {
                          if (Number(value) > 0) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Please Enter valid Amount");
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} type="number" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          {/* Add Modal by Karthik  */}
          <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>Vouchered</strong>
                </h6>
              </div>,
            ]}
            width={400}
            className="promo-modal-header"
            visible={VoucheredModalVisible}
            onOk={VoucheredConfirm}
            onCancel={toggleVoucheredModal}
            footer={[
              <div>
                <Button key="close" onClick={() => toggleVoucheredModal()}>
                  Cancel
                </Button>
                <Button key="add" type="primary" onClick={VoucheredConfirm}>
                  Confirm
                </Button>
              </div>,
            ]}
          >
            <h6 className="text-center"><strong>Note :- </strong>This Ticket will be Non Refundable and You will get this ticket in 30 Minutes</h6>
          </Modal>

          {/* add email Modal */}
          <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>Enter The Email Address</strong>
                </h6>
              </div>,
            ]}
            className="promo-modal-header"
            visible={emailModalVisible}
            onOk={toggleEmailModal}
            onCancel={toggleEmailModal}
            footer={[
              <div>
                <Button key="close" onClick={toggleEmailModal}>
                  Cancel
                </Button>

                <Button
                  key="add"
                  type="primary"
                  htmlType="submit"
                  onClick={emailForm.submit}
                >
                  Send
                </Button>
              </div>,
            ]}
          >
            <Form form={emailForm} layout="vertical" onFinish={submitEmailForm}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[
                      { required: true, message: "Required!" },
                      { type: "email", message: "Email is not a valid email" },
                    ]}
                  >
                    <Input placeholder="Enter The Email Address" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </>
      ) : null}
    </div>
  );
};
export default ActivityTicketSidebar;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, message, Modal, Rate, Skeleton, Row, Col, Spin, Collapse } from "antd";
import { useCurrencyContext } from "../../../../common/providers/CurrencyProvider";
import ImagesLightbox from "../../../../components/ImagesLightbox/ImagesLightbox";
import queryString from "query-string";
import { EnvironmentOutlined, ClockCircleOutlined } from "@ant-design/icons";
import hotelNoImg from "../../../../assets/images/hotels/no_photo.png";
import "../ActivitiesList/ActivitiesList.scss";
import { useAuthContext } from "../../../../common/providers/AuthProvider";
import ShowCartModel from "./ShowCartModel";
import { useActivitiesContext } from "../../../../common/providers/Activities/ActivitiesProvider";

import ActivitiesSelectpkg from "../../ActivitiesTourDetail/ActivitiesSelectpkg";
import ApiClient from "../../../../helpers/ApiClient";

const ActivitiesList = ({
  toursData,
  mainToursList,
  activeTab,
  setActiveTab,
  showNetFare,
  isFromPackage,
}) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const { Panel } = Collapse;
  const { user } = useAuthContext();

  const [tourDetail, setTourDetail] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [imagesModal, setImagesModal] = useState({
    visible: false,
    data: null,
  });
  const [modalOpen, setmodalOpen] = useState(false);
  const [TourPolicyData, setTourPolicyData] = useState({});
  const TourImage = [];

  const [cartData, setCartData] = useState({
    searchRequest: {},
    TourDetails: [],
  });
  const {
    state: { activitiesCart },
    setActivitiesCart,
  } = useActivitiesContext();

  const goToActivitieDetails = (tourObj) => {
    let queryObj = {
      traceId: mainToursList.traceId,
      tourId: tourObj.tourId,
      key: tourObj.apikey,
    };

    const query = queryString.stringify(queryObj);
    //selectToursActivity(query)
   return "/activities/tourDetail?" + query;
  };
 
  const onHandleModal = (hotelObj) => {
    setImagesModal((prev) => ({ ...prev, data: hotelObj, visible: true }));
  };

  function handleImagesModalClose() {
    setImagesModal((prev) => ({ ...prev, data: null, visible: false }));
  }

  const selectTours = (traceId, tourId, apikey) => {
    setLoading(true);
    setTourDetail({});
    const ReqObj = { traceId, tourId, apikey };
    if (ReqObj.traceId != null && ReqObj.tourId != null) {
      ApiClient.post("activities/tours/details", ReqObj)
        .then((result) => result)
        .then((resp) => {
          if (resp?.statusCode == 200) {
            setTourDetail({ ...resp.data, traceId: ReqObj.traceId });
          } else {
            message.error("No Tours Found", 3);
          }
          setLoading(false);
        });
    }
  };

  const getActivitiesByUserId = (userId) => {
   
    ApiClient.get(`activities/tours/getactivitiesbyuserId/${userId}`)
      .then((result) => result)
      .then((resp) => {
        if (resp.statusCode == 200 && resp?.data) {
          //  setCartData(resp.data);
          setActivitiesCart(resp.data)
         
        } else {
          // setCartData({
          //   searchRequest: {},
          //   TourDetails: [],
          // });
          setActivitiesCart({
            searchRequest: {},
            TourDetails: [],
          });
        }
        setLoading(false);
      });
  };

  const ShowModal = (traceId, tourId, apikey) => {
    setLoading(true);
    setmodalOpen(true);
    const ReqObj = { traceId, tourId, apikey };
    ApiClient.post("activities/tours/details", ReqObj)
      .then((result) => result)
      .then((resp) => {
        if (resp?.statusCode == 200) {
          setTourPolicyData(resp.data);
        } else {
          message.error("No Tours Found", 3);
        }
        setLoading(false);
      });
  };

  const closeModal = () => {
    setmodalOpen(false);
  };

  useEffect(() => {
    if (user) {
      if (user.UserID) {
       // getActivitiesByUserId(user.UserID);
      }
    }
  }, [user]);

  return (
    <div className="activitys_search_list">
      <div className="activity-card">
        <div className="results-wrapper">
          <div className="grid-item activity-image">
            {toursData.imagePath == null ? (
              <div className="activity_image_">
                <img
                  loading="lazy"
                  src={hotelNoImg}
                  alt={toursData.imagePath}
                />
              </div>
            ) : (
              <div className="activity_image_">
                <img src={toursData.imagePath} alt={toursData.tourName} />
                <div
                  className="gallery-icon"
                  onClick={() => onHandleModal(toursData)}
                >
                  <i className="fa fa-search-plus" aria-hidden="true"></i>
                </div>
              </div>
            )}
          </div>
          <div className="grid-item activity-title">
            <div className="activity-title-wrapper">
              <Link to={goToActivitieDetails(toursData)}>
                <span className="activity-name">{toursData.tourName} </span>{" "}
              </Link>
            </div>
            {toursData.rating ? (
              <div className="activity-star">
                <Rate
                  className="starRating"
                  disabled
                  value={Number(toursData.rating)}
                  allowHalf={true}
                />
              </div>
            ) : (
              <div className="activity-star">
                <Rate
                  className="starRating"
                  disabled
                  value={0}
                  allowHalf={true}
                />
              </div>
            )}
            <div className="activity-address">
              <EnvironmentOutlined />
              <span className="activity-address-title">
                {toursData.cityTourType}
              </span>
            </div>
            <div className="activity-address">
              <EnvironmentOutlined />
              <span className="activity-address-title">
                {toursData?.cancellationPolicyName}
              </span>
            </div>

            <div className="activity-duration">
              <ClockCircleOutlined />
              <span>{toursData.duration}</span>
            </div>
            <div className="activity-desc">
              <div
                dangerouslySetInnerHTML={{
                  __html: toursData.tourShortDescription,
                }}
              ></div>
            </div>
            <div className="modalPolicy">
              <Button type="primary" onClick={() => ShowModal(mainToursList.traceId, toursData.tourId, toursData.apikey)}>
                About The Experience
              </Button>

              <Button type="primary" onClick={() => ShowModal(mainToursList.traceId, toursData.tourId, toursData.apikey)} className="mx-3">
                Inclusion
              </Button>

              <Button type="primary" onClick={() => ShowModal(mainToursList.traceId, toursData.tourId, toursData.apikey)}>
                Important Information
              </Button>

              <Modal
                visible={modalOpen}
                // centered
                width={800}
                title="Tour Policy"
                onCancel={closeModal}
                footer={null}
              >
                {isLoading ? <Spin size="large" tip="Loading..." style={{ width: "100%" }} /> : (
                  <div className="tour_policyPop pd-0">
                    <Collapse
                      className="p-0"
                      bordered={false}
                      defaultActiveKey={["1"]}
                      expandIconPosition="right"
                    >
                      <Panel className="bg-white border-0" header={<h6><strong>About The Experience</strong></h6>} key="1">
                        {TourPolicyData.itenararyDescription != '' && TourPolicyData.itenararyDescription != null ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: TourPolicyData.itenararyDescription,
                            }}
                          ></div>) : "Tour Descripation Is Not Available"}
                      </Panel>
                    </Collapse>

                    <Collapse
                      bordered={false}
                      defaultActiveKey={["1"]}
                      expandIconPosition="right"
                    >
                      <Panel className="bg-white border-0" header={<h6><strong>Tour Inclusion</strong></h6>} key="1">
                      
                        <div
                          dangerouslySetInnerHTML={{
                            __html: TourPolicyData.tourInclusion,
                          }}
                        ></div>
                      </Panel>
                    </Collapse>

                    <Collapse
                      className="p-0"
                      bordered={false}
                      defaultActiveKey={["1"]}
                      expandIconPosition="right"
                    >
                      <Panel className="bg-white border-0" header={<h6><strong>Tour Exclusion</strong></h6>} key="1">
                        {TourPolicyData.tourExclusion != '' && TourPolicyData.tourExclusion != null ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: TourPolicyData.tourExclusion,
                            }}
                          ></div>) : "Tour Exclusion Is Not Available"}
                      </Panel>
                    </Collapse>

                    <Collapse
                      className="p-0"
                      bordered={false}
                      defaultActiveKey={["1"]}
                      expandIconPosition="right"
                    >
                      <Panel className="bg-white border-0" header={<h6><strong>Important Information</strong></h6>} key="1">
                        {TourPolicyData?.importantInformation != '' && TourPolicyData?.importantInformation != null  ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: TourPolicyData?.importantInformation,
                            }}
                          ></div>) : "Important Information Is Not Available"}
                      </Panel>
                    </Collapse>

                    <Collapse
                      className="p-0"
                      bordered={false}
                      defaultActiveKey={["1"]}
                      expandIconPosition="right"
                    >
                      <Panel className="bg-white border-0" header={<h6><strong>Booking Policy</strong></h6>} key="1">
                        <p className="mb-1"><strong>Cancellation Policy</strong></p>
                        {TourPolicyData?.cancellationPolicyDescription != '' && TourPolicyData?.cancellationPolicyDescription != null  ? (
                          <div className="mb-3"
                            dangerouslySetInnerHTML={{
                              __html: TourPolicyData?.cancellationPolicyDescription,
                            }}
                          ></div>) : "Cancellation Policy Is Not Available"}
                        <p className="mb-1"><strong>Child Policy</strong></p>
                        {TourPolicyData?.childCancellationPolicyDescription != '' && TourPolicyData?.childCancellationPolicyDescription != null  ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: TourPolicyData?.childCancellationPolicyDescription,
                            }}
                          ></div>) : "Child Policy Is Not Available"}
                      </Panel>
                    </Collapse>
                  </div>
                )}
              </Modal>
            </div>
          </div>

          <div className="grid-item activity-price-box">
            <div className="activity-price-box-wrapper cp-wrapper-activity">
              <div className="activity-price activity-cp-price">
                {showNetFare ? (
                  <>
                    <span className="text-line">
                      {activeCurrency}{" "}
                      <span>{currencyValue(toursData.publishAmount)}</span>
                    </span>

                    <p className="netfare">
                      {activeCurrency} {currencyValue(toursData.netAmount)}{" "}
                    </p>
                    <p className="netfare">
                      Inc: {activeCurrency}{" "}
                      {currencyValue(toursData.commission)}{" "}
                    </p>
                  </>
                ) : (
                  <span>
                    {activeCurrency}{" "}
                    <span>{currencyValue(toursData.publishAmount)}</span>
                  </span>
                )}
              </div>
              {/* Add New Features to replace previous features by bubul mam */}
              {/* { isFromPackage ?( */}
                <div className="activity-choose-btn">
                  {activeTab !== `tour_${toursData.tourId}` ? (
                    <Button
                      block
                      className="select-btn activity-choose-btn"
                      onClick={() => {
                        selectTours(
                          mainToursList.traceId,
                          toursData.tourId,
                          toursData.apikey
                        );
                        setActiveTab(`tour_${toursData.tourId}`);
                      }}
                    >
                      Select
                    </Button>
                  ) : (
                    <Button
                      block
                      className="dark-choose-btn activity-choose-btn"
                      onClick={() => {
                        setActiveTab(null);
                      }}
                    >
                      Close
                    </Button>
                  )}
                </div>
              {/* //  ) : (
              //   <div className="activity-choose-btn">
              //     <Link to={goToActivitieDetails(toursData)}>
              //       <Button
              //         block
              //         className="web-choose-btn activity-choose-btn"
              //         onClick={() => {
              //           goToActivitieDetails(toursData)
                        
              //         }}
              //       >
              //         Choose
              //       </Button>
              //     </Link>
              //   </div>
              // )} */}
            
              {/* This Code Add By Karthik End Here */}
            </div>
          </div>
        </div>
{/* isFromPackage && remove bcz both have same view now */}
        { activeTab === `tour_${toursData.tourId}` ? (
          <div className="activities-list">
            {isLoading ? (
              <Skeleton active />
            ) : activeTab ? (
              activeTab === `tour_${toursData.tourId}` ? (
                <ActivitiesSelectpkg
                  tourDetail={tourDetail}
                  isfromPackage={isFromPackage?true:false}
                  searchReq={mainToursList.searchReq}
                  setCartData={setCartData}
                  getActivitiesByUserId={getActivitiesByUserId}
                />
              ) : null
            ) : null}
          </div>
        ) : null}

   
      </div>
      {!isFromPackage && (
        <div className="view-select-pkg-plan">
          <Button
            className="border-0 btn btn-danger shadow-none"
            data-toggle="modal"
            data-target="#showMyCart"
          >
            Show Cart
          </Button>
        </div>
      )}
      {!isFromPackage && (
        <div
          className="modal left fade tabs-left-bar"
          id="showMyCart"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="showMyCartModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close close-hide-show"
                  data-dismiss="modal"
                  aria-label="Close"
                  // onClick={hideModal}

                >
                  <span aria-hidden="true">Hide</span>
                </button>
              </div>

              <div className="modal-body">
                <Row>
                  <Col md={24} xs={24} className="trace-hotels ">
                    <ShowCartModel 
                    cartData={cartData}
                    getActivitiesByUserId={getActivitiesByUserId}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        wrapClassName="modalHeader hotelImgModal"
        visible={imagesModal.visible}
        centered
        width={800}
        title={
          imagesModal.visible ? (
            <div className="headerwrapper">
              <span>{imagesModal.data.tourName} </span>{" "}
              {imagesModal.data.rating && (
                <sup>
                  <Rate
                    className="starRating"
                    disabled
                    value={Number(imagesModal.data.rating)}
                    allowHalf={true}
                  />
                </sup>
              )}
            </div>
          ) : null
        }
        onOk={handleImagesModalClose}
        onCancel={handleImagesModalClose}
        footer={null}
      >
        {imagesModal.visible ? (
          <ImagesLightbox hotelImages={TourImage} />
        ) : null}
      </Modal>
      {/* 
      <ReactBootstrap.Tabs
        activeKey={activeTab}
        id="controlled-tab"
        onSelect={(tabKey) => {
          if (activeTab !== tabKey) {
            selectTours(
              mainToursList.traceId,
              toursData.tourId,
              toursData.apikey
            );
            setActiveTab(tabKey);
          } else {
            setActiveTab(null);
          }
        }}
      >
        <ReactBootstrap.Tab
          eventKey={`tour_${toursData.tourId}`}
          title={activeTab === `tour_${toursData.tourId}` ? "Close" : "Select"}
        >
          <div className="seats_wrapper">
            {isLoading ? (
              <Skeleton active />
            ) : activeTab ? (
              activeTab === `tour_${toursData.tourId}` ? (
                <ActivitiesSelectpkg
                  tourDetail={tourDetail}
                  isfromPackage={true}
                  searchReq={mainToursList.searchReq}
                />
              ) : null
            ) : null}
          </div>
        </ReactBootstrap.Tab>
      </ReactBootstrap.Tabs> */}
    </div>
  );
};

export default ActivitiesList;

import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  message,
  Modal,
  Select,
  Spin,
  Table,
  Rate,
  Upload,
} from "antd";

import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";

import search from "../../../src/assets/vector-icons/search.png";
import excel from "../../assets/vector-icons/excel.png";
import UpdatedComponent from "../ExportExcel";

import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import "./Bookingreports.scss";

const { Option } = Select;
const { TextArea } = Input;
let dateFormat = "DD-MM-YYYY";
let oriDateFormat = "YYYY-MM-DD";

const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const ImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  label = "",
  limit,
  limitSize,
  deleteBanner,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(true);
      return;
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName} label={label ? label : ""}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          // onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList
              ? fileList.length >= limitSize
                ? null
                : uploadLogoButton
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        destroyOnClose={true}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const Bookingreports = (props) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const [reviewForm] = Form.useForm();
  const [showTable, setShowTable] = useState(false);
  // const [vocher, setVocher] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [reviewModal, setReviewModal] = useState({
    ServiceType: "",
    RefNo: "",
    UserId: "",
    EmailId: "",
    PhoneNumber: "",
    StartRating: 0,
    TotalAmount: 0,
    modalVisible: false,
  });
  const [loading, setLoading] = useState(false);
  const [fromdateto, setFromdateto] = useState(moment());
  const [todateto, setTodateto] = useState();
  const [reports, setReports] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [reviewImages, setReviewImages] = useState([]);

  const [showSearchBox, setShowSearchBox] = useState(false);

  const [columns, setColumns] = useState([]);
  const [form] = Form.useForm();
  const [toDisableDate, setToDisableDate] = useState(moment());
  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const toggleReviewModal = () => {
    reviewForm.resetFields();
    setReviewImages([]);
    setReviewModal((prev) => ({
      ...prev,
      ServiceType: "",
      RefNo: "",
      UserId: "",
      EmailId: "",
      PhoneNumber: "",
      StartRating: 0,
      TotalAmount: 0,
      modalVisible: !prev.modalVisible,
    }));
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = reports.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      setDataSource(reports);
    }
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const renderReffrence = (ref, rec) => {
    const type = rec.type;
    let url = "";
    switch (type) {
      case "Flight": {
        url = "flight/ticket";
        break;
      }
      case "Bus": {
        url = "bus/ticket";
        break;
      }
      case "Hotel": {
        url = "hotel/ticket";
        break;
      }
      case "Activities": {
        url = "activities/ticket";
        break;
      }
    }

    return <Link to={url + "?ref=" + ref}>{ref}</Link>;
  };

  const submitReviewForm = (val) => {
    if (!reviewModal?.StartRating && reviewModal?.StartRating === 0) {
      message.error("Star Rating Required");
      return;
    }

    let array = [];
    if (val.ImagesPath) {
      if (val.ImagesPath.fileList.length > 0) {
        for (let i = 0; i < val.ImagesPath.fileList.length; i++) {
          if (val.ImagesPath.fileList[i].response.status === 200) {
            array.push(val.ImagesPath.fileList[i].response.data.filepaths[0]);
          }
        }
      }
    }

    let req = {
      ...reviewModal,
      ...val,
      ImagesPath: array,
      DeviceOS: 3,
      ApplicationType: 1,
      CreadtedBy: user?.UserID ?? 1,
      CreatedDate: moment(),
      ModifiedBy: 0,
      Status: 0,
      ModifiedDate: "string",
      DeviceToken: "string",
      DeviceType: "string",
      FirBaseToken: "string",
      DeviceOSVersion: "string",
    };

    delete req.modalVisible;

    ApiClient.post("UserReviews/userReview", req)
      .then((res) => {
        if (res.status == 200) {
          if (res?.message) message.success(res?.message, 3);

          toggleReviewModal();
        }
      })
      .catch(() => {});
  };

  const getReviewButton = (record) => {
    if (!agent) {
      if (record.type === "Bus") {
        if ([3, 7].includes(record.bookingStatus)) {
          if (moment(record.journeyDate, "DD-MM-YYYY") < moment()) {
            return (
              <Button onClick={() => handleReviewModal(record)}>
                Add Review
              </Button>
            );
          }
        }
      } else if (record.type === "Flight") {
        if ([3].includes(record.bookingStatus)) {
          if (moment(record.journeyDate) < moment()) {
            return (
              <Button onClick={() => handleReviewModal(record)}>
                Add Review
              </Button>
            );
          }
        }
      } else if (record.type === "Hotel") {
        if ([2].includes(record.bookingStatus)) {
          if (
            moment(record.CheckInDate, "YYYY-MM-DD").startOf("day") <
            moment().startOf("day")
          ) {
            return (
              <Button onClick={() => handleReviewModal(record)}>
                Add Review
              </Button>
            );
          }
        }
      } else if (record.type === "Activities") {
        if ([3].includes(record.bookingStatus)) {
          if (
            moment(record.journeyDate, "YYYY-MM-DD").startOf("day") <
            moment().startOf("day")
          ) {
            return (
              <Button onClick={() => handleReviewModal(record)}>
                Add Review
              </Button>
            );
          }
        }
      }
    }
  };

  const handleReviewModal = (record) => {
    if (record.type === "Bus") {
      setReviewModal({
        ServiceType: 3,
        RefNo: record.referenceNumber,
        UserId: record.UserId,
        EmailId: record.guestEmaiId,
        PhoneNumber: record.guestMobileNo,
        StartRating: 0,
        TotalAmount: record.totalPrice,
        modalVisible: true,
      });
    } else if (record.type === "Flight") {
      setReviewModal({
        ServiceType: 1,
        RefNo: record.referenceNumber,
        UserId: record.UserId,
        EmailId: record.guestEmaiId,
        PhoneNumber: record.guestMobileNo,
        StartRating: 0,
        TotalAmount: record.totalPrice,
        modalVisible: true,
      });
    } else if (record.type === "Hotel") {
      setReviewModal({
        ServiceType: 2,
        RefNo: record.referenceNumber,
        UserId: record.UserId,
        EmailId: record.guestEmaiId,
        PhoneNumber: record.guestMobileNo,
        StartRating: 0,
        TotalAmount: record.totalPrice,
        modalVisible: true,
      });
    } else if (record.type === "Activities") {
      setReviewModal({
        ServiceType: 4,
        RefNo: record.referenceNumber,
        UserId: record.UserId,
        EmailId: record.guestEmaiId,
        PhoneNumber: record.guestMobileNo,
        StartRating: 0,
        TotalAmount: record.totalPrice,
        modalVisible: true,
      });
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case "HOLD": {
        return <p style={{ color: "#FFA500" }}>HOLD</p>;
      }
      case "BLOCKED": {
        return <p style={{ color: "#FFA500" }}>{status}</p>;
      }
      case "CONFIRMED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "BOOKED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "CANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CANCELED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CREATED": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }
      case "PARTIALLY CANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "FAILED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      default:
        return;
    }
  };

  let flightColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Review & Rating/Photos",
      render: (_, record) => getReviewButton(record),
    },
    {
      title: "Source",
      dataIndex: "from",
      render: (text, record) => {
        return record.type == "Flight" ? record.oneWaySegment[0].origin : text;
      },
    },
    {
      title: "Destination",
      dataIndex: "to",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = record.oneWaySegment.length;
          return record.oneWaySegment[length - 1].destination;
        } else {
          return text;
        }
      },
    },
    {
      title: "Arrival Date",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight"
          ? moment(text[0].arrivalDateTime, oriDateFormat).format(dateFormat)
          : text,
    },
    {
      title: "Departure Date",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return moment(
            text[length - 1].departureDateTime,
            oriDateFormat
          ).format(dateFormat);
        } else {
          return text;
        }
      },
    },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },
    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      key: "journeyDate",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : null),
    },
    // {
    //   title: "Departure Date",
    //   dataIndex: "oneWaySegment",
    //   render: (text, record) => {
    //     if (record.type === "Flight") {
    //       let length = text.length;
    //       return moment(
    //         text[length - 1].departureDateTime,
    //         oriDateFormat
    //       ).format(dateFormat);
    //     } else {
    //       return text;
    //     }
    //   },
    // },

    {
      title: "Guest Name ",
      dataIndex: "guestName",
    },
    {
      title: "Guest Email",
      dataIndex: "guestEmaiId",
    },
    {
      title: "Guest Mobile ",
      dataIndex: "guestMobileNo",
    },
    {
      title: "Adults",
      dataIndex: "pax",
      render: (text) => (text ? text.adults : ""),
    },
    {
      title: "Childs",
      dataIndex: "pax",
      render: (text) => (text ? text.childCount : ""),
    },
    {
      title: "Infant",
      dataIndex: "pax",
      render: (text) => (text ? text.infantCount : ""),
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      render: (status) => getStatus(status),
    },
  ];
  let allColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },

    {
      title: "Review & Rating/Photos",
      render: (_, record) => getReviewButton(record),
    },
    {
      title: "Service Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type?.localeCompare(b.type),
    },

    {
      title: "Source",
      dataIndex: "from",
      render: (text, record) => {
        if (record.type == "Flight") {
          return record.oneWaySegment[0].origin;
        } else if (record.type == "Bus") {
          return record.from;
        } else {
          return "";
        }
      },
    },
    {
      title: "Destination",
      dataIndex: "to",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = record.oneWaySegment.length;
          return record.oneWaySegment[length - 1].destination;
        } else if (record.type == "Bus") {
          return record.to;
        } else {
          return "";
        }
      },
    },
    {
      title: "Journey Date",
      // dataIndex: "journeyDate",
      key: "journeyDate",
      render: (text) =>
        text.type == "Flight"
          ? moment(text.journeyDate).format("DD-MM-YYYY")
          : text.journeyDate,
      sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
    },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },
    {
      title: "Pax Name ",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName?.localeCompare(b.guestName),
    },
    {
      title: "Pax Email",
      dataIndex: "guestEmaiId",
      sorter: (a, b) => a.guestEmaiId?.localeCompare(b.guestEmaiId),
    },
    {
      title: "Pax Mobile ",
      dataIndex: "guestMobileNo",
      sorter: (a, b) => a.guestMobileNo?.localeCompare(b.guestMobileNo),
    },
    {
      title: "Arrival Date",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight"
          ? moment(text[0].arrivalDateTime, oriDateFormat).format(dateFormat)
          : "",
    },

    {
      title: "Departure Date",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return moment(
            text[length - 1].departureDateTime,
            oriDateFormat
          ).format(dateFormat);
        } else {
          return "";
        }
      },
    },
    {
      title: "CheckIn Date",
      dataIndex: "CheckInDate",
      render: (text, record) =>
        record.type == "Hotel"
          ? moment(record.CheckInDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.CheckInDate?.localeCompare(b.CheckInDate),
      // render: (CheckInDate) =>  moment(CheckInDate).format("DD-MM-YYYY"),
    },
    {
      title: "CheckOut Date",
      dataIndex: "CheckOutDate",
      render: (text, record) =>
        record.type == "Hotel"
          ? moment(record.CheckOutDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.CheckOutDate?.localeCompare(b.CheckOutDate),
    },
    {
      title: "Adults",
      // dataIndex: "pax",
      render: (text, record) => {
        if (record.type == "Flight") {
          return record.pax ? record.pax.adults : "";
        } else if (record.type == "Bus") {
          return "";
        } else if (record.type == "Hotel") {
          return record.pax ? record.pax.adults : "";
        } else if (record.type == "Activities") {
          return record.pax ? record.pax.adults : "";
        } else {
          return "";
        }
      },
    },
    {
      title: "Childs",
      //dataIndex: "pax",
      render: (text, record) => {
        if (record.type == "Flight") {
          return record.pax ? record.pax.childCount : "";
        } else if (record.type == "Bus") {
          return "";
        } else if (record.type == "Hotel") {
          return record.pax ? record.pax.childCount : "";
        } else if (record.type == "Activities") {
          return record.pax ? record.pax.childCount : "";
        } else {
          return "";
        }
      },
    },
    {
      title: "Infant",
      //dataIndex: "pax",
      render: (record) => {
        if (record.type == "Flight") {
          return record.pax ? record.pax.infantCount : "";
        } else if (record.type == "Bus") {
          return "";
        } else if (record.type == "Hotel") {
          return "";
        } else if (record.type == "Activities") {
          return record.pax ? record.pax.infantdCount : "";
        } else {
          return "";
        }
      },
    },
    {
      title: "Operator Name",
      dataIndex: "operator",
      key: "operatorName",
    },

    {
      title: "Pickup Location",
      dataIndex: "PickupLocation",
      key: "pickUpLocation",
    },
    {
      title: "Drop Location",
      dataIndex: "DropLocation",
      key: "dropLocation",
    },
    {
      title: "Hold Time Date",
      render: (currentData) =>
        currentData?.BookingStatus === "HOLD"
          ? currentData?.LastVoucherDate
            ? moment(currentData?.LastVoucherDate).format("DD-MM-YYYY hh:mm")
            : null
          : null,
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "status",
      render: (status) => getStatus(status),
      sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
    },
  ];
  let hotelColumns = [
    {
      title: "ReferenceNumber",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Review & Rating/Photos",
      render: (_, record) => getReviewButton(record),
    },
    {
      title: "Guest Name",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName?.localeCompare(b.guestName),
    },
    { title: "Hotel Name", dataIndex: "HotelName" },
    { title: "City", dataIndex: "City" },
    { title: "NoOfRooms", dataIndex: "NoOfRooms" },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },
    {
      title: "CheckIn Date",
      dataIndex: "CheckInDate",
      render: (CheckInDate) => moment(CheckInDate).format("DD-MM-YYYY"),
      sorter: (a, b) => a.CheckInDate?.localeCompare(b.CheckInDate),
    },
    {
      title: "CheckOut Date",
      dataIndex: "CheckOutDate",
      render: (CheckOutDate) => moment(CheckOutDate).format("DD-MM-YYYY"),
      sorter: (a, b) => a.CheckOutDate?.localeCompare(b.CheckOutDate),
    },
    {
      title: "Hold Time Date",
      render: (currentData) =>
        currentData.BookingStatus === "HOLD"
          ? currentData.LastVoucherDate
            ? moment(currentData.LastVoucherDate).format("DD-MM-YYYY hh:mm")
            : null
          : null,
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      render: (status) => getStatus(status),
      sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
    },
  ];
  let busColumns = [
    {
      title: "Reference No.",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Review & Rating/Photos",
      render: (_, record) => getReviewButton(record),
    },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },
    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
      // key: "journeyDate",
    },
    { title: "Source", dataIndex: "from" },
    { title: "Destination", dataIndex: "to" },
    { title: "Operator", dataIndex: "operator" },
    // { title: "PickupLocation", dataIndex: "PickupLocation" },
    // { title: "DropLocation", dataIndex: "DropLocation" },
    {
      title: "Pax Name ",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName - b.guestName,
    },
    {
      title: "Email",
      dataIndex: "guestEmaiId",
      sorter: (a, b) => a.guestEmaiId?.localeCompare(b.guestEmaiId),
    },
    {
      title: "Mobile",
      dataIndex: "guestMobileNo",
      sorter: (a, b) => a.guestMobileNo?.localeCompare(b.guestMobileNo),
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      render: (status) => getStatus(status),
      sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
    },
  ];
  //  Reference number	Guest Name  Activity Name	 Adult  Child  Infant  Booking Date 	Travel Date	Booking Status
  let activitiesColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Review & Rating/Photos",
      render: (_, record) => getReviewButton(record),
    },
    {
      title: "Tour Name",
      dataIndex: "tourName",
      sorter: (a, b) => a.tourName?.localeCompare(b.tourName),
    },
    {
      title: "Tour Option",
      dataIndex: "optionName",

      sorter: (a, b) => a.optionName?.localeCompare(b.optionName),
    },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },

    {
      title: "journey Date",
      dataIndex: "journeyDate",
      render: (journeyDate) => moment(journeyDate).format("DD-MM-YYYY"),
      sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
    },
    {
      title: "Pax Name ",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName?.localeCompare(b.guestName),
    },
    {
      title: "Pax Email",
      dataIndex: "guestEmaiId",
      sorter: (a, b) => a.guestEmaiId?.localeCompare(b.guestEmaiId),
    },
    {
      title: "Pax Mobile ",
      dataIndex: "guestMobileNo",
      sorter: (a, b) => a.guestMobileNo?.localeCompare(b.guestMobileNo),
    },
    {
      title: "Adults",
      dataIndex: "pax",
      render: (text) => text?.adults ?? "",
    },
    {
      title: "Childs",
      dataIndex: "pax",
      render: (text) => text?.childCount ?? "",
    },
    {
      title: "Infant",
      dataIndex: "pax",
      render: (text) => text?.infantCount ?? "",
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "status",
      render: (status) => getStatus(status),
      sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
    },
    // {
    //   title: "Review & Rating/Photos",
    //   dataIndex: "totalPrice",
    //   render: (price) => (
    //     <Button title="Add Review" onClick={() => handleReviewModal(price)} />
    //   ),
    // },
  ];

  ///////fromdate
  const onChangeDate = (currentDate) => {
    const value = moment(currentDate).format("DD-MM-YYYY");
    setFromdateto(value);
  };

  const disabledDates = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment(fromdateto).startOf("day");
  };
  // const onChangetoDate = (currentDate) => {
  //   const value = moment(currentDate).format("DD-MM-YYYY");
  //   setTodateto(value);
  // };

  const disabledtoDate = (currentDate) => {
    return currentDate < moment(fromdateto).startOf("day");
  };

  const [col, setCol] = useState();
  /* Creating service dynamic column headers */
  const setTableHeaders = (travelType) => {
    switch (travelType) {
      case 1:
        setColumns(flightColumns);
        setCol(1);
        break;
      case 2:
        setColumns(hotelColumns);
        setCol(2);
        break;
      case 3:
        setColumns(busColumns);
        setCol(3);
        break;
      case 4:
        setColumns(activitiesColumns);
        setCol(4);
        break;
      default:
        setColumns(allColumns);
        setCol(5);
    }
  };

  const reset = () => {
    form.resetFields();
  };

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };

  /* Switching and updating service */

  const onFormSubmit = (values) => {
    setShowTable(true);

    setColumns([]);

    setTableHeaders(values.travelType);

    values.fromDate = values.fromDate
      ? moment(values.fromDate).format("YYYY-MM-DD")
      : "";
    values.toDate = values.toDate
      ? moment(values.toDate).format("YYYY-MM-DD")
      : "";

    values.userId = user?.UserID;
    getBookingListData(values);
  };

  const getBookingListData = (values) => {
    setLoading(true);
    setReports([]);
    setDataSource([]);
    ApiClient.get("admin/myBookings", values)
      .then((res) => {
        setLoading(false);
        if (res.statusCode == 200) {
          setFromdateto();
          setTodateto();
          if (user.Role.RoleId == 2) {
            let filterdata = res.data.filter(
              (item) => item.BookingStatus != "BLOCKED"
            );
            setReports(filterdata);
            setDataSource(filterdata);
          } else {
            setReports(res.data);
            setDataSource(res.data);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleExcel = () => {
    // const excelData = dataSource;
    const excelData = dataSource.map((item, index) => {
      if (col == 5) {
        var arival = "";
        if (item.type == "Flight") {
          arival = item.oneWaySegment[0];
        } else {
          arival = "null";
        }
        return {
          S_No: index + 1,
          ReferenceNumber: item.referenceNumber ?? "",
          Type: item.type ?? "",
          Source: arival != "null" ? item.oneWaySegment[0]?.origin : item.from,
          Destination:
            arival != "null" ? item.oneWaySegment[0].destination : item.to,
          JourneyDate: item.journeyDate ?? "",
          GuestName: item.guestName ?? "",
          GuestEmail: item.guestEmaiId ?? "",
          GuestMobileNo: item.guestMobileNo ?? "",
          Arrival: arival != "null" ? moment(arival).format("DD-MM-YYYY") : "",
          Departure:
            arival != "null"
              ? moment(item.oneWaySegment[0].departureDateTime).format(
                  "DD-MM-YYYY"
                )
              : "",
          CheckInDate: item.CheckInDate ?? "",
          CheckOutDate: item.CheckOutDate ?? "",
          Adults: item?.pax?.adults ?? "",
          Childs: item?.pax?.childCount ?? "",
          Infant: item?.pax?.infantCount ?? "",
          OperatorName: item.operator ?? "",
          PickupLocation: item.PickupLocation ?? "",
          DropLocation: item.DropLocation ?? "",
          BookingStatus: item.BookingStatus ?? "",
        };
      }
      if (col == 1) {
        return {
          S_No: index + 1,
          ReferenceNumber: item.referenceNumber ?? "",
          Type: item.type ?? "",
          Source: item?.oneWaySegment[0]?.origin ?? "",
          Destination: item.oneWaySegment[0].destination ?? "",
          JourneyDate: item.journeyDate ?? "",
          GuestName: item.guestName ?? "",
          GuestEmail: item.guestEmaiId ?? "",
          GuestMobileNo: item.guestMobileNo ?? "",
          Arrival: moment(item.oneWaySegment[0].arrivalDateTime).format(
            "DD-MM-YYYY"
          ),
          Departure: moment(item.oneWaySegment[0].departureDateTime).format(
            "DD-MM-YYYY"
          ),
          Adults: item.pax.adults ?? "",
          Childs: item.pax.childCount ?? "",
          Infant: item.pax.infantCount ?? "",
          BookingStatus: item.BookingStatus ?? "",
        };
      } else {
        return {
          S_No: index + 1,
          ...item,
        };
      }
    });
    props.exportExcel(excelData, "Booking_Reports");
  };

  if (agent) {
    flightColumns = flightColumns.filter(
      (item) => item.title !== "Review & Rating/Photos"
    );
    hotelColumns = hotelColumns.filter(
      (item) => item.title !== "Review & Rating/Photos"
    );
    busColumns = busColumns.filter(
      (item) => item.title !== "Review & Rating/Photos"
    );
    activitiesColumns = activitiesColumns.filter(
      (item) => item.title !== "Review & Rating/Photos"
    );
    allColumns = allColumns.filter(
      (item) => item.title !== "Review & Rating/Photos"
    );
  }
  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };
  const onChangeFromDate = (momentdate, _) => {
    setToDisableDate(momentdate);
  };
  const disabledSelectedDate = (currentDate) => {
    return currentDate < moment(toDisableDate).startOf("day");
  };

  return (
    <div className="manage-markup-section ourteam-bg">
      <div className="container-fluid cms-pages-width">
        <div className="card-bt-gap">
          <div className="card-add-heading">
            <div className="add-icon d-none">
              <i className="fa fa-plus"></i>
            </div>
          </div>
          <div>
            <Form
              layout={"vertical"}
              form={form}
              initialValues={{
                options: 1,
                bookingStatus: "",
                referenceNo: "",
                travelType: 0,
              }}
              onFinish={onFormSubmit}
            >
              <Card className="retrieve-booking card-style">
                <h5>Booking Reports</h5>

                <Row gutter={16}>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Time and Date"
                      name="options"
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder="Please select"
                        onChange={handleTimeAndDate}
                      >
                        <Option value={1}>Last One Hour</Option>
                        <Option value={2}>Yesterday</Option>
                        <Option value={3}>Last Week</Option>

                        <Option value={5}>Custom Dates</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {isDateDisplay ? (
                    <>
                      <Col md={6} sm={12} xs={24}>
                        <Form.Item label="From Date" name="fromDate">
                          <DatePicker
                            format="DD-MM-YYYY"
                            style={{ width: "100%" }}
                            // disabledDate={disabledOriginDate}
                            // onChange={(date) => setFromdateto(date)}
                            disabledDate={disabledFutureDate}
                            onChange={(date, dateString) =>
                              onChangeFromDate(date, dateString)
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col md={6} sm={12} xs={24}>
                        <Form.Item label="To Date" name="toDate">
                          <DatePicker
                            format="DD-MM-YYYY"
                            style={{ width: "100%" }}
                            // disabledDate={disabledtoDate}
                            disabledDate={disabledSelectedDate}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  ) : null}
                  <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item label="Service" name="travelType">
                      <Select placeholder="Select">
                        <Option value={0}>All</Option>
                        <Option value={1}>Flights</Option>
                        <Option value={2}>Hotels</Option>
                        <Option value={3}>Bus</Option>
                        <Option value={4}>Activities</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={6} xs={24}>
                    <Form.Item label="Reference No." name="referenceNo">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col md={6} xs={24}>
                    <Form.Item label="Booking Status" name="bookingStatus">
                      <Select placeholder="Please Select">
                        <Option value={2}>Blocked</Option>
                        <Option value={3}>Confirmed</Option>
                        <Option value={4}>Cancelled</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="reports-btns reset-button-fst reports-btns">
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button type="danger" onClick={reset}>
                    Reset
                  </Button>
                </Row>
              </Card>
            </Form>
          </div>
        </div>

        {showTable && (
          <div>
            <Card bordered={false} className="card-style">
              <div className="action-images">
                {showSearchBox && (
                  <Input
                    placeholder="Search"
                    onChange={(e) => searchTableData(e)}
                    suffix={closeSearchInput}
                    style={{ padding: "0px 12px", width: "300px" }}
                  />
                )}
                &nbsp;&nbsp;
                <img src={search} alt="search" onClick={(e) => searchData()} />
                <img src={excel} alt="excel" onClick={handleExcel} />
              </div>

              {dataSource.length > 0 ? (
                <Table
                  scroll={{ x: true }}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50", "100", "125"],
                  }}
                />
              ) : loading ? (
                <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
              ) : (
                <h5>No Data Found</h5>
              )}
            </Card>
          </div>
        )}
      </div>

      <Modal
        title="Review & Rating/Photos"
        className="promo-modal-header"
        visible={reviewModal.modalVisible}
        onOk={toggleReviewModal}
        onCancel={toggleReviewModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleReviewModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={reviewForm.submit}
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Form
          form={reviewForm}
          layout="vertical"
          onFinish={(val) => submitReviewForm(val)}
          initialValues={{
            Comments: "",
            imagePath: [],
          }}
        >
          <Form.Item label="Star Rating">
            <Rate
              value={reviewModal.StartRating}
              onChange={(value) =>
                setReviewModal((prev) => ({ ...prev, StartRating: value }))
              }
            />
          </Form.Item>

          <Form.Item label="Comment" name="Comments">
            <TextArea rows={2} />
          </Form.Item>

          <ImageUpload
            category="reviewratingphotos"
            fileList={reviewImages}
            setFileList={setReviewImages}
            formName="ImagesPath"
            label="Upload Images"
            limit={true}
            limitSize={10}
            // deleteBanner={DeleteBannerImage}
          />
          <Row gutter={[8, 8]}>
            <Col md={12} sm={12} xs={24}>
              <Form.Item
                label="Next Travel Destination"
                name="nextTravelDestination"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} sm={12} xs={24}>
              <Form.Item label="Next Travel Date" name="nextTravelDate">
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                  disabledDate={disabledDates}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(Bookingreports);

import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Radio,
  Layout,
  message,
  TimePicker,
  Popover,
} from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import moment from "moment";

import AirportAutoComplete from "../../common/AutoCompleteSelect/AirportAutoCompleteSelect";

import {
  OnlyFutureDateValidator,
  PaxAgeValidator,
} from "../../helpers/CustomValidators";
import queryString from "query-string";
// import { SeprateRequestCreator } from "./RequestCreatorHelper";
import ApiClient from "../../helpers/ApiClient";
import { MinusCircle } from "react-feather";
// import { useAuthContext } from "../../common/providers/AuthProvider";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

const CreateTicket = () => {
  const [form] = Form.useForm();
  // const { user } = useAuthContext();
  const [tripype, setTriptype] = useState("1");
  const [bookigFromType, setBookigFromType] = useState(1);

  const [agentList, setAgentList] = useState([]);

  const [isAdult, setIsAdult] = useState(true);
  const [isChild, setIsChild] = useState(false);
  const [isinfant, setIsInfant] = useState(false);
  // const [loadingTicket, setLoadingTicket] = useState(true);

  const initialState = {
    id: "",
    pax: {},
    passengers: [],
    oneWaySegment: [],
    returnSegment: [],
    oneWayflightFare: {},
    oneWayBaggageInfo: [],
    returnBaggageInfo: [],
  };
  const [ticketData, setTicketData] = useState(initialState);

  //update get RefNo from Query string

  const params = queryString.parse(window.location.search);

  useEffect(() => {
    if (params.ref && params.ref.indexOf("SYT-F") != -1) {
      // getTicketDetails(params.ref);
    }
  }, []);
  const getAllUsers = () => {
    ApiClient.get(`admin/user/`)
      .then((res) => {
        if (res.status == 200) {
          let agents = res.data
            .filter((item, index) => item.Role == 5)
            .map((agent) => agent.userDetails);
          setAgentList(agents);
        }
      })
      .catch(() => { });
  };
  useEffect(() => {
 
    getAllUsers();
  }, []);


  const validationMessage = {
    required: " ",
  };

  const updateIsADult = (val) => {
    // val=val.target.value

    if (val > 0) {
      setIsAdult(true);
    } else {
      setIsAdult(false);
    }
  };


  const updateIsChild = (val) => {
    if (val > 0) {
      setIsChild(true);
    } else {
      setIsChild(false);
    }
  };



  const TicketDetails = ({ isminus, field, add, index, remove }) => {
    const origin = useRef();
    const destination = useRef();
    const dateBox = useRef();
    const fieldsData = [];
    const [showDate, setShowDate] = useState(false);
    const handleOnSubmit = (ref) => {
      ref.current.focus();
      if (ref === dateBox) {
        setShowDate(true);
      }
    };
    return (
      <div>
      
        <Row gutter={16}>
          <Col className="gutter-class" md={5} xs={5}>
            <Form.Item label="Bar Code Number" 
              {...field}
              name={[field.name, "BarCode"]}
              fieldKey={[field.fieldKey, "BarCode"]}
             
            rules={[{ required: false }]}>
              <Input placeholder="Bar Code Number" />
            </Form.Item>
          </Col>
          {/* {console.log(field)} */}

          <Col className="gutter-class" md={5} xs={5}>
            <Form.Item
              label="Packeges Types"
              {...field}
              name={[field.name, "paxType"]}
              fieldKey={[field.fieldKey, "paxType"]}
              rules={[{ required: true }]}
            >
              <Select onChange={updateIsADult}>
                <Option value="Adult">Adult</Option>
                <Option value="Child">Child</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col className="gutter-class" md={6} xs={6}>
            <Form.Item
              label="Adults"
             {...field}
              name={[field.name, "NoOfAdult"]}
              fieldKey={[field.fieldKey, "NoOfAdult"]}
              rules={[{ required: true }]}
            >
              <Select onChange={updateIsADult}>
                <Option value="0">0</Option>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col className="gutter-class" md={6} xs={6}>
            <Form.Item
              label="Children"
              
              {...field}
              name={[field.name, "NoOfChild"]}
              fieldKey={[field.fieldKey, "NoOfChild"]}
              rules={[{ required: true }]}
            >
              <Select onChange={updateIsChild}>
                <Option value="0">0</Option>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={1} style={{ alignSelf: "center" }}>
            { isminus > 1 ?  (
              <MinusCircle
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            ):null }
          </Col>
        </Row>
      </div>
    );
  };

  const onFormSubmit = (formData) => {
    let requestData = formData;
    ApiClient.post("admin/booking/activityoffline", requestData).then((res) => {
      if (res.statusCode == 200) {
        UpdateTicketVourcherStatus(formData.SYS_RefNo)
        // message.success("Added SuccessFully");
      } else {
        message.error("Something went wrong");
      }
    });

    const UpdateTicketVourcherStatus = (refNo) => {
      ApiClient.post("activities/tours/updatevourcherstatus", {refNo:refNo})
        .then((result) => result)
        .then((resp) => {
          if (resp.status == 200) {
            message.success("Successfully updated")
          } else {
            message.error("Error updating status: " + resp.status);
          }
        })
        .catch((error) => { });
    };
    // HERE f is form data

    // let requestData = SeprateRequestCreator(formData, tripype);

    // if (params.mode != "edit") {
    //   ApiClient.post("admin/booking/offline", requestData).then((res) => {
    //     if (res.statusCode == 200) {
    //       message.success("Added SuccessFully");
    //     } else {
    //       message.error("Error addding Data");
    //     }
    //   });
    // } else {
    //   ApiClient.put(
    //     "admin/booking/offline/" + params.ref,
    //     {},
    //     requestData
    //   ).then((res) => {
    //     if (res.statusCode == 200) {
    //       message.success("Flight Details Updated Successfully", 3);
    //     } else if (res.statusCode == 400) {
    //       message.error(" Unable to update the Flight Details", 3);
    //     } else {
    //       message.error(" Error Uploading Data", 3);
    //     }
    //   });
    // }
  };
  const valdationFailed = () => {
    message.error("Please Check All Fields");
  };
  const onBookingTypeChange = (e) => {
    setBookigFromType(e);
  };

  const BookingLabelTitle = () => (
    <>
      Booking From {"  "}
      {/* <HelpInfoHelper screenName={"/admin/bookoffline"} /> */}
    </>
  );
  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <Form
                initialValues={{ ...ticketData }}
                layout="vertical"
                form={form}
                onFinish={onFormSubmit}
                validateMessages={validationMessage}
                onFinishFailed={valdationFailed}
              >
           
                <div className="card-gap">
                  <div className="flight-depart-details">
                    <h4>Ticket Details</h4>
                    <Card title="Ticket Details" bordered={false}>
                      <div className="">
                        <Row gutter="16">

                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item label="SYT-Refrence No" name="SYS_RefNo"
                              rules={[{ required: true }]}>
                              <Input placeholder="SYT-Refrence No" />
                            </Form.Item>
                          </Col>

                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item label="Api-Reference No" name="BookingRef" rules={[{ required: true }]}>
                              <Input placeholder="Api-Reference No" />
                            </Form.Item>
                          </Col>

                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item label="Slot" name="slot" rules={[{ required: false }]}>
                              <Input placeholder="Slot" />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item label="PNR Number" name="PNRNo" rules={[{ required: false }]}>
                              <Input placeholder="PNR Number" />
                            </Form.Item>
                          </Col>
                         
                        </Row>
                      </div>

                      {/* Add And Remove Return */}
                      <div className="flight-details">
                        <Form.List name="ticketDetails">
                          {(fields, { add, remove }) => {
                            return (
                              <>
                                {fields.map((field, index) => {
                                  return (
                                    <TicketDetails
                                      isminus={fields.length}
                                      field={field}
                                      index={index}
                                      key={field.key}
                                      remove={remove}
                                    />
                                  );
                                })}
                                {fields.length < 50 ? (
                                  <Button
                                    type="primary"
                                    id="add_onward_flight_details"
                                    onClick={() => add()}
                                  >
                                    Add Ticket Details
                                  </Button>
                                ) : null}
                              </>
                            );
                          }}
                        </Form.List>
                      </div>
                    </Card>
                   
                    <Card>
                      <Row gutter={16}>
                        <Col className="gutter-class" md={24} xs={24}>
                          <Form.Item className="bookoffline-submit">
                            <Button htmlType="submit">Submit</Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Content>
      </div>
    </div>
  );
};
export default CreateTicket;

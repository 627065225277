import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

import hotelImg from "../../../assets/images/hotels/hotelsmall.png";
import NohotelImg from "../../../assets/images/hotels/no_img.png";
import LogoImg from "../../../assets/images/logos/trip-logo.png";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: 20,
  },
  section: {
    padding: "0 10px",
  },
  innerSection: {
    position: "relative",
    paddingLeft: 30,
  },
  borderleft: {
    position: "absolute",
    width: 3,
    height: "100%",
    backgroundColor: "#e9ecef",
  },
  heading: {
    fontSize: 14,
    marginBottom: 10,
    color: "#274581",
  },
  title: {
    fontSize: 12,
    marginBottom: 5,
    color: "#274581",
  },
  subtitle: {
    fontSize: 10,
    marginBottom: 5,
  },
  stitle: {
    fontSize: 10,
  },
  textDanger: {
    color: "#dc3545",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    flexWrap: "wrap",
  },

  wHalf: {
    width: "50%",
    marginBottom: 5,
  },
  card: {
    position: "relative",
    padding: 10,
    border: "1px solid #e9ecef",
    borderRadius: 2,
  },
  hotelimgWrapper: {
    width: 100,
  },
  hotelLogo: {
    width: 10,
  },
  tourImg: {
    width: 100,
  },
  mb10: {
    marginBottom: 10,
  },
  contentWrapper: {
    position: "relative",
    marginBottom: 10,
  },

  typeTitle: {
    backgroundColor: "#1e4167",
    width: 40,
    padding: "4px 5px",
    fontSize: 12,
    textAlign: "left",
    borderRadius: 4,
    color: "#fff",
    marginLeft: -50,
    marginBottom: 10,
  },

  timelineBadge: {
    position: "absolute",
    top: 9,
    left: -46,
    zIndex: 10,
    width: 35,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: 9,
    color: "#fff",
    backgroundColor: "#ffbc34",
  },
  description: { width: 350, fontSize: 10 },
  hotelsPackageDetails: { width: 350, paddingLeft: 10 },
  addressDetails: {
    textAlign: "right",
    fontSize: 10,
    paddingBottom: 5,
  },
  flex5: {
    flex: 0.49,
  },
  logoSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  textRight: {
    textAlign: "cen",
  },
  alignSelf: {
    alignSelf: "flex-end",
  },
  justifyContentBetween: {
    justifyContent: "space-between",
  },
});

const Description = ({ tourShortDescription }) => {
  let returnContentConst =<Text style={{ display: "inline" }}>{''}</Text>;;
  if (tourShortDescription) {
    const parsedHtml = ReactHtmlParser(tourShortDescription);
    parsedHtml.forEach((element) => {
      if (element?.props && element?.props?.children) {
        element.props.children.forEach((content) => {
          returnContentConst = (
            <Text style={{ display: "inline" }}>{content}</Text>
          );
        });
      } else {
        if (element)
          returnContentConst = (
            <Text style={{ display: "inline" }}>{element}</Text>
          );
      }
    });
    return returnContentConst;
  } else {
    return returnContentConst;
  }
};

const HotelImg = ({ img }) => {
  if (img) {
    return (
      <View style={styles.hotelimgWrapper}>
        <Image style={{ width: "100%" }} src={img} />
      </View>
    );
  } else {
    return (
      <View style={styles.hotelimgWrapper}>
        <Image style={{ width: "100%" }} src={NohotelImg} />
      </View>
    );
  }
};

const getHotelPaxCount = (roomsArray) => {
  if (roomsArray.length > 0) {
    let pax = roomsArray[0];
    return `Adult(s) ${pax?.AdultCount !== "0" ? pax?.AdultCount : 0} ${
      pax?.ChildCount !== "0" ? ", Child(s) " + pax?.ChildCount : " "
    } `;
  }
};

const getPaxCount = (pax) => {
  return `Adult(s) ${pax?.adult !== "0" ? pax?.adult : 0} ${
    pax?.child !== "0" ? ", Child(s) " + pax?.child : " "
  } ${pax?.infant !== "0" ? ", Infant(s) " + pax?.infant : " "}`;
};
const BASE = process.env.REACT_APP_BASE_URL;
const BuildPackageDoc = ({
  ticketData,
  agent,
  showPrice = true,
  withFare,
  Logo,
}) => {
  const getStatus = (status) => {
    switch (status) {
      case 2: {
        return <Text style={{ color: "#FFA500" }}>BLOCKED </Text>;
      }

      case 3: {
        return <Text style={{ color: "#008000" }}>CONFIRMED</Text>;
      }

      case 1: {
        return <Text style={{ color: "#FF0000" }}>ALREADY CANCELLED</Text>;
      }
      case 0: {
        return <Text style={{ color: "#FF0000" }}>CANCELLED</Text>;
      }
      case 11: {
        return <Text style={{ color: "#FF0000" }}>VOURCHER</Text>;
      }
      default:
        return;
    }
  };

  return (
    <Document>
      {ticketData && Object.keys(ticketData).length > 0 ? (
        <>
          {ticketData.hotelsPackageDetails?.length ? (
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <View style={styles.logoSection}>
                  <View style={{ ...styles.flex5 }}>
                    {Logo ? (
                      <Image
                        src={BASE + Logo.substring(1)}
                        style={{ height: 50, width: 100, marginBottom: 10 }}
                      ></Image>
                    ) : (
                      <Image style={{ width: 100, heigh: 50 }} src={LogoImg} />
                    )}
                  </View>
                  <View
                    style={{
                      ...styles.flex5,
                      ...styles.addressDetails,
                    }}
                  >
                    {ticketData?.agentDetails ? (
                      <View
                        style={{
                          ...styles.textRight,
                          ...styles.justifyContentBetween,
                        }}
                      >
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.agentDetails?.AgencyName}{" "}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.agentDetails?.Address1}{" "}
                          {ticketData?.agentDetails?.Address2}
                        </Text>
                        {ticketData?.agentDetails?.City ||
                        ticketData?.agentDetails?.State ? (
                          <Text style={{ ...styles.alignSelf }}>
                            {ticketData?.agentDetails?.City}{" "}
                            {ticketData?.agentDetails?.State}
                          </Text>
                        ) : null}

                        <Text style={{ ...styles.alignSelf }}>
                          Email: {ticketData?.agentDetails?.Email}
                        </Text>
                        {ticketData?.agentDetails?.Phone ? (
                          <Text style={{ ...styles.alignSelf }}>
                            Contact No.: {ticketData?.agentDetails?.Phone}
                          </Text>
                        ) : null}
                      </View>
                    ) : ticketData?.adminDetails?.length > 0 ? (
                      <View
                        style={{
                          ...styles.textRight,
                          ...styles.justifyContentBetween,
                        }}
                      >
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.Address}{" "}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.CityName}{" "}
                          {ticketData?.adminDetails[0]?.StateName}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.CountryName}{" "}
                          {ticketData?.adminDetails[0]?.PostalCode}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>

                <Text style={styles.heading}> INCLUSIONS DETAILS - HOTEL </Text>
                <View style={styles.innerSection}>
                  <View style={styles.borderleft}></View>

                  {ticketData.hotelsPackageDetails?.length > 0 ? (
                    <Text style={styles.typeTitle}>Hotel</Text>
                  ) : null}
                  {ticketData.hotelsPackageDetails.length > 0
                    ? ticketData.hotelsPackageDetails.map((hotel, index) => {
                        return (
                          <View
                            key={index + "hotel"}
                            style={styles.contentWrapper}
                          >
                            <View style={styles.timelineBadge}>
                              {hotel?.hotelImage ? (
                                <Image
                                  src={hotel?.hotelImage}
                                  source={{
                                    header: {
                                      "Access-Control-Allow-Origin": "*",
                                    },
                                  }}
                                />
                              ) : (
                                <Image
                                  style={styles.hotelLogo}
                                  src={hotelImg}
                                />
                              )}
                            </View>

                            <View key={index} style={styles.card}>
                              <View
                                style={{
                                  ...styles.row,
                                }}
                              >
                                <HotelImg img={hotel.hotelImage} />
                                <View style={styles.hotelsPackageDetails}>
                                  <Text style={styles.title}>
                                    {hotel.HotelName}
                                  </Text>
                                  <View style={styles.row}>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      Check in Date :
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      {moment(hotel.CheckInDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      Check Out Date :
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      {moment(hotel.CheckOutDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      Room Type :
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      {hotel.Rooms[0].RoomName}
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      No. of Pax :
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      {getHotelPaxCount(hotel.Rooms)}
                                    </Text>

                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      No. of Nights :
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      {hotel.Rooms[0].noOfNight}
                                    </Text>
                                    {withFare ? (
                                      <>
                                        {showPrice && (
                                          <Text
                                            style={{
                                              ...styles.stitle,
                                              ...styles.wHalf,
                                            }}
                                          >
                                            Amount :
                                          </Text>
                                        )}
                                        {showPrice && (
                                          <Text
                                            style={{
                                              ...styles.stitle,
                                              ...styles.wHalf,
                                            }}
                                          >
                                            INR {hotel.Rooms[0].pricePerRoom}
                                          </Text>
                                        )}
                                      </>
                                    ) : null}
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      })
                    : null}
                </View>
              </View>
            </Page>
          ) : null}

          {ticketData?.tourDetails?.length ? (
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Text style={styles.heading}> DAY WISE ITINERARY - TOUR</Text>
                <View style={styles.innerSection}>
                  <View style={styles.borderleft}></View>
                  {ticketData?.tourDetails?.length > 0 ? (
                    <Text style={styles.typeTitle}>Tours</Text>
                  ) : null}

                  {ticketData?.tourDetails?.length > 0
                    ? ticketData?.tourDetails.map(
                        (tourList, index) =>
                          tourList &&
                          tourList?.length > 0 &&
                          tourList.map((tour) => {
                            return (
                              <View key={index} style={styles.contentWrapper}>
                                <View style={styles.timelineBadge}>
                                  <Text>Day {index + 1}</Text>
                                </View>

                                <View key={index} style={styles.card}>
                                  <Text style={styles.title}>
                                    {tour.tourName}
                                  </Text>

                                  <Text style={styles.subtitle}>
                                    Tour Date :{" "}
                                    {moment(tour.tourDate, "YYYY-MM-DD").format(
                                      "DD-MM-YYYY"
                                    )}
                                  </Text>

                                  <Text style={styles.subtitle}>
                                    Booking Status :{" "}
                                    {getStatus(tour?.BookingStatus)}
                                  </Text>

                                  <Text style={styles.subtitle}>
                                    Booking Reference No : {tour?.BookingRefNo}
                                  </Text>

                                  {!agent ? (
                                    <Text style={styles.subtitle}>
                                      Provider Reference No :{" "}
                                      {tour?.ProviderRefNo}
                                    </Text>
                                  ) : null}

                                  <View style={styles.row}>
                                    <Image
                                      style={styles.tourImg}
                                      src={tour.tourImage}
                                    />
                                    <View style={styles.description}>
                                      <Description
                                        tourShortDescription={
                                          tour.tourShortDescription
                                        }
                                      />
                                    </View>
                                  </View>

                                  <View style={styles.row}>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      Tour Option :
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      {tour.optionName}
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      Transfer Option :
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      {tour.transferName}
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      Duration :
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      {tour.duration}
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      Start Time :
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      {tour.startTime}
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      No. of Pax :
                                    </Text>
                                    <Text
                                      style={{
                                        ...styles.stitle,
                                        ...styles.wHalf,
                                      }}
                                    >
                                      {getPaxCount(tour)}
                                    </Text>
                                    {withFare ? (
                                      <>
                                        {showPrice && (
                                          <Text
                                            style={{
                                              ...styles.stitle,
                                              ...styles.wHalf,
                                            }}
                                          >
                                            Amount :
                                          </Text>
                                        )}
                                        {showPrice && (
                                          <Text
                                            style={{
                                              ...styles.stitle,
                                              ...styles.wHalf,
                                            }}
                                          >
                                            INR {tour.serviceTotal}
                                          </Text>
                                        )}
                                      </>
                                    ) : null}
                                  </View>
                                </View>
                              </View>
                            );
                          })
                      )
                    : null}
                </View>
              </View>
            </Page>
          ) : null}
        </>
      ) : null}
    </Document>
  );
};

export default BuildPackageDoc;

import React from "react";
import { Route, Switch } from "react-router-dom";

import AgentProtectedRoute from "../../helpers/AgentProtectedRoute";

import BusSearch from "./../../pages/Buses/BusSearch/BusSearch";
import BusResults from "./../../pages/Buses/BusResults/BusResults";
import BusCheckout from "./../../pages/Buses/Checkout/BusCheckout";
import BusReview from "../../pages/Buses/Review/BusReview";
// import BusTicketDetails from "../../pages/Buses/TicketDetails/BusTicketDetails";
import BusInvoice from "../../pages/Buses/Invoice/BusInvoice";
import HotelSearch from "./../../pages/Hotels/HotelSearch/HotelSearch";
import HotelResults from "./../../pages/Hotels/HotelResults/HotelResults";
import HotelDet from "./../../pages/Hotels/HotelDet/HotelDet";
import HotelCheckout from "./../../pages/Hotels/HotelCheckout/HotelCheckout";
import HotelPreview from "./../../pages/Hotels/HotelPreview/HotelPreview";
import HotelInvoice from "./../../pages/Hotels/HotelInvoice/HotelInvoice";
import Map from "./../../components/Map/Map";
import UserRegistration from "./../../components/UserRegistration/UserRegistration";
import Wallet from "../../components/Wallet/Wallet";
import TransactionReports from "../../components/TransactionReports/TransactionReports";
import AgentRegistration from "../../components/AgentRegistration/AgentRegistration";
import Login from "./../../components/Login/Login";

import FlightCheckout from "./../../components/FlightsCheckout/FlightsCheckout";
import SearchResults from "./../../components/SearchResults/SearchResults";
import Home from "./../../components/home/Home";
import FlightReview from "./../../components/Flights-Review/Flights-Review";
// import FlightInvoice from "../../components/Flights-Invoice/FlightsInvoice";

// import Invoice from "../../admin/Invoice/Invoice";
import CheckInformation from "../../components/CheckInformation/CheckInformation";
// import FlightTicket from "../../admin/FlightTicket/FlightTicket";
import Deals from "../../components/Deals/Deals";

import DealsDetails from "../../components/home/DealsDetails/DealsDetails";
import Offers from "../../components/Offers/Offers";
import Error404 from "../../components/ErrorPages/Error404";

import TravellerDetails from "../../components/TravellerDetails/TravellerDetails";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import MyPackage from "../../components/Myquotations/MyQuotation";
import HotelBookingList from "../../pages/Hotels/HotelBookingsList/HotelBookingsList";

import TicketCancel from "../../common/TicketCancel/TicketCancel";
import PrintTicket from "../../common/PrintTicket/PrintTicket";
import OurTeam from "../../components/OurTeam/OurTeam";
import Testimonials from "../../components/Testimonials/Testimonials";
import Faqs from "../../components/Faqs/Faqs";
import Termsofconditions from "../../components/Termsofconditions/Termsofconditions";
import Privacypolicy from "../../components/Privacypolicy/Privacypolicy";
import Disclaimer from "../../components/Disclaimer/Disclaimer";
import Careers from "../../components/Careers/Careers";
import Socialresponsibility from "../../components/Socialresponsibility/Socialresponsibility";
import Checkflightinfo from "../../components/Checkflightinfo/Checkflightinfo";
import Refundpolicy from "../../components/Refundpolicy/Refundpolicy";
import Investorrelations from "../../components/Investorrelations/Investorrelations";
import CombinedBooking from "../../components/CombinedBooking/CombinedBooking";
import Profile from "../../components/Profile/Profile";
import Bookingreports from "../../components/Bookingreports/Bookingreports";
import PgrequestTest from "../../PgRequestTest";
import ContactUsForm from "../../components/ContactUsForm/ContactUsForm";

import FlightEnquiry from "../../components/FlightEnquiry/FlightEnquiry";
import PaymentFailure from "../../admin/PaymentFailure/PaymentFailure";
import AboutUS from "../../components/AboutUS/AboutUs";
import Mytrips from "../../components/Mytrips/Mytrips";

import Unauthorized from "../../components/ErrorPages/Unauthorized";
import NotFound from "../../components/ErrorPages/NotFound";
import ProtectedRoute from "../../helpers/RouteProtection";
import ErrorScreen from "../../helpers/ErrorScreen";
import PaymentSucces from "../../admin/PaymentSuccess/PaymentSuccess";
import Blog from "../../components/Blog/Blog";
import Bloginfo from "../../components/Bloginfo/Bloginfo";
import Activities from "../../components/Activities/Activities";
import BuildPackage from "../../components/BuildYourPackage/Buildpackage";
import HolidaysSearch from "../../pages/Holidays/HolidaysSearch/HolidaysSearch";
import Holidaysresults from "../../pages/Holidays/HolidaysResults/HolidaysResults";
import Holidaysnearplaces from "../../pages/Holidays/Holidaysnearplaces/Holidaysnearplaces";
import Holidaystotal from "../../pages/Holidays/Holidaystotal/Holidaystotal";
import Holidayscart from "../../pages/Holidays/Holidayscart/Holidayscart";
import Holidaysshoppingcart from "../../pages/Holidays/Holidaysshoppingcart/Holidaysshoppingcart";
import ActivitiesSearch from "../../pages/Activitespkg/Activitiessearch/Activitiessearch";
import Activiesresults from "../../pages/Activitespkg/Activitiesresults/Activitiesresults";
import ActivitiesTourDetail from "../../pages/Activitespkg/ActivitiesTourDetail/ActivitiesTourDetail";
import Activitiescheckout from "../../pages/Activitespkg/Activitiescheckout/Activitiescheckout";
import ActivitiesCart from "../../pages/Activitespkg/ActivitiesCart/ActivitiesCart";
import Combinedbuildpkg from "../../components/Combinedbuildpkg/Combinedbuildpkg";
import Buildquotationtotal from "../../pages/Activitespkg/Buildquotationtotal/Buildquotationtotal";
import Quotationcheckout from "../../pages/Activitespkg/Quotationcheckout/Quotationcheckout";

import MyProfile from "../../components/MyProfile/MyProfile";
import Storevistors from "../../components/Storevistors/Storevistors";
import BankDetails from "../../components/BankDetails/BankDetails";
import Deposits from "../../components/Deposits/Deposits";
import AgentLogo from "../../components/AgentLogo/AgentLogo";
import Agentmarkup from "../../components/Agentmarkup/Agentmarkup";
import Commissiondetails from "../../components/Commissiondetails/Commissiondetails";
import UserActivitiesTicket from "../../components/Bookingreports/UserTickets/UserActivitiesTicket";
import UserBuildPackageTicket from "../../components/Bookingreports/UserTickets/UserBuildPackageTicket";
import UserBusTicket from "../../components/Bookingreports/UserTickets/UserBusTicket";
import UserFlightTicket from "../../components/Bookingreports/UserTickets/UserFlightTicket";
import UserHotelTicket from "../../components/Bookingreports/UserTickets/UserHotelTicket.jsx";
import ActivitiesReview from "../../pages/Activitespkg/ActivitiesTourDetail/ActivitiesReview";
import testingActivities from "../../pages/Activitespkg/Activitiessearch/testingActivities";
import Sastaeurope from "../../pages/Sastaeurope/Sastaeurpoe";
import Travelitinerary from "../../components/Travelitinerary/Travelitinerary";
import CouponWallet from "../../components/CouponWallet/CouponWallet";
import TransportSearch from "../../pages/Transport/TransportSearch/TransportSearch";
import TransportResults from "../../pages/Transport/TransportResults/TransportResults";
import TransportCheckout from "../../pages/Transport/TransportCheckout/TransportCheckout";
import VisaSearch from "../../pages/Visa/Visasearch/Visasearch";
import Dubaivisa from "../../pages/Visa/Dubaivisa/Dubaivisa";
import Travellersvisadetails from "../../pages/Visa/Travellersvisadetails/Travellersvisadetails";
import Agenthomepage from "../../components/Agenthomepage/Agenthomepage";
import Statement from "../../components/statement/Statement";
import Webcheckin from "../../components/Webcheckin/Webcheckin";
import Discounts from "../../components/Discounts/Discounts";
import Holidaypackages from "../../components/Holidaypackages/Holidaypackages";
import BusHire from "../../components/BusHire/BusHire";
import Forex from "../../components/Forex/Forex";
import CarHire from "../../components/CarHire/CarHire";
import Thankyou from "../../components/Thankyou/Thankyou";
import Visa from "../../components/Visa/Visa";
import Insurance from "../../components/Insurance/Insurance";

const NavigationItems = () => {
  return (
    <div className="mob-M-Top">
      <Switch>
        <Route exact path={"/"} component={Home} />
        <Route exact path={"/flight/results"} component={SearchResults} />

        <AgentProtectedRoute
          routeKey="2"
          exact
          path={"/hotels"}
          component={HotelSearch}
        />
        <Route exact path={"/hotels/listing"} component={HotelResults} />

        <Route exact path={"/hotel-location"} component={Map} />

        <Route exact path={"/hotels/hotel-details"} component={HotelDet} />
        <Route
          exact
          path={"/hotels/hotel-checkout"}
          component={HotelCheckout}
        />
        <Route exact path={"/hotels/hotel-review"} component={HotelPreview} />
        <Route exact path={"/hotel-invoice"} component={HotelInvoice} />
        <Route exact path={"/hotel/ticket"} component={UserHotelTicket} />
        <Route exact path={"/agenthomepage"} component={Agenthomepage} />
        <Route exact path={"/transportsearch"} component={TransportSearch} />
        <Route exact path={"/transportresults"} component={TransportResults} />
        <Route
          exact
          path={"/transportcheckout"}
          component={TransportCheckout}
        />
        <Route exact path={"/visasearch"} component={VisaSearch} />
        <Route exact path={"/dubaivisa"} component={Dubaivisa} />
        <Route
          exact
          path={"/travellersvisadetails"}
          component={Travellersvisadetails}
        />

        <AgentProtectedRoute
          routeKey="3"
          exact
          path={"/bus"}
          component={BusSearch}
        />
        <Route exact path={"/bus/results"} component={BusResults} />
        <Route exact path={"/bus/checkout"} component={BusCheckout} />
        <Route exact path={"/bus/review"} component={BusReview} />
        <Route exact path={"/bus-invoice"} component={BusInvoice} />
        <Route exact path={"/bus/ticket"} component={UserBusTicket} />
        <Route exact path={"/user-registration"} component={UserRegistration} />
        <Route
          exact
          path={"/agent-registration"}
          component={AgentRegistration}
        />
        <Route exact path={"/login"} component={Login} />
        <Route exact path={"/flight/checkout"} component={FlightCheckout} />
        <Route exact path={"/flight/review"} component={FlightReview} />
        <Route exact path={"/blog"} component={Blog} />
        <Route exact path={"/bloginfo/:id/:title"} component={Bloginfo} />
        <Route exact path={"/checkInfo"} component={CheckInformation} />
        <Route exact path={"/deals"} component={Deals} />
        <Route exact path={"/deals-details"} component={DealsDetails} />
        <Route exact path={"/ticketcancel"} component={TicketCancel} />
        <Route exact path={"/printticket"} component={PrintTicket} />
        <Route exact path={"/ourteam"} component={OurTeam} />
        <ProtectedRoute
          exact
          path={"/store-vistors"}
          component={Storevistors}
        />
        <ProtectedRoute exact path={"/myprofile"} component={MyProfile} />
        <ProtectedRoute exact path={"/statements"} component={Statement} />
        <ProtectedRoute exact path={"/discounts"} component={Discounts} />
        <ProtectedRoute exact path={"/bank-details"} component={BankDetails} />
        <Route path={"/Travelitinerary"} component={Travelitinerary} />
        <ProtectedRoute path={"/deposits"} component={Deposits} />
        <Route path={"/agent-logo"} component={AgentLogo} />
        <ProtectedRoute path={"/agent-markup"} component={Agentmarkup} />
        <ProtectedRoute
          path={"/commission-details"}
          component={Commissiondetails}
        />
        <ProtectedRoute
          exact
          path={"/booking-reports"}
          component={Bookingreports}
        />
        <Route exact path={"/testimonials"} component={Testimonials} />
        <Route exact path={"/faqs"} component={Faqs} />
        <Route exact path={"/mytrips"} component={Mytrips} />
        <Route
          exact
          path={"/termsofconditions"}
          component={Termsofconditions}
        />
        <Route exact path={"/privacypolicy"} component={Privacypolicy} />
        <Route exact path={"/disclaimer"} component={Disclaimer} />
        <Route exact path={"/careers"} component={Careers} />
        <Route exact path={"/Holidaypackages"} component={Holidaypackages} />
        <Route
          exact
          path={"/Socialresponsibility"}
          component={Socialresponsibility}
        />
        <Route exact path={"/checkflightinfo"} component={Checkflightinfo} />
        <Route exact path={"/Refundpolicy"} component={Refundpolicy} />
        <Route exact path={"/Thankyou"} component={Thankyou} />
        <Route
          exact
          path={"/Investorrelations"}
          component={Investorrelations}
        />
        <Route exact path={"/offers"} component={Offers} />
        <ProtectedRoute
          exact
          path={"/traveller-details"}
          component={TravellerDetails}
        />
        <ProtectedRoute
          exact
          path={"/change-password"}
          component={ChangePassword}
        />
        <Route exact path={"/booking-list"} component={HotelBookingList} />
        <Route exact path={"/combinedbooking"} component={CombinedBooking} />

        <ProtectedRoute exact path={"/profile"} component={Profile} />

        <Route exact path={"/404"} component={Error404} />
        <Route exact path={"/pg_test"} component={PgrequestTest} />
        <Route exact path={"/flight/ticket"} component={UserFlightTicket} />
        <Route
          exact
          path={"/activities/ticket"}
          component={UserActivitiesTicket}
        />
        <Route
          exact
          path={"/buildpackage/ticket"}
          component={UserBuildPackageTicket}
        />

        <Route
          exact
          path={"/testingActivities"}
          component={testingActivities}
        />
        <ProtectedRoute exact path={"/mypackage"} component={MyPackage} />
        <ProtectedRoute
          exact
          path={"/coupon-wallet"}
          component={CouponWallet}
        />
        <ProtectedRoute
          exact
          path={"/transaction-reports"}
          component={TransactionReports}
        />
        <ProtectedRoute exact path={"/wallet"} component={Wallet} />
        <Route exact path={"/contact_us_form"} component={ContactUsForm} />
        <Route exact path={"/flightenquiry"} component={FlightEnquiry} />
        <Route exact path={"/paymentFailure"} component={PaymentFailure} />
        <Route exact path={"/pg_failure"} component={PaymentFailure} />
        <Route exact path={"/pg_success"} component={PaymentSucces} />
        <Route exact path={"/about-us"} component={AboutUS} />
        <Route exact path={"/holidays"} component={HolidaysSearch} />
        <AgentProtectedRoute
          routeKey="4"
          exact
          path={"/activities"}
          component={ActivitiesSearch}
        />

        <AgentProtectedRoute
          routeKey="6"
          exact
          path={"/sastaeurope"}
          component={Sastaeurope}
        />
        <Route exact path={"/activities/results"} component={Activiesresults} />
        <Route
          exact
          path={"/activities/tourDetail"}
          component={ActivitiesTourDetail}
        />
        <Route
          exact
          path={"/activities/checkout"}
          component={Activitiescheckout}
        />

        <Route
          exact
          path={"/buildquotationtotal"}
          component={Buildquotationtotal}
        />
        <Route
          exact
          path={"/Quotationcheckout"}
          component={Quotationcheckout}
        />
        <Route exact path={"/activities/cart"} component={ActivitiesCart} />

        <Route exact path={"/combinedbuildpkg"} component={Combinedbuildpkg} />
        <Route exact path={"/holidaysresults"} component={Holidaysresults} />
        <Route
          exact
          path={"/holidaysnearplaces"}
          component={Holidaysnearplaces}
        />
        <Route exact path={"/holidaystotal"} component={Holidaystotal} />
        <Route exact path={"/holidayscart"} component={Holidayscart} />

        <Route
          exact
          path={"/holidaysshoppingcart"}
          component={Holidaysshoppingcart}
        />
        <Route exact path={"/activities"} component={Activities} />
        <AgentProtectedRoute
          routeKey="5"
          exact
          path={"/buildyourpackage"}
          component={BuildPackage}
        />
        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route exact path="/ActivitiesReview" component={ActivitiesReview} />
        <Route exact path={"/web-check-in"} component={Webcheckin} />
        <Route exact path={"/bus-hire"} component={BusHire} />
        <Route exact path={"/forex"} component={Forex} />
        <Route exact path={"/car-hire"} component={CarHire} />
        <Route exact path={"/visa"} component={Visa} />
        <Route exact path={"/insurance"} component={Insurance} />
        <Route exact path="/error" component={ErrorScreen} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};
export default NavigationItems;

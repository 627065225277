import React, { useState, useRef, useEffect } from 'react'
import { Radio, Input, Select, Checkbox } from "antd";
import { CaretUpOutlined, CaretDownOutlined, FilterFilled } from "@ant-design/icons";
import { Card } from 'react-bootstrap';

const RoomSort = ({ roomsDetails, setRoomsDetails,CopyRoomsDetails }) => {
    const [sorting, setSorting] = useState("price");
    const [sortDirection, setSortDirection] = useState("asc");

    let mealTypesObj = [
        {
            value: 'Breakfast',
            label: 'Breakfast',
        },
        {
            value: 'Half Board',
            label: 'Half Board',
        },
        {
            value: 'Full Board',
            label: 'Full Board',
        },
    ]

    useEffect(() => {
        sortRoom("price", "asc");
    }, []);

    const handleChange = (e) => {
        const selSorting = e.target.value;
        setSorting(selSorting);
        setSortDirection("asc");
        sortRoom(selSorting, "asc");
    };

    const sortRoom = (selSorting, dir) => {
        let newlist = roomsDetails.roomList.sort((p1, p2) => {
            if (selSorting === "price") {
                return dir === "asc"
                    ? compareRoom(
                        Math.round(p1[0]?.ratePlans[0]?.price?.total),
                        Math.round(p2[0]?.ratePlans[0]?.price?.total),
                        selSorting
                    )
                    : compareRoom(
                        Math.round(p2[0]?.ratePlans[0]?.price?.total),
                        Math.round(p1[0]?.ratePlans[0]?.price?.total),
                        selSorting
                    );
            }
        });
        setRoomsDetails({ roomList: [...newlist] });
    };

    const compareRoom = (a, b, selSorting) => {
        if (selSorting === "name" || selSorting === "price") {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        } else {
            return a - b;
        }
    };

    const handleClick = (e) => {
        const selSorting = e.target.value;
        if (sorting === selSorting) {
            let dir = sortDirection === "asc" ? "desc" : "asc";
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
            sortRoom(selSorting, dir);
        }
    };

    const getSortIcon = (val) => {
        return val === "asc" ? <CaretUpOutlined /> : <CaretDownOutlined />;
    };
    
    const onChange = (value: string) => {
        console.log("onChange", value)
    };

    const FreeCancellationFilter = (e) => {
        if(e.target.checked !== false) {
            if(roomsDetails?.roomList.length > 0){
                roomsDetails.roomList.map((room, index) => {
                    let penaltyAmount = room[0].ratePlans[0].cancellationPolicy[0].penaltyAmount
                    if(!penaltyAmount > 0 && penaltyAmount){
                        let arr = roomsDetails.roomList[index];
                        setRoomsDetails({roomList : arr})
                    }else{
                        setRoomsDetails({roomList : []});
                    }
                })
            }
        }else{
            setRoomsDetails({roomList : CopyRoomsDetails});
        }
    };

    return (
        <Card className='mb-3'>
            <div style={{ alignItems: "baseline" }} className="room-filter-card d-flex">

                <div className="room-search-button">
                    <Input placeholder="Search Room Type" />
                </div>

                <div className="filter-price-sort mx-3">
                    <Radio value={"price"} onClick={handleClick}>
                        <span className="checkmark">
                            <div className="active-background">
                                <p className="price-type">
                                    <span>
                                        {sorting === "price" ? getSortIcon(sortDirection) : ""}
                                    </span>
                                    Price
                                </p>
                            </div>
                        </span>
                    </Radio>
                </div>

                <div className="room-cancellation mx-3">
                    <Checkbox onChange={FreeCancellationFilter}><strong>Free cancellation</strong></Checkbox>
                </div>

                <div className="meals-types">
                    <Select
                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                        style={{ width: 200 }}
                        onChange={onChange}
                        // onSearch={onSearch}
                        // filterOption={filterOption}
                        options={mealTypesObj}
                    />
                </div>
            </div>
        </Card>
    )
};

export default RoomSort
import React, { createContext, useContext, useState } from "react";
import {
  useLocalStorage,
  useSessionStorage,
} from "../../../helpers/useStorage";

const ActivitiesContext = createContext();

export function useActivitiesContext() {
  return useContext(ActivitiesContext);
}

const ActivitiesProvider = (props) => {
  const [quotation, setQuotation] = useState({});
  const [tourPassangerData, setTourPassangerData] = useState([]);
  const [activitiesCart, setActivitiesCart] = useSessionStorage(
    "activitiesCart",
    {}
  );

  const [activitiesCheckOutData, setActivitiesCheckOutData] = useSessionStorage(
    "activitiesCheckOutData",
    {}
  );

  const [tourNameImage, setTourNameImage] = useLocalStorage(
    "tourNameImage",
    null
  );

  const [searchReqBuildActivities, setSearchReqBuildActivities] =
    useLocalStorage("searchReqBuildActivities", {});

  const [selectedBuildActivities, setSelectedBuildActivities] = useSessionStorage(
    "selectedBuildActivities",
    []
  );

  const [selectedBuildHotel, setSelectedBuildHotel] = useSessionStorage(
    "selectedBuildHotel",
    []
  );

  return (
    <ActivitiesContext.Provider
      value={{
        state: {
          tourNameImage,
          tourPassangerData,
          activitiesCart,
          activitiesCheckOutData,
          searchReqBuildActivities,
          selectedBuildActivities,
          selectedBuildHotel,
          quotation,
        },
        setActivitiesCart,
        setActivitiesCheckOutData,
        setTourNameImage,
        setTourPassangerData,
        setSearchReqBuildActivities,
        setSelectedBuildActivities,
        setSelectedBuildHotel,
        setQuotation,
      }}
    >
      {props.children}
    </ActivitiesContext.Provider>
  );
};

export default ActivitiesProvider;

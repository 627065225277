export function getHotelPrice(ticketData) {
  let baseAmount = 0;
  let taxAmount = 0;
  let convienenceFee = 0;
  let discount = 0;
  let RefundAmount = 0;
  let insuranceTotal = 0;
  let totalAmount = 0;
  let grandTotal = 0;
  let otherCharges = 0;

  let postMarkup = 0;
  if (ticketData?.postMarkup) {
    postMarkup = Number(ticketData?.postMarkup);
  }

  const checkin = new Date(ticketData.CheckInDate);
  const checkout = new Date(ticketData.CheckOutDate);
  const diffTime = Math.abs(checkout - checkin);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const noOfNights = Number(diffDays);
  const noOfRooms = Number(ticketData.NoOfRooms);

  if (ticketData.Rooms.length > 0) {
    let totalBaseFare = ticketData.Rooms.reduce(
      (acc, val) => acc + val.base,
      0
    );

    // baseAmount = Number(noOfRooms * Number(totalBaseFare) * noOfNights);
    baseAmount = Number(noOfRooms * Number(totalBaseFare));
    let totalTax = ticketData.Rooms.reduce((acc, val) => acc + val.tax, 0);
    taxAmount = Number(noOfRooms * Number(totalTax));
    let totalOtherCharges = ticketData.Rooms.reduce(
      (acc, val) => acc + val.otherCharges,
      0
    );

    otherCharges = Number(noOfRooms * Number(totalOtherCharges));

    if (
      ticketData?.insuranceRequired === 1 &&
      ticketData?.insuranceData?.serviceType === 2
    ) {
      let totalPax = ticketData.Rooms.reduce(
        (acc, cur) => acc + Number(cur.adultCount) + Number(cur.childCount),
        0
      );

      insuranceTotal = totalPax * Number(ticketData?.insuranceData.amount);
    }
  }

  baseAmount = Number(baseAmount) + postMarkup;

  totalAmount = Number(baseAmount) + Number(taxAmount) + Number(otherCharges);

  if (ticketData?.convienenceData?.amount) {
    if (ticketData.convienenceData.type === 1) {
      convienenceFee = Number(ticketData.convienenceData.amount);
    } else {
      convienenceFee = Number(
        (Number(totalAmount) / 100) *
          Number(
            ticketData.convienenceData.amount
              ? ticketData.convienenceData.amount
              : 0
          )
      );
    }
  }

  if (ticketData.PromoData && ticketData?.PromoData?.DiscountType) {
    if (ticketData.PromoData.DiscountType == 1) {
      discount = Number((totalAmount / 100) * ticketData.PromoData.Discount);
    } else {
      discount = Number(ticketData.PromoData.Discount);
    }
  }
  RefundAmount = ticketData.RefundAmount ?? 0;

  grandTotal = Number(
    baseAmount +
      taxAmount +
      otherCharges +
      Number(convienenceFee) +
      Number(insuranceTotal) -
      discount
  ).toFixed(2);

  return {
    baseAmount: Number(baseAmount).toFixed(2),
    taxAmount: Number(taxAmount).toFixed(2),
    otherCharges: Number(otherCharges).toFixed(2),
    convienenceFee: Number(convienenceFee).toFixed(2),
    discount: Number(discount).toFixed(2),
    RefundAmount: Number(RefundAmount).toFixed(2),
    grandTotal,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
    noOfNights,
  };
}

export const getStatus = (status) => {
  switch (status) {
    case 1: {
      return <p style={{ color: "#FFA500" }}>FAILED</p>;
    }
    case 2: {
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;
    }
    case 3: {
      return <p style={{ color: "#FF0000" }}>CANCELLED</p>;
    }
    case 4: {
      return <p style={{ color: "#FF0000" }}>PENDING</p>;
    }
    case 5: {
      return <p style={{ color: "#f9e218" }}>REJECTED</p>;
    }
    case 6: {
      return <p style={{ color: "#FF0000" }}>HOLD</p>;
    }
    case 7: {
      return <p style={{ color: "#FF0000" }}>CANCELLATIONREQUESTED</p>;
    }
    case 8: {
      return <p style={{ color: "#FF0000" }}>CANCELLATIONPENDING</p>;
    }
    case 9: {
      return <p style={{ color: "#FF0000" }}>CANCELLATIONPROGRESS</p>;
    }
    default:
      return;
  }
};

import React, { useState, useRef } from "react";
import {
  Radio,
  Col,
  Row,
  Form,
  Select,
  DatePicker,
  Button,
  Checkbox,
  Collapse,
  Card,
  message,
} from "antd";
import { useHistory } from "react-router-dom";
import "./Agenthomepage.scss";
import moment from "moment";
import AirportAutoComplete from "../../common/AutoCompleteSelect/AirportAutoCompleteSelect";
import { createQueryParamsFromRequest } from "../flight-search/SearchRequestHelper";
import { OriDesValidate } from "../../helpers/CustomValidators";

const { Option } = Select;
const { Panel } = Collapse;

const Agenthomepage = (props) => {
  const CheckboxGroup = Checkbox.Group;

  const origin = useRef();
  const destination = useRef();
  const dateBox = useRef();
  const [agentForm] = Form.useForm();
  const [showDate, setShowDate] = useState(false);

  const [travel_type, setTravelType] = useState("oneWay");

  const dateFormat = "YYYY-MM-DD";
  const departureDate = moment();
  const [arrivalDate, setArrivalDate] = useState(moment());

  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === dateBox) {
      setShowDate(true);
    }
  };
  const updateTravelType = (e) => {
    if (e.target) {
      setTravelType(e.target.value);
    } else {
      setTravelType(e);
    }
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment(departureDate).startOf("day");
  };

  const disabledDestDate = (currentDate) => {
    return currentDate < moment(arrivalDate).startOf("day");
  };

  let history = useHistory();

  const onFormSubmit = (formData) => {
    if (formData.origin === formData.destination) {
      message.error("Origin and Destination cannot be same", 3);
      return false;
    }
    if (formData.infantCount > formData.adultCount) {
      message.error(
        "The number of infants cannot be grater than the number of adults.",
        2
      );
      return;
    }

    if (formData.adultCount + formData.childCount > 9) {
      message.error(
        "Booking for more than 9 persons will be procced as group booking.",
        2
      );
      return;
    }

    let result = {
      originDestinations: getOriginDestinations(formData, travel_type),
      adultCount: formData.adultCount,
      childCount: formData.childCount,
      infantCount: formData.infantCount,
      cabinClass: formData.cabinClass,
      includeCarrier: formData.includeCarrier
        ? formData.includeCarrier.toString()
        : "",
      excludeCarrier: "",
      stopOver: formData.stopOver ?? "none",
      airTravelType: travel_type,
      flightDateFlex: 0,
      itineraryViewType: "1",
      departureTiming: formData.departureTiming ?? "",
      returnTiming: formData.returnTiming ?? "",
    };
    const params = createQueryParamsFromRequest(result);
    if (props.modify) {
      history.replace("/flight/results?" + params);
      props.onFormSubmit();
    } else {
      history.push("/flight/results?" + params);
    }
  };
  const getOriginDestinations = (form, type) => {
    if (type == "multidestination") {
      return form.originDestinationFields.map((i) => {
        return {
          origin: i.origin,
          destination: i.destination,
          departureDateTime: i.departureDateTime.format(dateFormat),
          flightDateFlex: 0,
        };
      });
    } else {
      let data = {
        departureDateTime: form.departure.format(dateFormat),
        origin: form.origin,
        destination: form.destination,
        flightDateFlex: 1,
      };
      if (type == "oneWay") {
        return [data];
      } else {
        return [
          data,
          {
            departureDateTime: form.arrival.format(dateFormat),
            destination: form.origin,
            origin: form.destination,
            flightDateFlex: 1,
          },
        ];
      }
    }
  };
  const AirLineOptions = [
    { name: "Air Asia", value: "I5" },
    { name: "Tiger Airways", value: "TR" },
    { name: "Indigo", value: "6E" },
    { name: "Trujet", value: "2T" },
    { name: "Star air", value: "OG" },
    { name: "Flydubai", value: "FZ" },
    { name: "AirIndiaExpress", value: "IX" },
    { name: "Spicejet ", value: "SJ" },
    { name: "BhutanAirlines", value: "B3" },
    { name: "Mahan Air", value: "W5" },
    { name: "Zoom Air", value: "ZO" },
    { name: "NookScoot", value: "XW" },
    { name: "FlyBig", value: "S9" },
    { name: "Airarabia", value: "G9" },
    { name: "Air Costa", value: "LB" },
    { name: "GOfirst", value: "G8" },
    { name: "Airpegasus", value: "PC" },
    { name: "MegaMaldives", value: "LV" },
    { name: "SalamAir", value: "OV" },
    { name: "Flyscoot", value: "TR" },
  ];
  const [carrier, setCarrier] = useState([]);

  const handleFlightName = (e, name) => {
    let array = [...carrier];
    if (e.target.checked) {
      array.push(name);
    } else {
      let index = array.indexOf(name);
      if (index > -1) {
        array.splice(index, 1);
      }
    }
    setCarrier(array);
  };

  const onChangeOriginDate = (momentdate, _) => {
    let originDate = momentdate ? moment(momentdate).startOf("day") : "";
    setArrivalDate(momentdate);
    const toDate = agentForm.getFieldValue("arrival");
    if (travel_type === "roundTrip" && toDate) {
      let a = moment(toDate).startOf("day");
      let diffDays = a.diff(originDate, "days");
      if (diffDays < 0) {
        let newTodate = moment(momentdate).add(2, "days");
        agentForm.setFieldsValue({
          arrival: newTodate,
        });
      }
    }
  };

  return (
    <div className="agent-flight-wrapper">
      <div className="cms-pages-width ourteam-bg">
        <Form
          layout="vertical"
          form={agentForm}
          onFinish={onFormSubmit}
          initialValues={{
            adultCount: 1,
            infantCount: 0,
            childCount: 0,

            cabinClass: "Economy",
            includeCarrier: "",
            stopOver: "none",
            departure: departureDate,
            departureTiming: "AnyTime",
            returnTiming: "AnyTime",
          }}
        >
          <div className="mb-2">
            <Radio.Group onChange={updateTravelType} value={travel_type}>
              <Radio
                className={`bdr-icon-f ${
                  travel_type === "oneWay" ? "tripTypeActive" : ""
                }`}
                value={"oneWay"}
              >
                One way
              </Radio>
              <Radio
                className={`bdr-icon-f ${
                  travel_type === "roundTrip" ? "tripTypeActive" : ""
                }`}
                value={"roundTrip"}
              >
                Round trip
              </Radio>
              {/* <Radio value={"multidestination"}>Multi-city</Radio> */}
            </Radio.Group>
          </div>
          <Row gutter={[8, 8]}>
            <Col md={6} xs={24}>
              <AirportAutoComplete
                formItemProps={{
                  label: "Source City",
                  name: "origin",

                  rules: [
                    {
                      required: true,
                      message: "Please Specify The City",
                    },
                    {
                      validator: (_, value) => {
                        let destination =
                          agentForm.getFieldValue("destination");
                        return OriDesValidate(value, destination);
                      },
                    },
                  ],
                }}
                selectProps={{
                  size: "large",
                  placeholder: "Select Source",
                }}
                refName={origin}
                focusRef={destination}
                handleOnSubmit={handleOnSubmit}
                dataFlip={false}
              />
            </Col>

            <Col md={6} xs={24}>
              <AirportAutoComplete
                formItemProps={{
                  label: "Destination",
                  name: "destination",
                  rules: [
                    {
                      required: true,
                      message: "Please Specify The City",
                    },
                    {
                      validator: (_, value) => {
                        let origin = agentForm.getFieldValue("origin");
                        return OriDesValidate(origin, value);
                      },
                    },
                  ],
                }}
                selectProps={{
                  size: "large",
                  placeholder: "Select Destination",
                }}
                refName={destination}
                focusRef={dateBox}
                handleOnSubmit={handleOnSubmit}
                dataFlip={false}
              />
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Departure Date"
                name="departure"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <DatePicker
                  allowClear={false}
                  style={{ width: "100%" }}
                  className="train-search-btn"
                  size="large"
                  format={"DD-MM-YYYY"}
                  disabledDate={disabledOriginDate}
                  onChange={(date, dateString) =>
                    onChangeOriginDate(date, dateString)
                  }
                  ref={dateBox}
                  open={showDate}
                  onOpenChange={() => {
                    setShowDate((prev) => !prev);
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={6} xs={24} className="any-time-select">
              <Form.Item label="Time" name="departureTiming">
                <Select>
                  <Option value="anyTime">Any Time</Option>
                  <Option value="morning">Morning</Option>
                  <Option value="afternoon">Afternoon</Option>
                  <Option value="evening">Evening</Option>
                  <Option value="night">Night</Option>
                </Select>
              </Form.Item>
            </Col>
            {travel_type === "roundTrip" && (
              <>
                <Col md={6} xs={24}>
                  <Form.Item
                    className="returnDate"
                    label="Return Date"
                    name="arrival"
                    rules={[
                      {
                        required: true,
                        message: "Please Select a Date",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      className="train-search-btn"
                      size="large"
                      format={"DD-MM-YYYY"}
                      disabledDate={disabledDestDate}
                      placeholder="Arrival Date"
                    />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24} className="any-time-select">
                  <Form.Item label="Time" name="returnTiming">
                    <Select>
                      <Option value="anyTime">Any Time</Option>
                      <Option value="morning">Morning</Option>
                      <Option value="afternoon">Afternoon</Option>
                      <Option value="evening">Evening</Option>
                      <Option value="night">Night</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>

          <Row gutter={[8, 8]}>
            <Col md={6} xs={24}>
              <Form.Item label="Adult (12+ Yrs)" name="adultCount">
                <Select>
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                  <Option value={6}>6</Option>
                  <Option value={7}>7</Option>
                  <Option value={8}>8</Option>
                  <Option value={9}>9</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item label="Children (2-11 Yrs)" name="childCount">
                <Select>
                  <Option value={0}>0</Option>
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                  <Option value={6}>6</Option>
                  <Option value={7}>7</Option>
                  <Option value={8}>8</Option>
                  <Option value={9}>9</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item label="Infant (Under 2 Yrs)" name="infantCount">
                <Select>
                  <Option value={0}>0</Option>
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                  <Option value={6}>6</Option>
                  <Option value={7}>7</Option>
                  <Option value={8}>8</Option>
                  <Option value={9}>9</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item label="Class" name="cabinClass">
                <Select>
                  <Option value="Economy">Economy</Option>
                  <Option value="premiumEconomy">Premium Economy</Option>
                  <Option value="Business">Business</Option>
                  <Option value="first">First Class</Option>
                  <Option value="premiumFirst">Premium First Class</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="coolaspe-airline-op">
            <Collapse>
              <Panel header="More Options Airline Preference" key="1">
                <Row>
                  <Col md={12} xs={24}>
                    <Form.Item name="stopOver">
                      <Radio.Group>
                        <Radio value="none">None</Radio>
                        <Radio value="directFlight">Direct Flights</Radio>
                        <Radio value="oneStopFlight">One Stop Flight</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  {/* <Col md={12} xs={24} >
                        <Form.Item
                          label="Preferred Carrier"

                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Select a Date",
                          //   },
                          // ]}
                        >
                          <Input
                            className="agent-flight-c2"
                            disabled
                            placeholder={carrier.toString()}
                          />
                        </Form.Item>
                      </Col> */}
                </Row>

                <p className="restrict-select">
                  <span>Restrict My Search to:</span> SelectAll / Unselect All
                </p>

                <Card className="gds-card-bg">
                  <Form.Item name="includeCarrier">
                    <CheckboxGroup>
                      <Row>
                        {AirLineOptions.length > 0
                          ? AirLineOptions.map((item) => {
                              return (
                                <Col md={8} xs={24} className="p-1">
                                  <Checkbox
                                    value={item.value}
                                    onChange={(e) =>
                                      handleFlightName(e, item.name)
                                    }
                                  >
                                    {" "}
                                    {item.name}
                                  </Checkbox>
                                </Col>
                              );
                            })
                          : null}
                      </Row>
                    </CheckboxGroup>
                  </Form.Item>
                </Card>
              </Panel>
            </Collapse>
          </div>

          <Row>
            <Col md={24} xs={24}>
              <Button className="ff-agent-search" htmlType="submit">
                Flight Search
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Agenthomepage;

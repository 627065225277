import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { getAllActivitiesPrice } from "../../../pages/Activitespkg/Activitiescheckout/activitiesHelper";
import LogoImg from "../../../assets/images/logos/trip-logo.png";

const getQRImage = (index) => {
  let imgUrl = document.getElementById("packageQR" + index);
  if (imgUrl) {
    imgUrl = imgUrl.toDataURL();
    return imgUrl;
  }
};
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: 20,
  },
  section: {
    padding: "0 10px",
  },
  innerSection: {
    position: "relative",
    paddingLeft: 30,
  },
  borderleft: {
    position: "absolute",
    width: 3,
    left: 0,
    height: "100%",
    backgroundColor: "#e9ecef",
  },
  title: {
    fontSize: 12,
    marginBottom: 5,
    color: "#274581",
  },
  subtitle: {
    fontSize: 10,
    marginBottom: 5,
  },
  stitle: {
    fontSize: 10,
  },
  textDanger: {
    color: "#dc3545",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    flexWrap: "wrap",
  },

  wHalf: {
    width: "50%",
    marginBottom: 5,
  },
  card: {
    position: "relative",
    padding: 10,
    border: "1px solid #e9ecef",
    borderRadius: 2,
  },
  tourImg: {
    width: 100,
  },
  mb10: {
    marginBottom: 10,
  },
  contentWrapper: {
    position: "relative",
    marginBottom: 10,
    backgroundColor: "#e3f2fd",
  },

  timelineBadge: {
    position: "absolute",
    top: 9,
    left: -46,
    zIndex: 10,
    width: 35,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: 9,
    color: "#fff",
    backgroundColor: "#ffbc34",
  },
  description: { width: 350, fontSize: 10, marginBottom: 8 },
  headerWrraper: {
    backgroundColor: "#e3f2fd",
    padding: "5px 15px",
  },
  mb_5: { marginBottom: 5 },
  addressDetails: {
    textAlign: "right",
    fontSize: 10,
    paddingBottom: 5,
  },
  flex5: {
    flex: 0.49,
  },
  logoSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  textRight: {
    textAlign: "cen",
  },
  alignSelf: {
    alignSelf: "flex-end",
  },
  justifyContentBetween: {
    justifyContent: "space-between",
  },
  wHalfBar: {
    width: "50%",
    marginBottom: 5,
  },
  tourImgBar: {
    width: 100,
    height: "100%",
  },
});

const getStatus = (status) => {
  switch (status) {
    case "BLOCKED": {
      return <Text style={{ color: "#FFA500" }}>BLOCKED </Text>;
    }
    case "SUCCESS": {
      return <Text style={{ color: "#008000" }}>{status}</Text>;
    }

    case "CONFIRMED": {
      return <Text style={{ color: "#008000" }}>{status}</Text>;
    }

    case "ALREADYCANCELLED": {
      return <Text style={{ color: "#FF0000" }}>ALREADY CANCELLED</Text>;
    }
    case "CANCELLED": {
      return <Text style={{ color: "#FF0000" }}>{status}</Text>;
    }
    case "VOUCHERED": {
      return <Text style={{ color: "#FF0000" }}>{status}</Text>;
    }
    default:
      return;
  }
};
const CancellationDescription = ({ tourShortDescription }) => {
  let returnContentConst=   <Text style={{ display: "inline" }}>{}</Text>
  if (tourShortDescription) {
    const parsedHtml = ReactHtmlParser(tourShortDescription);
    parsedHtml.forEach((element) => {
      if (element?.props && element?.props?.children) {
        element.props.children.forEach((content) => {
          returnContentConst=(
            <Text  style={{ display: "inline", color: "red"  }}>{content}</Text>
          );
        });
      } else {
        if (element)
          return (<Text style={{ display: "inline", color: "red"  }}>{element}</Text>)
        
      }
    });
   return returnContentConst
  }
}
const Description = ({ tourShortDescription }) => {
  let returnContentConst= <Text style={{ display: "inline" }}>{''}</Text>;
  if (tourShortDescription) {
    const parsedHtml = ReactHtmlParser(tourShortDescription);
    parsedHtml.forEach((element) => {
      if (element?.props && element?.props?.children) {
        element.props.children.forEach((content) => {
          returnContentConst = (
            <Text style={{ display: "inline" }}>{content}</Text>
          );
        });
      } else {
        if (element)
          returnContentConst = (
            <Text style={{ display: "inline" }}>{element}</Text>
          );
      }
    });
    return returnContentConst;
  } else {
    return returnContentConst;
  }
};

const getPaxCount = (pax) => {
  return `Adult(s) ${pax?.adults !== "0" ? pax?.adults : 0} ${
    pax?.childCount !== "0" ? ", Child(s) " + pax?.childCount : " "
  } ${pax?.infantCount !== "0" ? ", Infant(s) " + pax?.infantCount : " "}`;
};

const ActivitiesDoc = ({ 
  ticketData, 
  withFare, 
  agent, 
  Logo,
  voucherData,
  activeCurrency,
  currencyValue = () => {},
 }) => {
  const { totalAmount, discount } = getAllActivitiesPrice(
    ticketData.TourDetails,
    ticketData.convienenceData,
    ticketData.promoData
  );
  ticketData.TourDetails=ticketData.TourDetails.filter(x=>x.BookingStatus!=="NOT-AVAILABLE")
  const BASE = process.env.REACT_APP_BASE_URL;
  const getTotalAmount = () => {
    let postMarkup = ticketData.totalPostMarkup
      ? ticketData.totalPostMarkup
      : 0;
    let totalAmount =
      Number(ticketData.PartPayment.serviceTotal) + Number(postMarkup);

    return Number(totalAmount).toFixed(2);
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        <View style={styles.section}>
          <View style={{ ...styles.logoSection }}>
            <View style={{ ...styles.flex5 }}>
              {Logo ? (
                <Image
                  src={BASE + Logo.substring(1)}
                  style={{ height: 50, width: 100, marginBottom: 10 }}
                ></Image>
              ) : (
                <Image style={{ width: 100, heigh: 50 }} src={LogoImg} />
              )}
            </View>

            <View
              style={{
                ...styles.flex5,
                ...styles.addressDetails,
              }}
            >
              {ticketData?.agentDetails ? (
                <View
                  style={{
                    ...styles.textRight,
                    ...styles.justifyContentBetween,
                  }}
                >
                  <Text style={{ ...styles.alignSelf }}>
                    {ticketData?.agentDetails?.AgencyName}{" "}
                  </Text>
                  <Text style={{ ...styles.alignSelf }}>
                    {ticketData?.agentDetails?.Address1}{" "}
                    {ticketData?.agentDetails?.Address2}
                  </Text>
                  {ticketData?.agentDetails?.City ||
                  ticketData?.agentDetails?.State ? (
                    <Text style={{ ...styles.alignSelf }}>
                      {ticketData?.agentDetails?.City}{" "}
                      {ticketData?.agentDetails?.State}
                    </Text>
                  ) : null}

                  <Text style={{ ...styles.alignSelf }}>
                    Email: {ticketData?.agentDetails?.Email}
                  </Text>
                  {ticketData?.agentDetails?.Phone ? (
                    <Text style={{ ...styles.alignSelf }}>
                      Contact No.: {ticketData?.agentDetails?.Phone}
                    </Text>
                  ) : null}
                </View>
              ) : ticketData?.adminDetails?.length > 0 ? (
                <View
                  style={{
                    ...styles.textRight,
                    ...styles.justifyContentBetween,
                  }}
                >
                  <Text style={{ ...styles.alignSelf }}>
                    {ticketData?.adminDetails[0]?.Address}{" "}
                  </Text>
                  <Text style={{ ...styles.alignSelf }}>
                    {ticketData?.adminDetails[0]?.CityName}{" "}
                    {ticketData?.adminDetails[0]?.StateName}
                  </Text>
                  <Text style={{ ...styles.alignSelf }}>
                    {ticketData?.adminDetails[0]?.CountryName}{" "}
                    {ticketData?.adminDetails[0]?.PostalCode}
                  </Text>
                </View>
              ) : null}
            </View>
          </View>

          <View style={styles.innerSection}>
            <View style={styles.borderleft}></View>
            <View style={styles.headerWrraper}>
              <Text style={styles.title}>Passenger Details</Text>
              <View style={styles.row}>
                <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                  Name: {ticketData?.Passengers.firstName}{" "}
                  {ticketData?.Passengers.lastName}
                </Text>
                <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                  Email: {ticketData?.Passengers.email}
                </Text>
                <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                  Contact: {ticketData?.Passengers.mobile}
                </Text>
              </View>
            </View>
            <View style={{ ...styles.headerWrraper, ...styles.mb_5 }}>
              <Text style={styles.title}>Tour Details</Text>
              <View style={styles.row}>
                <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                  Reference Number:{" "}
                  {ticketData?.TourDetails?.[0].referenceNumber}
                </Text>
                {!agent && (
                  <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                    Provider Ref No:{" "}
                    {ticketData?.TourDetails?.[0].ProviderRefNo}
                  </Text>
                )}
                
                {ticketData?.TourDetails?.[0].confirmationNo != '' && (
                <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                  Confirmation No: {ticketData?.TourDetails?.[0].confirmationNo}
                </Text>
                )}

                {withFare ? (
                  <>
                    <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                      Payment Mode:{" "}
                      {ticketData.PartPayment.isPartPayment === true
                        ? " Part"
                        : " Full"}{" "}
                      Payment
                    </Text>
                    {ticketData?.convienenceData?.amount != '' && (
                      <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                        Convienence Fee: {activeCurrency}{" "}
                        {currencyValue(Number(
                          ticketData?.convienenceData?.amount ?? 0
                        ).toFixed(2))}
                      </Text>
                    )}
                    <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                      Total Amount: {activeCurrency} {currencyValue(totalAmount)}
                    </Text>
                    
                    {discount != '' && (
                    <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                      Discount: - {activeCurrency}{" "}
                      {isNaN(discount) ? 0 : Number(discount).toFixed(2)}
                    </Text>
                    )}

                    <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                      Grand Total: {activeCurrency} {currencyValue(getTotalAmount())}
                    </Text>
                    {ticketData.PartPayment.isPartPayment === true ? (
                      <>
                        <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                          Paid Amount: {activeCurrency}{" "}
                          {currencyValue(ticketData.PartPayment.partPaymentAmount)}
                        </Text>
                        <Text
                          style={{
                            ...styles.subtitle,
                            ...styles.wHalf,
                            ...styles.textDanger,
                          }}
                        >
                          Due Amount: {activeCurrency} {currencyValue(ticketData.PartPayment.dueAmount)}
                        </Text>
                      </>
                    ) : null}
                  </>
                ) : null}
              </View>
            </View>

            {ticketData.TourDetails.map((tour, index) => {
              return (
                
                <>
                <View
                  key={index + tour.tourName}
                  style={styles.contentWrapper}
                  wrap={false}
                >
                  <View style={styles.timelineBadge}>
                    <Text>
                      {moment(tour.journeyDate, "YYYY-MM-DD").format("DD MMM")}{" "}
                    </Text>
                  </View>

                  <View style={styles.card} wrap={false}>
                    <Text style={styles.title}>{tour.tourName}</Text>
                    <Text style={styles.subtitle}>{tour.optionName}</Text>
                    <Text style={styles.subtitle}>
                      Tour Date :{" "}
                      {moment(tour.journeyDate, "YYYY-MM-DD").format(
                        "ddd DD MMM YYYY"
                      )}
                    </Text>
                    <Image style={styles.tourImg} source={tour.tourImage} />
                    <View style={styles.description}>
                      <Description
                        tourShortDescription={tour.tourShortDescription}
                      />
                    </View>
                    <View style={styles.row}>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                        Booking Status :
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                      {voucherData === true ? getStatus(tour.BookingStatus) : <Text style={{ color: "red" }}>Vouchered</Text>}
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                        Cancellation Date :
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                        {tour.cancellationDate
                          ? moment(tour.cancellationDate, "YYYY-MM-DD").format(
                              "DD-MM-YYYY"
                            )
                          : ""}
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                        Transfer Option :
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                        {tour.transferName}
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                        Duration :
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                        {tour.duration}
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                        Start Time :
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                      {tour ? tour.startTime : 'Time Not Available'}
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                        Pax Details :
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                        {getPaxCount(tour.pax)}
                      </Text>
                      {withFare ? (
                        <>
                          <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                            Amount :
                          </Text>
                          <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                            {activeCurrency} {currencyValue(tour.serviceTotal?.toFixed(2))}
                          </Text>
                        </>
                      ) : null}
                    </View>
                  </View>
      
{/*                        
                             <View style={styles.description}>
                             {tour.ticketPolicy[0]?.childPolicyDescription}
                        </View>googleNavigation
                       
                          <View style={styles.description}>
                             {tour.ticketPolicy[0]?.googleNavigation}
                             </View> */}
                       
                </View>
                {tour.ticket?.map(x=>{
                 return (x.barcode ? (
                      
                    <View
                      style={{
                        ...styles.mb10,
                        ...styles.row,
                      }}
                      
                    >
                            <Text style={{ ...styles.stitle, ...styles.wHalfBar }}>
                      <Image style={{ height: 100, width: 100, marginBottom: 10 }} src={tour.tourImage} />
                      </Text>
                      <Text style={{ ...styles.stitle, ...styles.wHalfBar }}>
                        <Image
                          allowDangerousPaths
                          src={getQRImage(index)}
                          style={{ height: 100, width: 100, marginBottom: 10 }}
                        />
                     </Text>
                    </View> ) : null)})}

                    
                 { <View style={styles.description}>
                 <Text style={styles.title}>Cancellation Policy</Text>
                 <Text style={{ display: "inline", color: "red"  }}>{tour.ticketPolicy[0]?.cancellationPolicyDescription?.replace(/<[^>]*>?/gm, '')}</Text>
                
                      </View>
            }
          
                </>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ActivitiesDoc;

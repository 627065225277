import React from "react";
import { Col, Row } from "antd";
import hotelNoImg from "../../../assets/images/hotels/no_photo.png";
import Table from "react-bootstrap/Table";

import "../ticketActivities.scss";
import moment from "moment";
import { useAuthContext } from "../../providers/AuthProvider";
import { useCurrencyContext } from "../../providers/CurrencyProvider"

const TicketBuildPackage = ({ ticketData }) => {
  const {
    isLogin: { agent },
  } = useAuthContext();

  const { activeCurrency, currencyValue } = useCurrencyContext();

  const getHotelStatus = (status) => {
    switch (status) {
      case 2: {
        return (
          <span style={{ color: "#008000" }}>
            <b> CONFIRMED</b>
          </span>
        );
      }

      case 1: {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> FAILED</b>
          </span>
        );
      }
      case 3: {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> CANCELLED</b>
          </span>
        );
      }
      default:
        return;
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 2: {
        return <span style={{ color: "#FFA500" }}>BLOCKED </span>;
      }

      case 3: {
        return (
          <span style={{ color: "#008000" }}>
            <b> CONFIRMED </b>
          </span>
        );
      }

      case 1: {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> ALREADY CANCELLED </b>
          </span>
        );
      }
      case 11: {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> VOUCHERED </b>
          </span>
        );
      }
      case 0: {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> CANCELLED </b>
          </span>
        );
      }
      default:
        return;
    }
  };

  const getPaxCount = (pax) => {
    return `Adult(s) ${pax?.adult !== "0" ? pax?.adult : 0} ${
      pax?.child !== "0" ? ", Child(s) " + pax?.child : " "
    } ${pax?.infant !== "0" ? ", Infant(s) " + pax?.infant : " "}`;
  };

  const getTimebyUser = (optionData) => {
    if (
      (optionData.optionName.includes('Airport') ||
        optionData.optionName.includes('Transfer') &&
        optionData.transferName === "Private Transfers")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="activities-ticket">
      <div className="activities-details">
        <ul className="tour-timeline tour-timeline-left">
          <div className="tour-details-panel">
            <h6 className="pax-title">Passanger Details</h6>
            <div className="passenger-details">
              <Row gutter={[8, 8]}>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Name:{" "}
                    <span>
                      {ticketData?.leadPassenger?.firstName}{" "}
                      {ticketData?.leadPassenger?.lastName}
                    </span>
                  </p>
                </Col>

                <Col md={12} sm={12} xs={24}>
                  <p>
                    Email: <span>{ticketData?.leadPassenger?.email}</span>
                  </p>
                </Col>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Contact: <span>{ticketData?.leadPassenger?.mobile}</span>
                  </p>
                </Col>
              </Row>
            </div>
            <h6 className="pax-title">Payment Details</h6>
            <div className="passenger-details">
              <Row gutter={[8, 8]}>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Payment Mode:
                    <span>
                      {ticketData.partPayment.isPartPayment === true
                        ? " Part"
                        : " Full"}{" "}
                      Payment
                    </span>
                  </p>
                </Col>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Total Fare:
                    <span> {activeCurrency} {currencyValue(ticketData.partPayment.serviceTotal)}</span>
                  </p>
                </Col>

                {ticketData.partPayment.isPartPayment === true ? (
                  <>
                    <Col md={12} sm={12} xs={24}>
                      <p>
                        Paid Amount:
                        <span>
                          {activeCurrency} {currencyValue(ticketData.partPayment.partPaymentAmount)}{" "}
                        </span>
                      </p>
                    </Col>
                    <Col md={12} sm={12} xs={24}>
                      <p className="text-danger">
                        Due Amount:
                        <span> {activeCurrency} {currencyValue(ticketData.partPayment.dueAmount)} </span>
                      </p>
                    </Col>
                  </>
                ) : null}
              </Row>
            </div>
          </div>

          <Row>
            <Col md={24} xs={24}>
              <ul className="timeline timeline-left">
                <Row className="tours-book-method">
                  <Col md={24} xs={24}>
                    <h4 className="hotel-room-info">Hotel</h4>
                  </Col>
                </Row>
                {ticketData.hotelsPackageDetails.length > 0
                  ? ticketData.hotelsPackageDetails.map((hotel, index) => {
                      return (
                        <li className="timeline-inverted timeline-item">
                          <div className="timeline-badge warning">
                            <span className="font-12">
                              <i 
                                className="fa fa-hospital-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <div className="timeline-panel">
                            <div className="timeline-body">
                              <div className="info-hotel-main p-2">
                                <Row>
                                  <Col md={6} xs={24}>
                                    {hotel.hotelImage ? (
                                      <img
                                        className="img-cotation-total"
                                        alt="example"
                                        src={hotel.hotelImage}
                                      />
                                    ) : (
                                      <img
                                        className="img-cotation-total"
                                        src={hotelNoImg}
                                        alt="no-photo"
                                      />
                                    )}
                                  </Col>
                                  <Col
                                    md={18}
                                    xs={24}
                                    className="bty-info-hote"
                                  >
                                    <h5 className="review-name-hotel">
                                      {hotel.HotelName}
                                    </h5>
                                    <ul className="time-and-date-main">
                                      <li>
                                        <i
                                          className="fa fa-hospital-o"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp; {hotel.HotelAddress}
                                      </li>
                                    </ul>
                                    <ul className="last-date-maion">
                                      <li className="fn-bold">
                                        Check In Date:{" "}
                                        {moment(hotel.CheckInDate).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </li>

                                      <li className="fn-bold">
                                        Check Out Date :{" "}
                                        {moment(hotel.CheckOutDate).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </li>
                                      <li className="fn-bold">
                                        No Of Night: {hotel.Rooms[0].noOfNight}
                                      </li>
                                    </ul>

                                    <ul className="last-date-maion">
                                      <li className="fn-bold">
                                        Total: {activeCurrency} {currencyValue(hotel.Fare)}
                                      </li>
                                    </ul>

                                    <ul className="last-date-maion">
                                      <li className="fn-bold">
                                        Booking Status :{" "}
                                        {getHotelStatus(hotel?.Status)}
                                      </li>
                                    </ul>
                                  </Col>
                                </Row>
                                <Table
                                  className="mt-2"
                                  responsive="lg"
                                  striped
                                  bordered
                                  hover
                                >
                                  <thead>
                                    <tr>
                                      <th>Room Type</th>
                                      <th>Unit</th>
                                      <th>Room Cost </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {hotel.Rooms.map((room) => {
                                      return (
                                        <tr>
                                          <td>{room.RoomName}</td>
                                          <td>{room.noOfNight}</td>
                                          <td>₹ {room.pricePerRoom}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  : null}

                <Row className="tours-book-method">
                  <Col md={24} xs={24}>
                    <h4 className="hotel-room-info">Tours</h4>
                  </Col>
                </Row>

                {ticketData.tourDetails.length > 0
                  ? ticketData.tourDetails.map(
                      (tour, index) =>
                        tour &&
                        tour?.length > 0 &&
                        tour.map((optionData) => (
                          <li className="timeline-inverted timeline-item">
                            <div className="timeline-badge warning">
                              <p className="mb-0">Day {index + 1}</p>
                            </div>
                            <div className="timeline-panel ">
                              <div className="timeline-body">
                                <h6 className="font-weight-bold">
                                  {optionData.tourName}
                                </h6>

                                <p className="font-weight-bold mb-3">
                                  Tour Date :{" "}
                                  {moment(
                                    optionData.tourDate,
                                    "YYYY-MM-DD"
                                  ).format("DD-MM-YYYY")}
                                </p>

                                <p className="font-weight-bold ">
                                  Booking Status :{" "}
                                  {getStatus(optionData?.BookingStatus)}
                                </p>

                                <p className="font-weight-bold mb-1">
                                  Booking Reference No :{" "}
                                  {optionData?.BookingRefNo}
                                </p>

                                {!agent && (
                                  <p className="font-weight-bold mb-3">
                                    Provider Reference No :{" "}
                                    {optionData?.ProviderRefNo}
                                  </p>
                                )}

                                {optionData?.confirmationNo && (
                                  <p className="font-weight-bold mb-3">
                                    Confirmation No: :{" "}
                                    {optionData?.confirmationNo}
                                  </p>
                                )}

                                <Row>
                                  <Col md={6} xs={24}>
                                    <img
                                      className="img-fluid "
                                      alt="example"
                                      src={optionData.tourImage}
                                    />
                                  </Col>
                                  <Col md={18} xs={24}>
                                    <p
                                      className="hotel-list-para"
                                      dangerouslySetInnerHTML={{
                                        __html: optionData.tourShortDescription,
                                      }}
                                    ></p>
                                  </Col>
                                </Row>
                                <ul className="mt-4">
                                  <li>
                                    <span className="font-weight-bold">
                                      Tour Option : {optionData.optionName}
                                    </span>
                                  </li>

                                  <li>
                                    <span className="font-weight-bold">
                                      Transfer Option :{" "}
                                    </span>
                                    <span>{optionData.transferName}</span>
                                  </li>
                                  {!getTimebyUser(optionData) && (
                                  <li>
                                    <span className="font-weight-bold">
                                      Duration :{" "}
                                    </span>
                                    <span>{optionData.duration}</span>
                                  </li>
                                  )}
                                  
                                  <li>
                                    <span className="font-weight-bold">
                                      Start Time :{" "}
                                    </span>
                                    <span>{optionData.startTime}</span>
                                  </li>
                                  <li>
                                    <span className="font-weight-bold">
                                      No. of Pax :{" "}
                                    </span>
                                    <span>{getPaxCount(optionData)}</span>
                                  </li>

                                  <li>
                                    <span className="font-weight-bold">
                                      Amount :{" "}
                                    </span>
                                    <span> {activeCurrency} {currencyValue(optionData.serviceTotal)}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        ))
                    )
                  : null}
              </ul>
            </Col>
          </Row>
        </ul>
      </div>
    </div>
  );
};

export default TicketBuildPackage;
